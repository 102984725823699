import { Query, getDefaultQuery } from '@services/api'

import { useState } from 'react'

const useQuery = (filters?: string[]) => {
    const [query, setQuery] = useState<Query>({
        ...getDefaultQuery(),
        filters: filters,
    })

    const handleQueryChange = (q: Query) => {
        setQuery((prev) => ({
            ...prev,
            page: q.page,
            pageSize: q.pageSize,
            sorts: q.sorts,
        }))
    }

    const handleQueryFiltersChange = (filters: string[]) => {
        setQuery((prev) => ({ ...prev, filters }))
    }

    const clearQueryFilters = () => {
        setQuery((prev) => ({ ...prev, filters: [] }))
    }

    return {
        query,
        handleQueryChange,
        handleQueryFiltersChange,
        clearQueryFilters,
    }
}

export default useQuery
