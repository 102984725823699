import { useAppDispatch, useAppSelector } from '@hooks/store'

import { Location } from 'react-router-dom'
import { getNumberOrDefault } from '@utils/numbers'
import { setTreeMode } from '@store/network'
import { useFindAgentQuery } from '@services/api'
import { useFindDeviceQuery } from '@services/api/endpoints/devices.endpoints'
import { useFindGroupQuery } from '@services/api/endpoints/groups.endpoints'
import { useFindSensorQuery } from '@services/api/endpoints/sensors.endpoints'
import { useMemo } from 'react'

const useQueryParamsNetworkData = (location: Location) => {
    const { treeMode } = useAppSelector((state) => state.network)
    const dispatch = useAppDispatch()

    // Memos
    const searches = useMemo(() => {
        if (treeMode !== 'groups') dispatch(setTreeMode('groups'))

        const searchStrings = location.search
            ?.substring(1, location.search.length)
            .split('&')

        const searchObject: { [key: string]: number } = searchStrings?.reduce(
            (acc, curr) => {
                const entry = curr.split('=')

                if (entry[0] && entry[1]) {
                    return {
                        ...acc,
                        [entry[0]]: getNumberOrDefault(entry[1], 0),
                    }
                }

                return acc
            },
            {}
        )

        return searchObject
    }, [location])

    // Queries
    const { data: agentData } = useFindAgentQuery(
        { id: getNumberOrDefault(searches['agent'], 0) },
        { skip: getNumberOrDefault(searches['agent'], 0) === 0 }
    )
    const { data: groupData } = useFindGroupQuery(
        { id: getNumberOrDefault(searches['group'], 0) },
        { skip: getNumberOrDefault(searches['group'], 0) === 0 }
    )
    const { data: deviceData } = useFindDeviceQuery(
        { id: getNumberOrDefault(searches['device'], 0), includeSensors: true },
        { skip: getNumberOrDefault(searches['device'], 0) === 0 }
    )
    const { data: sensorData } = useFindSensorQuery(
        { id: getNumberOrDefault(searches['sensor'], 0) },
        { skip: getNumberOrDefault(searches['sensor'], 0) === 0 }
    )

    return {
        agent: agentData,
        group: groupData,
        device: deviceData,
        sensor: sensorData,
    }
}

export default useQueryParamsNetworkData
