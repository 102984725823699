import { store } from '@store/store'

export interface LoginRequest {
    username: string
    password: string
}

export interface RefreshTokenRequest {
    token: string
    refreshToken: string
}

export interface CreateApiKeyRequest {
    name: string
    description?: string
    expiredAt?: Date
    roleId?: string
}

export interface TokenModel {
    token: string
    refreshToken: string
    firstName: string
    lastName: string
    tenantId: string
    roles: string[]
    permissions: string[]
    expire: Date
}

export interface ApiKey {
    id: string
    name: string
    description?: string
    token: string
    roleId?: string
    expiredAt?: Date
    created: Date
    lastModified?: Date
}

export const hasPermissions = (permissions: string[]) => {
    const { user } = store.getState().auth
    if (!user) return false

    return permissions.every((p) => user.permissions.includes(p))
}
