import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TokenModel } from '@services/auth'

export interface AuthenticationState {
    isAuthenticated: boolean
    user?: TokenModel
}

const initialState: AuthenticationState = {
    isAuthenticated: false,
    user: undefined,
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        storeToken: (
            state,
            { payload: tokenModel }: PayloadAction<TokenModel>
        ) => {
            state.user = { ...tokenModel }
            state.isAuthenticated = true
        },
        logout: () => initialState,
    },
})

export const { storeToken, logout } = authSlice.actions
export default authSlice.reducer
