import {
    EuiAvatar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHeaderSectionItemButton,
    EuiLink,
    EuiPopover,
    EuiSpacer,
    EuiText,
    useGeneratedHtmlId,
} from '@elastic/eui'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type HeaderUserMenuProps = { displayName: string; onLogout: () => void }

const HeaderUserMenu = (props: HeaderUserMenuProps) => {
    const userPopoverId = useGeneratedHtmlId({ prefix: 'userPopover' })
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation(['auth'])

    const onMenuButtonClick = () => {
        setIsOpen(!isOpen)
    }

    const closeMenu = () => {
        setIsOpen(false)
    }

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls={userPopoverId}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Account menu"
            onClick={onMenuButtonClick}
        >
            <EuiAvatar name={props.displayName} size="s" />
        </EuiHeaderSectionItemButton>
    )
    return (
        <EuiPopover
            id={userPopoverId}
            repositionOnScroll
            button={button}
            isOpen={isOpen}
            anchorPosition="downRight"
            closePopover={closeMenu}
            panelPaddingSize="none"
        >
            <div style={{ width: 320 }}>
                <EuiFlexGroup
                    gutterSize="m"
                    className="euiHeaderProfile"
                    responsive={false}
                >
                    <EuiFlexItem grow={false}>
                        <EuiAvatar name={props.displayName} size="xl" />
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <EuiText>
                            <p>{props.displayName}</p>
                        </EuiText>
                        <EuiSpacer size="m" />
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiFlexGroup justifyContent="spaceBetween">
                                    <EuiFlexItem grow={false}>
                                        <EuiLink onClick={props.onLogout}>
                                            {t('auth:logout')}
                                        </EuiLink>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </div>
        </EuiPopover>
    )
}

export default HeaderUserMenu
