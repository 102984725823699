import './index.scss'
import 'rc-tree/assets/index.css'

import App from './App'
import { HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { persistStore } from 'redux-persist'
import { store } from '@store/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const persistor = persistStore(store)

root.render(
    // <React.StrictMode>
    <HashRouter>
        <Provider store={store}>
            <PersistGate
                loading={<span>Loading...</span>}
                persistor={persistor}
            >
                <App />
            </PersistGate>
        </Provider>
    </HashRouter>
    // </React.StrictMode>
)
