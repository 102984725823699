import { EuiComboBox, EuiComboBoxProps } from '@elastic/eui'

import { useMemo } from 'react'

export type SelectionBoxProps = {
    options: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
    value?: any // eslint-disable-line @typescript-eslint/no-explicit-any
    optionsAsValue?: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
    placeholder?: string
    multiple?: boolean
    disabled?: boolean
    loading?: boolean
    fullWidth?: boolean
    style?: React.CSSProperties
    isClearable?: boolean
    compressed?: boolean
    onSearchChange?: (searchValue: string, hasMatchingOptions?: boolean) => void
    onChange: (value: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
    prepend?: React.ReactElement
    append?: React.ReactElement
}

const SelectionBox = (props: SelectionBoxProps) => {
    const selectedValues = useMemo(() => {
        // Gets the selected values from the options (single)
        if (props.value && !props.multiple) {
            return [...props.options.filter((o) => o.value === props.value)]
        }

        // Gets the selected values from the options (multiple)
        else if (props.value && props.multiple) {
            return [
                ...props.options.filter((o) =>
                    props.value.some((v: any) => v.value === o.value)
                ),
            ]
        }

        if (props.optionsAsValue) {
            return [...props.optionsAsValue]
        }

        return undefined
    }, [props.value, props.optionsAsValue, props.options])

    return (
        <EuiComboBox
            fullWidth={props.fullWidth}
            options={props.options}
            isLoading={props.loading}
            style={props.style}
            compressed={props.compressed}
            placeholder={props.placeholder}
            isClearable={props.isClearable}
            singleSelection={
                !props.multiple ? { asPlainText: true } : undefined
            }
            selectedOptions={selectedValues}
            onSearchChange={props.onSearchChange}
            isDisabled={props.disabled}
            onChange={props.onChange}
            prepend={props.prepend}
            append={props.append}
        />
    )
}

export default SelectionBox
