const NUM_DISPLAY_CULTURE = 'nl-NL'

/**
 * Gets number of string or return initial value
 * @param value number to be checked
 * @param initialValue value to be returned if value is not a number
 * @returns Given value as a number or initialValue
 */
export const getNumberOrDefault = (
    value: string | number | undefined,
    initialValue: number
): number => {
    if (value && typeof value === 'number') {
        return value
    }
    if (value && typeof value === 'string' && !isNaN(+value)) {
        return parseFloat(value)
    }
    return initialValue
}

export const formatNumber = (
    value: number | string,
    decimalPlaces = 0
): string => {
    if (value === undefined || value.toString().length === 0) {
        return ''
    }

    return (+value).toLocaleString(NUM_DISPLAY_CULTURE, {
        maximumFractionDigits: decimalPlaces,
    })
}

export const formatAsPercentage = (value: number): string => {
    return `${formatNumber(value, 0)} %`
}

export const SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB'] as const
export type SizeUnit = (typeof SIZE_UNITS)[number]

export const formatSize = (
    value: number | string,
    from: SizeUnit,
    to: SizeUnit
): string => {
    if (typeof value === 'string') {
        value = parseFloat(value)
    }

    if (from === to) {
        return `${formatNumber(value, 2)} ${to}`
    }

    const fromIndex = SIZE_UNITS.indexOf(from)
    const toIndex = SIZE_UNITS.indexOf(to)

    if (fromIndex === -1 || toIndex === -1) {
        throw new Error('Invalid size unit')
    }

    const diff = toIndex - fromIndex
    const factor = Math.pow(1024, diff)

    return `${formatNumber(value / factor, 2)} ${to}`
}
