import { MigrationManifest } from 'redux-persist'

/**
 * Store migrations
 * To handle any changes to the store structure, we need to create a migration
 * the migration will be called only if the version of the store in local storage is different
 */
export const storeMigrations: MigrationManifest = {
    // Version 4:
    // Adding the new alarms filters and settings
    4: (state: any) => {
        const DEFAULT_INTERVAL = 60 * 1000 // 1 minute
        const newState = { ...state }

        if (
            newState.alarms?.interval &&
            newState.alarms.interval < DEFAULT_INTERVAL
        ) {
            newState.alarms.interval = DEFAULT_INTERVAL
        }

        return newState
    },

    // Version 5:
    5: (state: any) => {
        const newState = { ...state }

        if (!newState.network?.treeParams) {
            newState.network.treeParams = {
                expandedKeys: [],
                autoExpandParent: false,
            }
        }

        return newState
    },

    // Version 6
    // Adding permissions to auth slice
    6: (state: any) => {
        const newState = { ...state }

        if (!newState.auth?.permissions) {
            newState.auth.isAuthenticated = false
            newState.auth.user = undefined
        }

        return newState
    },
}
