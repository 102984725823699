import { EuiTab, EuiTabs } from '@elastic/eui'
import { useEffect, useMemo, useState } from 'react'

import { useLocation } from 'react-router-dom'

type TabsProps = {
    children: JSX.Element[]
    destroyOnHide?: boolean
    initialTab?: string
    useHref?: boolean
}

const Tabs = (props: TabsProps) => {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(
        props.useHref
            ? location.hash.substring(1) ?? props.initialTab
            : props.initialTab
    )

    const tabItems = useMemo(
        () =>
            props.children
                .filter((child) => !child.props.hide)
                .map((child, index) => {
                    const tabId = child.props.id ?? `tab-${index}`

                    return {
                        id: tabId,
                        onClick: () => setActiveTab(tabId),
                        isSelected: tabId === activeTab,
                        href: props.useHref ? '#' + tabId : undefined,
                        content: child.props.children,
                        title: child.props.title,
                        ...child.props,
                    }
                }),
        [props.children, activeTab]
    )

    const tabContent = useMemo(
        () =>
            props.destroyOnHide
                ? tabItems.find((t) => t.id === activeTab)
                : null,
        [tabItems]
    )

    useEffect(() => {
        if (tabItems.length > 0 && !props.initialTab && !location.hash) {
            setActiveTab(tabItems[0].id)
        }
    }, [])

    return (
        <div style={{ marginTop: -24 }}>
            <EuiTabs>
                {tabItems.map((tabItem, index) => (
                    <EuiTab {...tabItem} key={`tab-button-${index}`}>
                        {tabItem.title}
                    </EuiTab>
                ))}
            </EuiTabs>

            {/* Tabs must be destroyed on hide, therefore we need to render only the selected one */}
            {props.destroyOnHide && tabContent && tabContent.content}

            {/* Render the content of all tabs as it will not be destroyed on hide */}
            {!props.destroyOnHide &&
                tabItems.map((tabItem, index) => (
                    <div
                        key={`tab-content-${index}`}
                        className={
                            tabItem.id !== activeTab ? 'hidden' : undefined
                        }
                    >
                        {tabItem.content}
                    </div>
                ))}
        </div>
    )
}
export default Tabs
