import {
    ALARMS_DEFAULT_REFRESH_INTERVAL,
    ALARMS_MIN_REFRESH_INTERVAL,
} from '@utils/globals'
import {
    AlarmsState,
    setAlarmsInterval,
    setAlarmsPrefrences,
    setAlarmsRealtime,
} from '@store/alarms'
import {
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormLabel,
    EuiPanel,
    EuiPopover,
    EuiRefreshInterval,
    EuiSwitch,
    EuiSwitchEvent,
    OnRefreshChangeProps,
} from '@elastic/eui'
import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import AlarmsPrefrencesColumns from './AlarmsPrefrencesColumns'
import { useTranslation } from 'react-i18next'

const AlarmsPrefrences: FC = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['common', 'alarms'])

    const [isOpen, setIsOpen] = useState(false)
    const { realtime, interval, prefrences } = useAppSelector(
        (state) => state.alarms
    )

    const handleOnRefreshChange = ({
        isPaused,
        refreshInterval,
    }: OnRefreshChangeProps) => {
        dispatch(setAlarmsInterval(!isPaused ? refreshInterval : undefined))
    }

    const handleOnRealtimeChange = ({ target }: EuiSwitchEvent) => {
        dispatch(setAlarmsRealtime(target.checked))
    }

    const handleOnPrefrencesChange = (
        prefrences: AlarmsState['prefrences']
    ) => {
        dispatch(setAlarmsPrefrences(prefrences))
    }

    const button = prefrences?.iconsOnly ? (
        <EuiButtonIcon
            iconType="controlsVertical"
            color={isOpen ? 'primary' : 'text'}
            size="s"
            aria-label={t('alarms:settings')}
            title={t('alarms:settings')}
            onClick={() => setIsOpen(!isOpen)}
        />
    ) : (
        <EuiButtonEmpty
            iconType="controlsVertical"
            color={isOpen ? 'primary' : 'text'}
            size="s"
            onClick={() => setIsOpen(!isOpen)}
        >
            {t('alarms:settings')}
        </EuiButtonEmpty>
    )

    useEffect(() => {
        if (interval && interval < ALARMS_MIN_REFRESH_INTERVAL) {
            dispatch(setAlarmsInterval(ALARMS_DEFAULT_REFRESH_INTERVAL))
        }
    }, [interval])

    return (
        <>
            {button}
            <EuiPopover
                hasDragDrop
                isOpen={isOpen}
                closePopover={() => setIsOpen(false)}
            >
                <EuiPanel hasShadow={false} paddingSize="s">
                    <EuiFlexGroup direction="column" gutterSize="m">
                        <EuiFlexItem>
                            <EuiRefreshInterval
                                onRefreshChange={handleOnRefreshChange}
                                refreshInterval={
                                    interval || ALARMS_DEFAULT_REFRESH_INTERVAL
                                }
                                minInterval={ALARMS_MIN_REFRESH_INTERVAL}
                                isPaused={interval === undefined}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:only_active_alarms')}
                                    </EuiFormLabel>
                                }
                                checked={realtime}
                                onChange={handleOnRealtimeChange}
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:display_resovled_temp')}
                                    </EuiFormLabel>
                                }
                                disabled={!realtime}
                                checked={prefrences?.displayResolved || false}
                                onChange={(e) =>
                                    handleOnPrefrencesChange({
                                        ...prefrences,
                                        displayResolved: e.target.checked,
                                    })
                                }
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:compressed_layout')}
                                    </EuiFormLabel>
                                }
                                checked={prefrences?.compressedLayout}
                                onChange={(e) =>
                                    handleOnPrefrencesChange({
                                        ...prefrences,
                                        compressedLayout: e.target.checked,
                                    })
                                }
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:icons_only')}
                                    </EuiFormLabel>
                                }
                                checked={prefrences?.iconsOnly}
                                onChange={(e) =>
                                    handleOnPrefrencesChange({
                                        ...prefrences,
                                        iconsOnly: e.target.checked,
                                    })
                                }
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:human_readable_dates')}
                                    </EuiFormLabel>
                                }
                                checked={prefrences?.humanReadableDates}
                                onChange={(e) =>
                                    handleOnPrefrencesChange({
                                        ...prefrences,
                                        humanReadableDates: e.target.checked,
                                    })
                                }
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:display_values')}
                                    </EuiFormLabel>
                                }
                                checked={prefrences?.displayValues}
                                onChange={(e) =>
                                    handleOnPrefrencesChange({
                                        ...prefrences,
                                        displayValues: e.target.checked,
                                    })
                                }
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:display_tech_details')}
                                    </EuiFormLabel>
                                }
                                checked={prefrences?.displayTechDetails}
                                onChange={(e) =>
                                    handleOnPrefrencesChange({
                                        ...prefrences,
                                        displayTechDetails: e.target.checked,
                                    })
                                }
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiSwitch
                                label={
                                    <EuiFormLabel type="legend">
                                        {t('alarms:display_full_tech_details')}
                                    </EuiFormLabel>
                                }
                                disabled={!prefrences?.displayTechDetails}
                                checked={prefrences?.displayFullTechDetails}
                                onChange={(e) =>
                                    handleOnPrefrencesChange({
                                        ...prefrences,
                                        displayFullTechDetails:
                                            e.target.checked,
                                    })
                                }
                                compressed
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <AlarmsPrefrencesColumns />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </EuiPopover>
        </>
    )
}

export default AlarmsPrefrences
