import { CollectionResult, Query, getDefaultQuery } from '@services/api'
import { useCallback, useEffect, useState } from 'react'

import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { debounce } from 'lodash'

const SEARCH_DEBOUNCE = 300 // ms

export type SearchQueryProps = {
    query?: Query
    append?: Partial<Query>
    search: {
        field: string
        value?: string
    }
}

const getQuery = (config?: SearchQueryProps) => {
    const newQuery = config?.query ? { ...config.query } : getDefaultQuery()

    if (config?.append) {
        return { ...newQuery, ...config.append }
    }

    return newQuery
}

const setSearchQuery = (config: SearchQueryProps) => {
    const { search } = config
    const query = getQuery(config)

    if (search.value && search.value.length > 0) {
        return {
            ...query,
            filters: [
                ...(query.filters || []),
                `${search.field}@=${search.value}`,
            ],
        }
    }

    return query
}

const useSearchQuery = <T>(
    queryHook: UseQuery<any>,
    config: SearchQueryProps
) => {
    const [query, setQuery] = useState<Query>(getQuery(config))
    const [isTyping, setIsTyping] = useState(false)
    const { data, isLoading, isFetching } = queryHook(query)

    const handleOnSearchChange = useCallback(
        debounce((config: SearchQueryProps) => {
            setQuery(setSearchQuery(config))
            setIsTyping(false)
        }, SEARCH_DEBOUNCE),
        []
    )

    useEffect(() => {
        setQuery(getQuery(config))
    }, [config.query, config.append])

    useEffect(() => {
        setIsTyping(true)
        handleOnSearchChange(config)
    }, [config.search])

    return {
        data: data as CollectionResult<T> | undefined,
        isLoading: isLoading || isFetching || isTyping,
    }
}

export default useSearchQuery
