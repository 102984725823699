import { Option, OptionsSource } from '@services/options'

import { appApi } from '../app.api'
import { invalidateCacheTag } from '../helpers'

type FetchOptionProps = {
    source: OptionsSource
    id: number | string
    inheritanceSourceId?: number | string | undefined
    inheritanceSources?: string[]
}

const getFetchQuery = (props: FetchOptionProps) => {
    switch (props.source) {
        case 'tenant':
            return `tenants/${props.id}/options`
        case 'group':
            if (props.inheritanceSources && props.inheritanceSources.length > 0)
                return `groups/${props.id}/options?${props.inheritanceSources.join('&')}`
            else return `groups/${props.id}/options`
        case 'device':
            if (props.inheritanceSourceId)
                return `devices/${props.id}/options?parentGroupId=${props.inheritanceSourceId}`
            else return `devices/${props.id}/options`
        case 'sensor':
            if (props.inheritanceSourceId)
                return `sensors/${props.id}/options?deviceId=${props.inheritanceSourceId}`
            else return `sensors/${props.id}/options`
    }
}

const optionsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchOptions: builder.query<Option[], FetchOptionProps>({
            providesTags: ['Option'],
            query: (props) => getFetchQuery(props),
        }),
        updateSensorChannelOption: builder.mutation<void, Option>({
            query: (req) => ({
                url: 'sensors/channel-option',
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: () =>
                invalidateCacheTag('Sensor').concat(['Option']),
        }),
    }),
})

export const { useFetchOptionsQuery, useUpdateSensorChannelOptionMutation } =
    optionsApi
