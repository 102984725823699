import { EuiButton, EuiEmptyPrompt, EuiIcon } from '@elastic/eui'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import { pascalToCamelCase } from '@utils/strings'
import { setNotificationsState } from '@store/notifications'
import svg from '../ErrorImage.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ForbiddenAccess = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { exceptionMessages } = useAppSelector((state) => state.notifications)

    const { t } = useTranslation(['common', 'errors', 'roles:permissions'])

    const handleOnHomeClick = () => {
        dispatch(
            setNotificationsState({
                overlayNotification: undefined,
                exceptionMessages: undefined,
            })
        )
        navigate('/')
    }

    const getBody = (exceptionMsgs: string[] | undefined) => {
        const body = <p>{t('errors:forbidden_access_message')}</p>

        if (!exceptionMsgs || exceptionMsgs.length < 1) return body

        let msgs: string[] = []

        exceptionMsgs.forEach((ex) => {
            if (!ex.startsWith('Missing permission')) return

            const permission = ex.split(': ')?.[1]?.trim()
            msgs = [
                ...msgs,
                t(`roles:permissions:${pascalToCamelCase(permission)}`),
            ]
        })

        // Remove duplicates
        msgs = msgs.filter((f, i, arr) => arr.indexOf(f) === i)

        return (
            <>
                {body}
                {msgs.length > 0 && (
                    <div>
                        <p>
                            {t(
                                'errors:forbidden_access_required_permissions_text'
                            )}
                        </p>
                        <p>
                            {msgs.map((msg, i) => (
                                <div key={`permission-exc-msg-${i}`}>
                                    <span>{msg}</span>
                                </div>
                            ))}
                        </p>
                    </div>
                )}
            </>
        )
    }

    return (
        <EuiEmptyPrompt
            color="subdued"
            icon={<EuiIcon type={svg} size="original" />}
            title={
                <h2 style={{ marginTop: '3rem' }}>
                    {t('errors:forbidden_access_title')}
                </h2>
            }
            layout="vertical"
            body={getBody(exceptionMessages)}
            actions={[
                <EuiButton
                    color="primary"
                    key="home-button"
                    onClick={handleOnHomeClick}
                    fill
                >
                    {t('common:go_home')}
                </EuiButton>,
            ]}
        />
    )
}

export default ForbiddenAccess
