export type AppConfig = {
    apiUrl: string
    workerVersion: string
}

export const getAppConfig = (): AppConfig => {
    const env = (window as any).env

    return {
        apiUrl: env.apiUrl,
        workerVersion: env.workerVersion,
    }
}

export const isDebug = () => process.env.NODE_ENV === 'development'
