import {
    ALL_OPTION_TYPES,
    Option,
    OptionsSource,
    OptionsType,
} from '@services/options'
import { useEffect, useMemo, useState } from 'react'

import OptionsEditorGroup from './OptionsEditorGroup'
import { useFetchOptionsQuery } from '@services/api'

type OptionsEditorProps = {
    source: OptionsSource
    sourceId?: number | string
    inheritanceSourceId?: number | string
    inheritanceSources?: string[]
    optionsGroups?: OptionsType[]
    onChange: (options: Option[]) => void
    isDisabled?: boolean
}

const OptionsEditor = (props: OptionsEditorProps) => {
    const [skip, setSkip] = useState(true)
    const { data, isLoading } = useFetchOptionsQuery(
        {
            source: props.source,
            id: props.sourceId !== undefined ? props.sourceId : '',
            inheritanceSourceId:
                props.inheritanceSourceId !== undefined
                    ? props.inheritanceSourceId
                    : '',
            inheritanceSources: props.inheritanceSources,
        },
        { skip }
    )

    const groups = useMemo(
        () => (props.optionsGroups ? props.optionsGroups : ALL_OPTION_TYPES),
        [props.optionsGroups]
    )

    const handleOnChange = (options: Option[]) => {
        const newOptions = options.map((option) => ({
            ...option,
            value: option.value?.toString(),
        }))

        props.onChange(newOptions)
    }

    useEffect(() => {
        if (props.sourceId !== undefined && props.sourceId !== 'new') {
            setSkip(false)
        }
    }, [props.sourceId])

    useEffect(() => {
        if (data) {
            handleOnChange(data)
        }
    }, [data])

    return (
        <>
            {groups.map((group, i) => (
                <OptionsEditorGroup
                    key={`options-group-${i}`}
                    optionsType={group}
                    isOpen={
                        i === 0 ||
                        group === 'wmi_service' ||
                        group === 'ssh_service'
                    }
                    options={data}
                    isLoading={isLoading}
                    onChange={handleOnChange}
                    isDisabled={props.isDisabled}
                    sourceId={props.sourceId}
                />
            ))}
        </>
    )
}
export default OptionsEditor
