import {
    AlarmPingOptionKey,
    AlarmSshOptionKey,
    AlarmWmiOptionKey,
    FtpOptionKey,
    GeneralOptionKey,
    HttpOptionKey,
    NOTIFICATION_PLACEHOLDERS,
    NotificationEmailOptionKey,
    NotificationPushOptionKey,
    Option,
    PingOptionKey,
    SnmpAuthMethods,
    SnmpEncryptionTypes,
    SnmpOptionKey,
    SnmpVersions,
    SshOptionKey,
    SshServiceOptionKey,
    WmiOptionKey,
    WmiServiceOptionKey,
} from '@services/options'
import { EuiIcon, EuiLink, EuiSwitch, EuiText, EuiToolTip } from '@elastic/eui'
import { useEffect, useMemo, useState } from 'react'

import { AlarmAllStatus } from '@services/alarms'
import AlarmOptionChannelGroupField from './AlarmOptionChannelGroupField'
import AlarmOptionField from './AlarmOptionField'
import AlarmOptionSshServiceGroup from './AlarmOptionsSshServiceGroup'
import AlarmOptionWmiServiceGroup from './AlarmOptionWmiServiceGroup'
import HttpHeadersOptionField from './HttpHeadersOptionField'
import NotificationSubscribersOptionField from './NotificationSubscribersOptionField'
import NotificationTemplateOptionField from './NotificationTemplateOptionField'
import OptionBooleanField from './OptionBooleanField'
import OptionSelectField from './OptionSelectField'
import OptionTextField from './OptionTextField'
import { SensorHttpMethods } from '@services/sensors'
import i18n from '@locale/i18n'
import { useTranslation } from 'react-i18next'

type OptionFieldProps = {
    option: Option
    onChange: (option: Option) => void
    isDisabled?: boolean
    fieldLabel?: string
    sourceId?: number | string
}

const getOptionField = (
    option: Option,
    onChange: any,
    onChannelChange: (option: Option) => void,
    helpText: JSX.Element | undefined,
    tooltip?: JSX.Element | undefined,
    isDisabled?: boolean,
    fieldLabel?: string,
    sourceId?: number | string
) => {
    const notificationPlaceholders = {
        title: 'notification_placeholders_info',
        content: (
            <ul style={{ marginLeft: 10 }}>
                {NOTIFICATION_PLACEHOLDERS.map((p) => (
                    <li key={p}>
                        <code>%{p}%</code>
                    </li>
                ))}
            </ul>
        ),
    }

    switch (option.key) {
        case GeneralOptionKey.AlarmCoolDown:
        case NotificationPushOptionKey.Delay:
        case NotificationEmailOptionKey.Delay:
            return (
                <OptionTextField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    inputType="time-string"
                    placeholder="HH:MM:SS"
                    append={tooltip}
                    isDisabled={isDisabled}
                />
            )
        case WmiOptionKey.Username:
        case WmiOptionKey.Password:
        case WmiServiceOptionKey.Name:
        case SnmpOptionKey.Community:
        case SnmpOptionKey.ContextName:
        case SnmpOptionKey.UserName:
        case SnmpOptionKey.Password:
        case SnmpOptionKey.EncryptionKey:
        case FtpOptionKey.Username:
        case FtpOptionKey.Password:
        case SshOptionKey.Username:
        case SshOptionKey.Password:
        case SshServiceOptionKey.Name:
        case NotificationEmailOptionKey.Subject:
        case NotificationPushOptionKey.Subject:
            return (
                <OptionTextField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                />
            )
        case SnmpOptionKey.Version:
            return (
                <OptionSelectField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                    values={SnmpVersions.map((s) => ({
                        value: s,
                        text: i18n.t(`options:snmp_version_${s}`),
                    }))}
                />
            )
        case SnmpOptionKey.EncryptionType:
            return (
                <OptionSelectField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                    values={SnmpEncryptionTypes.map((s) => ({
                        value: s,
                        text: i18n.t(`options:snmp_encryption_${s}`),
                    }))}
                />
            )
        case SnmpOptionKey.AuthMethod:
            return (
                <OptionSelectField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                    values={SnmpAuthMethods.map((s) => ({
                        value: s,
                        text: i18n.t(`options:snmp_auth_method_${s}`),
                    }))}
                />
            )
        case HttpOptionKey.RestMethod:
            return (
                <OptionSelectField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                    values={SensorHttpMethods.map((s) => ({
                        value: s,
                        text: s,
                    }))}
                />
            )
        case HttpOptionKey.RestHeaders:
            return (
                <HttpHeadersOptionField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                />
            )
        case HttpOptionKey.RestPayload:
            return (
                <OptionTextField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    inputType="code"
                    isDisabled={isDisabled}
                />
            )
        case HttpOptionKey.Url:
            return (
                <OptionTextField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                />
            )
        case HttpOptionKey.PortNumber:
        case PingOptionKey.TriesCount:
        case PingOptionKey.PacketLossCount:
            return (
                <OptionTextField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    append={tooltip}
                    inputType="number"
                    isDisabled={isDisabled}
                />
            )
        case HttpOptionKey.UseHttps:
        case HttpOptionKey.UseRest:
        case WmiOptionKey.AppendHostNameToUser:
        case FtpOptionKey.EnableSSL:
        case NotificationPushOptionKey.Enabled:
        case NotificationEmailOptionKey.Enabled:
            return (
                <OptionBooleanField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                />
            )
        case AlarmSshOptionKey.Processor:
        case AlarmSshOptionKey.Memory:
        case AlarmWmiOptionKey.Processor:
        case AlarmWmiOptionKey.Memory:
            return (
                <AlarmOptionField
                    option={option}
                    helpText={helpText}
                    onChange={onChange}
                    unit={'%'}
                    isDisabled={isDisabled}
                />
            )
        case AlarmSshOptionKey.Disk:
        case AlarmWmiOptionKey.Disk:
            return (
                <AlarmOptionChannelGroupField
                    option={option}
                    helpText={helpText}
                    onChange={onChange}
                    onChannelChange={onChannelChange}
                    unit={'%'}
                    isDisabled={isDisabled}
                />
            )
        case AlarmSshOptionKey.DiskChannel:
        case AlarmWmiOptionKey.DiskChannel:
            return (
                <AlarmOptionField
                    label={fieldLabel}
                    option={option}
                    helpText={helpText}
                    onChange={onChange}
                    unit={'%'}
                    isDisabled={isDisabled}
                />
            )
        case AlarmWmiOptionKey.Service:
            return (
                <AlarmOptionWmiServiceGroup
                    option={option}
                    helpText={helpText}
                    onChange={onChange}
                    isDisabled={isDisabled}
                    sourceId={sourceId}
                />
            )
        case AlarmSshOptionKey.Service:
            return (
                <AlarmOptionSshServiceGroup
                    option={option}
                    helpText={helpText}
                    onChange={onChange}
                    isDisabled={isDisabled}
                />
            )
        case AlarmPingOptionKey.Time:
        case AlarmPingOptionKey.Ttl:
        case AlarmPingOptionKey.Bytes:
            return (
                <AlarmOptionField
                    option={option}
                    helpText={helpText}
                    onChange={onChange}
                    unit={
                        option.key === AlarmPingOptionKey.Bytes ? 'bytes' : 'ms'
                    }
                    isDisabled={isDisabled}
                />
            )
        case NotificationPushOptionKey.Template:
            return (
                <NotificationTemplateOptionField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    extraInfo={notificationPlaceholders}
                    isDisabled={isDisabled}
                />
            )
        case NotificationEmailOptionKey.Template:
            return (
                <NotificationTemplateOptionField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    extraInfo={notificationPlaceholders}
                    html
                    isDisabled={isDisabled}
                />
            )
        case NotificationEmailOptionKey.Subscribers:
            return (
                <NotificationSubscribersOptionField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                />
            )
        case NotificationPushOptionKey.MinLevel:
        case NotificationEmailOptionKey.MinLevel:
            return (
                <OptionSelectField
                    option={option}
                    onChange={onChange}
                    helpText={helpText}
                    isDisabled={isDisabled}
                    values={AlarmAllStatus.map((s) => ({
                        value: s,
                        text: i18n.t(
                            `alarms:status_${s?.toString().toLowerCase()}`
                        ),
                    }))}
                />
            )
        default:
            return <span>Unknown</span>
    }
}

const OptionField = (props: OptionFieldProps) => {
    const [option, setOption] = useState(props.option)
    const { t } = useTranslation(['options'])

    const handleUnlink = (unlink: boolean) => {
        setOption({ ...option, isInherited: !unlink })
        props.onChange({ ...props.option, isInherited: !unlink })
    }

    const tooltip = useMemo(() => {
        if (
            option.key === GeneralOptionKey.AlarmCoolDown ||
            option.key === NotificationPushOptionKey.Delay ||
            option.key === NotificationEmailOptionKey.Delay
        ) {
            return (
                <EuiToolTip
                    content={t(`options:${option.key.toLowerCase()}_tooltip`)}
                >
                    <EuiIcon type="clock" />
                </EuiToolTip>
            )
        }

        if (
            option.key === PingOptionKey.TriesCount ||
            option.key === PingOptionKey.PacketLossCount
        )
            return (
                <EuiToolTip
                    content={t(`options:${option.key.toLowerCase()}_tooltip`)}
                >
                    <EuiIcon type="questionInCircle" />
                </EuiToolTip>
            )

        return undefined
    }, [option])

    const helpText = useMemo(() => {
        if (option.isInherited) {
            return (
                <span>
                    <EuiSwitch
                        style={{ marginTop: 2 }}
                        checked={option.isInherited}
                        onChange={() => handleUnlink(true)}
                        compressed
                        disabled={props.isDisabled}
                        label={
                            option.inheritanceSource ? (
                                <EuiText size="xs">
                                    {t('options:inherited_from', {
                                        type: option.inheritanceSource?.type.toLowerCase(),
                                    })}
                                    <EuiLink style={{ marginLeft: 5 }}>
                                        {option.inheritanceSource?.name}
                                    </EuiLink>
                                    .
                                </EuiText>
                            ) : (
                                <EuiText size="xs">
                                    {t('options:no_inheritance_available')}
                                </EuiText>
                            )
                        }
                    />
                </span>
            )
        }

        if (!option.isInherited && option.inheritanceSource) {
            return (
                <span>
                    <EuiSwitch
                        style={{ marginTop: 2 }}
                        checked={option.isInherited}
                        onChange={() => handleUnlink(false)}
                        disabled={props.isDisabled}
                        compressed
                        label={
                            <EuiText size="xs">
                                {t('options:link_to_inherit_from', {
                                    type: option.inheritanceSource?.type.toLowerCase(),
                                })}
                                <EuiLink style={{ marginLeft: 5 }}>
                                    {option.inheritanceSource?.name}
                                </EuiLink>
                                .
                            </EuiText>
                        }
                    />
                </span>
            )
        }

        return undefined
    }, [option, props.isDisabled, props.onChange])

    const handleOnChange = (op: Option) => {
        // If value is undefined as alarm option, do not update it
        if (op.value === 'undefined;undefined') return

        setOption(op)
        props.onChange(op)
    }

    const Field = useMemo(
        () =>
            getOptionField(
                option,
                handleOnChange,
                props.onChange,
                helpText,
                tooltip,
                props.isDisabled,
                props.fieldLabel,
                props.sourceId
            ),
        [option, props.isDisabled, helpText, props.onChange]
    )

    useEffect(() => {
        setOption({ ...props.option })
    }, [props.option])

    return <>{Field}</>
}

export default OptionField
