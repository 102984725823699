import { EuiBadge, EuiButton, EuiCode } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import { Table, TableColumn } from '@components/table'
import { formatFullDate, formatReadableDate } from '@utils/dates'
import { useDeleteApiKeyMutation, useFetchApiKeysQuery } from '@services/api'

import { ApiKey } from '@services/auth'
import { Page } from '@components/layout'
import { useHasDependentPermissions } from '@hooks/auth'
import { useQuery } from '@hooks/query'
import { useTranslation } from 'react-i18next'

const ApiKeysPage = () => {
    const { t } = useTranslation(['common', 'apiKeys'])
    const navigate = useNavigate()
    const {
        query,
        handleQueryChange,
        handleQueryFiltersChange,
        clearQueryFilters,
    } = useQuery()

    const canCreate = useHasDependentPermissions('apiKey', [
        'canView',
        'canCreate',
    ])
    const canDelete = useHasDependentPermissions('apiKey', [
        'canView',
        'canDelete',
    ])

    const { data, isLoading } = useFetchApiKeysQuery(query)

    const [deleteApiKey, { isLoading: isDeleteLoading }] =
        useDeleteApiKeyMutation()

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('apiKeys:name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('apiKeys:filter_by_name'),
        },
        {
            field: 'token',
            name: t('apiKeys:token'),
            sortable: false,
            filterable: false,
            formatter: (value: string) => <EuiCode>{value}</EuiCode>,
        },
        {
            field: 'description',
            name: t('apiKeys:description'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('apiKeys:filter_by_description'),
        },
        {
            field: 'expiredAt',
            name: t('apiKeys:expires_at'),
            sortable: true,
            filterable: true,
            filterType: 'date',
            filterPlaceholder: t('apiKeys:filter_by_expires_at'),
            formatter: (value: string) => <>{formatFullDate(value)}</>,
        },
        {
            field: 'created',
            name: t('apiKeys:created'),
            sortable: true,
            filterable: false,
            filterType: 'date',
            filterPlaceholder: t('apiKeys:filter_by_created'),
            formatter: (value: string) => <>{formatFullDate(value)}</>,
        },
        {
            field: 'action',
            renderAs: 'action',
            onDelete: canDelete
                ? (apiKey: ApiKey) => deleteApiKey(apiKey.id)
                : undefined,
        },
    ]

    return (
        <>
            <Outlet />
            <Page
                title={t('apiKeys:title')}
                iconType="securityApp"
                items={
                    canCreate
                        ? [
                              <EuiButton
                                  key="btn-new-api-key"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/api-keys/new')}
                              >
                                  {t('apiKeys:new')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <Table<ApiKey>
                    loading={isLoading || isDeleteLoading}
                    items={data?.items ?? []}
                    totalCount={data?.totalCount ?? 0}
                    columns={columns}
                />
            </Page>
        </>
    )
}

export default ApiKeysPage
