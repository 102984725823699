import {
    EuiButton,
    EuiButtonEmpty,
    EuiButtonGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPopover,
    EuiText,
    useEuiTheme,
} from '@elastic/eui'
import { FC, useState } from 'react'
import { LOG_LEVELS, LogLevel } from '@utils/globals/globals'

import { getLevelTag } from '@services/agents'
import { useTranslation } from 'react-i18next'

type AgentDebugControllerProps = {
    isActive: boolean
    isLoading?: boolean
    disabled?: boolean
    onStop: () => void
    onStart: (level: LogLevel) => void
}

const AgentDebugController: FC<AgentDebugControllerProps> = ({
    isActive,
    isLoading,
    onStop,
    onStart,
    ...props
}) => {
    const { euiTheme } = useEuiTheme()

    const [level, setLevel] = useState<LogLevel>('Information')
    const [isOpen, setIsOpen] = useState(false)

    const { t } = useTranslation(['common', 'agents'])

    const handleOnStart = () => {
        setIsOpen(false)
        onStart(level)
    }

    return isActive ? (
        <EuiButtonEmpty
            disabled={props.disabled}
            iconType="stop"
            size="s"
            onClick={onStop}
        >
            {t('agents:debug_agent_stop')}
        </EuiButtonEmpty>
    ) : (
        <EuiPopover
            button={
                <EuiButton
                    iconType="play"
                    size="s"
                    onClick={() => setIsOpen(!isOpen)}
                    isLoading={isLoading}
                    fill
                    disabled={props.disabled}
                >
                    {t('agents:debug_agent_start')}
                </EuiButton>
            }
            isOpen={isOpen}
            closePopover={() => setIsOpen(false)}
        >
            <EuiFlexGroup
                style={{ maxWidth: 550 }}
                direction="column"
                gutterSize="l"
            >
                <EuiFlexItem>
                    <EuiText size="s">
                        <p>{t('agents:debug_agent_description')}</p>
                        <p>
                            {t('agents:debug_agent_start_level', {
                                level: getLevelTag(level),
                            })}
                        </p>
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                    <center>
                        <EuiButtonGroup
                            legend="Select a log level"
                            idSelected={level}
                            onChange={(o) => setLevel(o as LogLevel)}
                            options={LOG_LEVELS.map((l) => ({
                                id: l,
                                label: getLevelTag(l),
                            }))}
                        />
                    </center>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFlexGroup justifyContent="flexEnd" alignItems="center">
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                iconType="play"
                                size="s"
                                onClick={handleOnStart}
                                fill
                            >
                                {t('agents:debug_agent_start')}
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                iconType="cross"
                                color="danger"
                                onClick={() => setIsOpen(false)}
                            >
                                {t('common:cancel')}
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPopover>
    )
}

export default AgentDebugController
