import { EuiComboBoxOptionOption, EuiFormLabel, EuiSpacer } from '@elastic/eui'
import {
    getWmiServiceComboboxOptions,
    getWmiServiceComboboxOptionsFromString,
} from '@services/sensors'
import { useEffect, useMemo, useRef, useState } from 'react'

import AlarmOptionSelectField from './AlarmOptionSelectField'
import { EmptyGuid } from '@utils/guids'
import { Option } from '@services/options'
import { serializeAlarmSelectOptionValue } from '@services/options/options.service'
import { useTranslation } from 'react-i18next'

type AlarmOptionWmiServiceGroupProps = {
    option: Option
    helpText?: JSX.Element | undefined
    onChange: (option: Option) => void
    isDisabled?: boolean
    sourceId?: number | string
}

const AlarmOptionWmiServiceGroup = ({
    option,
    helpText,
    onChange,
    isDisabled,
    sourceId,
}: AlarmOptionWmiServiceGroupProps) => {
    const { t } = useTranslation(['options'])
    const label = t(`options:${option.key.toLowerCase()}`)
    const [warnings, setWarnings] = useState<EuiComboBoxOptionOption[]>([])
    const [errors, setErrors] = useState<EuiComboBoxOptionOption[]>([])

    const isInitialized = useRef<boolean>(false)
    const comboboxOptions = useMemo(() => getWmiServiceComboboxOptions(), [])

    useEffect(() => {
        if (isInitialized.current) return

        const warningString = (option.value as any)?.warning as string
        const errorString = (option.value as any)?.error as string

        if (warningString && errorString) isInitialized.current = true

        if (warningString)
            setWarnings(getWmiServiceComboboxOptionsFromString(warningString))

        if (errorString)
            setErrors(getWmiServiceComboboxOptionsFromString(errorString))

        if (
            (sourceId === 0 || sourceId === EmptyGuid || sourceId === 'new') &&
            option.inheritanceSource === undefined
        ) {
            if (!warningString) {
                setWarnings([
                    { value: 'WmiServiceStatus:Unknown', label: 'Unknown' },
                ])
            }
            if (!errorString) {
                setErrors([
                    { value: 'WmiServiceState:Stopped', label: 'Stopped' },
                ])
            }
        }
    }, [option.value, sourceId, option.inheritanceSource])

    useEffect(() => {
        const warningsString = serializeAlarmSelectOptionValue(warnings)
        const errorsString = serializeAlarmSelectOptionValue(errors)

        onChange({
            ...option,
            value: `${warningsString};${errorsString}`,
            isInherited:
                option.isInherited && option.inheritanceSource !== undefined,
        })
    }, [warnings, errors, option.isInherited, option.inheritanceSource])

    return (
        <>
            <EuiSpacer size="s" />
            <EuiFormLabel>{label}</EuiFormLabel>
            <EuiSpacer size="s" />
            <AlarmOptionSelectField
                label={t('options:alarm_wmi_service_warnings')}
                helpText={helpText}
                onChange={setWarnings}
                options={comboboxOptions}
                selectedOptions={warnings}
                isDisabled={isDisabled}
            />
            <AlarmOptionSelectField
                label={t('options:alarm_wmi_service_errors')}
                helpText={helpText}
                onChange={setErrors}
                options={comboboxOptions}
                selectedOptions={errors}
                isDisabled={isDisabled}
            />
        </>
    )
}

export default AlarmOptionWmiServiceGroup
