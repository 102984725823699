import { Outlet, useNavigate } from 'react-router-dom'
import { Table, TableColumn } from '@components/table'
import {
    useDeleteCategoriesMutation,
    useDeleteCategoryMutation,
    useFetchCategoriesQuery,
} from '@services/api'

import { Category } from '@services/categories'
import { EuiButton } from '@elastic/eui'
import { Page } from '@components/layout'
import { useHasDependentPermissions } from '@hooks/auth'
import { useQuery } from '@hooks/query'
import { useTranslation } from 'react-i18next'

const CategoriesPage = () => {
    const { t } = useTranslation(['common', 'categories'])
    const navigate = useNavigate()
    const {
        query,
        handleQueryChange,
        handleQueryFiltersChange,
        clearQueryFilters,
    } = useQuery()

    const canCreateCategory = useHasDependentPermissions('category', [
        'canView',
        'canCreate',
    ])
    const canUpdateCategory = useHasDependentPermissions('category', [
        'canView',
        'canUpdate',
    ])
    const canDeleteCategory = useHasDependentPermissions('category', [
        'canDelete',
    ])

    const { data, isLoading } = useFetchCategoriesQuery(query)

    const [deleteCategory, { isLoading: isDeleteLoading }] =
        useDeleteCategoryMutation()
    const [deleteCategories, { isLoading: isDeletesLoading }] =
        useDeleteCategoriesMutation()

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('categories:name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('categories:filter_by_name'),
        },
        {
            field: 'description',
            name: t('categories:description'),
            sortable: true,
            filterable: false,
            filterType: 'text',
        },
        {
            field: 'created',
            renderAs: 'date',
            name: t('categories:created'),
            sortable: true,
            filterable: false,
        },
        {
            field: 'lastModified',
            renderAs: 'date',
            name: t('categories:lastModified'),
            sortable: true,
            filterable: false,
        },
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateCategory ? 'onEdit' : 'onView']: (category: Category) =>
                navigate(`/categories/${category.id}`),
            onDelete: canDeleteCategory
                ? (category: Category) => deleteCategory(category.id)
                : undefined,
        },
    ]

    return (
        <>
            <Outlet />
            <Page
                title={t('categories:title')}
                iconType="managementApp"
                items={
                    canCreateCategory
                        ? [
                              <EuiButton
                                  key="btn_new_category"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/categories/new')}
                              >
                                  {t('categories:new')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <Table<Category>
                    loading={isLoading || isDeleteLoading || isDeletesLoading}
                    items={data?.items ?? []}
                    totalCount={data?.totalCount ?? 0}
                    columns={columns}
                    onFilter={handleQueryFiltersChange}
                    onFilterClear={clearQueryFilters}
                    onChange={handleQueryChange}
                    selectable={canDeleteCategory}
                    itemSelectable={() => true}
                    deleteSelectedRows={
                        canDeleteCategory
                            ? (items) =>
                                  deleteCategories(items.map((x) => x.id))
                            : undefined
                    }
                />
            </Page>
        </>
    )
}
export default CategoriesPage
