import { AlarmBreadcrumb, handleOnBreadcrumbClick } from '@services/alarms'
import { EuiBreadcrumb, EuiBreadcrumbs, EuiLink } from '@elastic/eui'
import { FC, useMemo } from 'react'

import { AlarmCellProps } from '.'
import { useAppSelector } from '@hooks/store'

const AlarmPathCell: FC<AlarmCellProps> = ({ record }) => {
    const { prefrences } = useAppSelector((state) => state.alarms)

    const breadcrumbs: EuiBreadcrumb[] = useMemo(() => {
        if (!record.breadcrumbs?.groups) return []

        return record.breadcrumbs.groups.map(
            (group) =>
                ({
                    text: (
                        <EuiLink onClick={() => handleOnBreadcrumbClick(group)}>
                            {group.name}
                        </EuiLink>
                    ),
                    color: 'primary',
                    truncate: true,
                    css: {
                        fontSize: prefrences?.compressedLayout
                            ? '0.8em'
                            : '1em',
                    },
                }) as EuiBreadcrumb
        )
    }, [record.breadcrumbs?.groups])

    return <EuiBreadcrumbs truncate max={3} breadcrumbs={breadcrumbs} />
}

export default AlarmPathCell
