import {
    EuiBadge,
    EuiBottomBar,
    EuiPageBody,
    EuiPageBodyProps,
    EuiPageHeader,
    EuiPageHeaderProps,
    EuiPageSection,
} from '@elastic/eui'

import Changelog from '../Changelog/Changelog'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { useFetchAppInfoQuery } from '@services/api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type PageProps = {
    title: string | JSX.Element
    customizedTitle?: boolean
    iconType?: string
    children: JSX.Element
    isSubPage?: boolean
    hasPadding?: boolean
    fullWidth?: boolean
    loading?: boolean
    paddingSize?: {
        header?: EuiPageHeaderProps['paddingSize']
        body?: EuiPageBodyProps['paddingSize']
    }
    items?: JSX.Element[]
}

const RESTRICT_WIDTH = '2200px'

const Page = (props: PageProps) => {
    const { data: appInfo } = useFetchAppInfoQuery(null)
    const { t } = useTranslation(['common'])
    const [isChangelogOpen, setIsChangelogOpen] = useState(false)

    const style = props.isSubPage
        ? {
              boxShadow: 'none',
              padding: 16,
              paddingTop: 0,
          }
        : null

    return (
        <>
            <EuiPageBody panelled style={style}>
                {!props.customizedTitle && (
                    <EuiPageHeader
                        paddingSize={props.paddingSize?.header || 'm'}
                        restrictWidth={props.fullWidth ? false : RESTRICT_WIDTH}
                        pageTitle={props.title}
                        style={{ textTransform: 'capitalize' }}
                        iconType={props.iconType}
                        rightSideItems={props.items}
                        bottomBorder
                        alignItems="bottom"
                        rightSideGroupProps={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    />
                )}
                {props.customizedTitle && (
                    <EuiPageHeader
                        paddingSize={'none'}
                        restrictWidth={props.fullWidth ? false : RESTRICT_WIDTH}
                        style={{
                            maxWidth: props.fullWidth ? 'none' : RESTRICT_WIDTH,
                            marginInline: 'auto',
                        }}
                        bottomBorder
                    >
                        {props.title}
                    </EuiPageHeader>
                )}

                <EuiPageBody
                    paddingSize="none"
                    color="transparent"
                    borderRadius="none"
                >
                    <EuiPageSection
                        paddingSize={props.paddingSize?.body || 'm'}
                        restrictWidth={props.fullWidth ? false : RESTRICT_WIDTH}
                    >
                        <LoadingSpinner loading={props.loading}>
                            {props.children}
                        </LoadingSpinner>
                    </EuiPageSection>
                    {!props.isSubPage && (
                        <>
                            <Changelog
                                isOpen={isChangelogOpen}
                                onClose={() => setIsChangelogOpen(false)}
                            />
                            <EuiBottomBar
                                position="fixed"
                                className="page-footer"
                                paddingSize="s"
                            >
                                <EuiBadge
                                    color="#333"
                                    iconType="editorCodeBlock"
                                    onClick={() => setIsChangelogOpen(true)}
                                    onClickAriaLabel="Open changelog"
                                >
                                    v{appInfo?.version}-{appInfo?.build}
                                </EuiBadge>
                                <EuiBadge
                                    color="#333"
                                    iconType="heart"
                                    href="https://www.technoberg.nl"
                                >
                                    {t('common:developed_by_technoberg')}
                                </EuiBadge>
                            </EuiBottomBar>
                        </>
                    )}
                </EuiPageBody>
            </EuiPageBody>
        </>
    )
}
export default Page
