import {
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiCode,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiIcon,
    EuiLink,
    EuiPanel,
    EuiPopover,
    EuiSpacer,
    EuiText,
} from '@elastic/eui'
import { FC, useEffect, useState } from 'react'

import { Option } from '@services/options'
import { useTranslation } from 'react-i18next'

type HttpHeaderEditorProps = {
    value: string
    type: 'key' | 'value'
    onChange: (value: string) => void
    defaultEditing?: boolean
    disabled?: boolean
}

const HttpHeaderEditor: FC<HttpHeaderEditorProps> = ({
    value,
    onChange,
    type,
    defaultEditing,
    disabled,
}) => {
    const [isEditing, setIsEditing] = useState(defaultEditing || false)
    const { t } = useTranslation(['common', 'options'])
    const [editValue, setEditValue] = useState(value)

    const handleOnSave = () => {
        console.log(editValue)
        onChange(editValue)
        setIsEditing(false)
    }

    const handleOnCancel = () => {
        setEditValue(value)
        setIsEditing(false)
    }

    return isEditing ? (
        <EuiFlexGroup gutterSize="xs" alignItems="center">
            <EuiFlexItem>
                <EuiFieldText
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    compressed
                    fullWidth
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiButtonIcon
                    iconType="check"
                    color="success"
                    size="xs"
                    iconSize="s"
                    title={t('common:save')}
                    aria-label={t('common:save')}
                    onClick={handleOnSave}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiButtonIcon
                    iconType="cross"
                    color="danger"
                    size="xs"
                    iconSize="s"
                    title={t('common:cancel')}
                    aria-label={t('common:cancel')}
                    onClick={handleOnCancel}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    ) : (
        <EuiLink
            onClick={!disabled ? () => setIsEditing(true) : undefined}
            color="text"
        >
            {type === 'key' ? (
                <EuiText size="xs">
                    <strong>{value}</strong>
                </EuiText>
            ) : (
                <EuiCode>{value}</EuiCode>
            )}
        </EuiLink>
    )
}

type HttpHeaderDeleteProps = {
    index: number
    disabled?: boolean
    onDelete: (index: number) => void
}

const HttpHeaderDelete: FC<HttpHeaderDeleteProps> = ({
    index,
    onDelete,
    disabled,
}) => {
    const { t } = useTranslation(['common', 'options'])
    const [isConfirming, setIsConfirming] = useState(false)

    return (
        <EuiPopover
            button={
                <EuiButtonIcon
                    iconType="trash"
                    color="danger"
                    size="xs"
                    iconSize="s"
                    disabled={disabled}
                    title={t('common:delete')}
                    aria-label={t('common:delete')}
                    onClick={() => setIsConfirming(!isConfirming)}
                />
            }
            panelPaddingSize="s"
            isOpen={isConfirming}
            closePopover={() => setIsConfirming(false)}
        >
            <EuiFlexGroup direction="column" gutterSize="xs">
                <EuiFlexItem grow={false}>
                    <EuiText size="s">
                        {t('common:delete_confirm_description')}
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                color="danger"
                                size="xs"
                                onClick={() => {
                                    onDelete(index)
                                    setIsConfirming(false)
                                }}
                            >
                                {t('common:delete')}
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                onClick={() => setIsConfirming(false)}
                                size="xs"
                            >
                                {t('common:cancel')}
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPopover>
    )
}

type HttpHeaderRecord = {
    key: string
    value: string
    added?: boolean
}

type HttpHeadersOptionFieldProps = {
    option: Option
    helpText?: JSX.Element | undefined
    onChange: (option: Option) => void
    isDisabled?: boolean
}

const HttpHeadersOptionField: FC<HttpHeadersOptionFieldProps> = ({
    option,
    onChange,
    isDisabled,
}) => {
    const { t } = useTranslation(['common', 'options'])

    const [headers, setHeaders] = useState<HttpHeaderRecord[]>([])

    const handleOnChange = (
        value: string,
        index: number,
        type: HttpHeaderEditorProps['type']
    ) => {
        const newHeaders = [...headers]
        if (type === 'key') {
            newHeaders[index].key = value
        } else {
            newHeaders[index].value = value
        }

        // At this point we can assume that the header is not new
        newHeaders[index].added = false

        setHeaders(newHeaders)
        onChange({ ...option, value: JSON.stringify(newHeaders) })
    }

    const handleOnDelete = (index: number) => {
        const newHeaders = [...headers]
        newHeaders.splice(index, 1)
        setHeaders(newHeaders)
        onChange({ ...option, value: JSON.stringify(newHeaders) })
    }

    const handleOnAdd = () => {
        const newHeaders = [
            ...headers,
            {
                key: '',
                value: '',
                added: true,
            },
        ]
        setHeaders(newHeaders)
        onChange({ ...option, value: JSON.stringify(newHeaders) })
    }

    useEffect(() => {
        try {
            if (option.value) {
                setHeaders(
                    JSON.parse(option.value as string) as HttpHeaderRecord[]
                )
            }
        } catch (e) {
            console.error(e)
        }
    }, [option])

    return (
        <EuiFormRow
            label={t('options:http_restheaders')}
            fullWidth
            isDisabled={isDisabled}
        >
            <EuiPanel hasShadow={false} paddingSize="xs" color="subdued">
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            size="xs"
                            iconType="plus"
                            onClick={handleOnAdd}
                            disabled={
                                option.isInherited || option.isEnabled === false
                            }
                        >
                            {t('options:http_restheaders_create')}
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size="s" />
                <EuiFlexGroup gutterSize="xs" direction="column">
                    {headers.map(({ value, key, added }, i) => (
                        <EuiFlexItem key={`${key}_${i}`}>
                            <EuiFlexGroup
                                gutterSize="xs"
                                alignItems="center"
                                wrap
                            >
                                <EuiFlexItem>
                                    <HttpHeaderEditor
                                        type="key"
                                        value={key}
                                        defaultEditing={added}
                                        disabled={
                                            option.isInherited ||
                                            option.isEnabled === false
                                        }
                                        onChange={(v) =>
                                            handleOnChange(v, i, 'key')
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <HttpHeaderEditor
                                        type="value"
                                        value={value}
                                        defaultEditing={added}
                                        disabled={
                                            option.isInherited ||
                                            option.isEnabled === false
                                        }
                                        onChange={(v) =>
                                            handleOnChange(v, i, 'value')
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <HttpHeaderDelete
                                        index={i}
                                        disabled={
                                            option.isInherited ||
                                            option.isEnabled === false
                                        }
                                        onDelete={handleOnDelete}
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    ))}

                    {headers.length === 0 && (
                        <EuiFlexItem>
                            <EuiFlexGroup
                                justifyContent="center"
                                alignItems="center"
                                gutterSize="m"
                            >
                                <EuiFlexItem grow={false}>
                                    <EuiIcon
                                        type="bell"
                                        size="l"
                                        color="lightgrey"
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiText color="grey" size="s">
                                        {t('options:http_restheaders_empty')}
                                    </EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiSpacer size="m" />
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            </EuiPanel>
        </EuiFormRow>
    )
}
export default HttpHeadersOptionField
