import { EuiFlexGrid, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'
import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import { Tab, Tabs } from '@components/tabs'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    useFetchNetworkSensorDataQuery,
    useFetchNetworkSensorSnapshotQuery,
} from '@services/api'

import { IfInView } from '@components/layout'
import { SnmpDataPointKeys as dataPointKeys } from '@services/sensors'
import { useSnmpData } from '@hooks/network'
import { useTranslation } from 'react-i18next'

type SnmpTrafficChartProps = ChartProps & {
    sensorId: number
}

const interfacesHolder: string[] = []

const SnmpTrafficChart = ({
    data,
    query,
    setQuery,
    sensorId,
}: SnmpTrafficChartProps) => {
    const { charts, chartsData, onInterfaceChange, isLoading } = useSnmpData(
        sensorId,
        query
    )

    const { t } = useTranslation(['common', 'network'])
    const xAxisDomain = useMemo(() => getDataDomainByQuery(query), [query])

    return (
        <>
            <div style={{ marginTop: 10 }}>
                <Tabs initialTab="bandwidth" destroyOnHide>
                    <Tab id="bandwidth" title={t('network:snmp_tab_bandwidth')}>
                        <EuiFlexGrid columns={2}>
                            {charts.bandwidth.map((chart) => (
                                <EuiFlexItem key={`chart_${chart.title}`}>
                                    <EuiFlexGrid direction="row">
                                        <EuiFlexItem>
                                            <EuiText>
                                                {`[${chart.key}] `}
                                                {`${t('common:interface')}: ${chart.title}`}
                                            </EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <IfInView
                                                height={250}
                                                onChange={onInterfaceChange}
                                                viewId={chart.key.toString()}
                                                isLoading={isLoading}
                                            >
                                                <NetworkLineChart
                                                    data={chartsData}
                                                    isAnimationActive={false}
                                                    query={query}
                                                    setQuery={setQuery}
                                                    lines={chart.lines}
                                                    xAxis="timestamp"
                                                    xAxisFormatter={(v) =>
                                                        formatTimestamp(
                                                            v,
                                                            query
                                                        )
                                                    }
                                                    yAxisFormatter={(v) =>
                                                        `${v} Mbps`
                                                    }
                                                    xAxisDomain={xAxisDomain}
                                                    yAxisDomain={[
                                                        0,
                                                        chart.maxBandwidth,
                                                    ]}
                                                    height={250}
                                                />
                                            </IfInView>
                                        </EuiFlexItem>
                                    </EuiFlexGrid>
                                    <EuiSpacer />
                                </EuiFlexItem>
                            ))}
                        </EuiFlexGrid>
                    </Tab>
                    <Tab id="errors" title={t('network:snmp_tab_errors')}>
                        <EuiFlexGrid columns={2}>
                            {charts.errors.map((chart) => (
                                <EuiFlexItem key={`chart_${chart.title}`}>
                                    <EuiFlexGrid direction="row">
                                        <EuiFlexItem>
                                            <EuiText>{`${t('common:interface')}: ${chart.title}`}</EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <IfInView
                                                height={250}
                                                onChange={onInterfaceChange}
                                                viewId={chart.key.toString()}
                                                isLoading={isLoading}
                                            >
                                                <NetworkLineChart
                                                    data={chartsData}
                                                    isAnimationActive={false}
                                                    query={query}
                                                    setQuery={setQuery}
                                                    lines={chart.lines}
                                                    xAxis="timestamp"
                                                    xAxisFormatter={(v) =>
                                                        formatTimestamp(
                                                            v,
                                                            query
                                                        )
                                                    }
                                                    yAxisFormatter={(v) => v}
                                                    xAxisDomain={xAxisDomain}
                                                    yAxisDomain={[
                                                        0,
                                                        chart.maxBandwidth,
                                                    ]}
                                                    height={250}
                                                />
                                            </IfInView>
                                        </EuiFlexItem>
                                    </EuiFlexGrid>
                                    <EuiSpacer />
                                </EuiFlexItem>
                            ))}
                        </EuiFlexGrid>
                    </Tab>
                    <Tab id="packets" title={t('network:snmp_tab_packets')}>
                        <EuiFlexGrid columns={2}>
                            {charts.packets.map((chart) => (
                                <EuiFlexItem key={`chart_${chart.title}`}>
                                    <EuiFlexGrid direction="row">
                                        <EuiFlexItem>
                                            <EuiText>{`${t('common:interface')}: ${chart.title}`}</EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <IfInView
                                                height={250}
                                                onChange={onInterfaceChange}
                                                viewId={chart.key.toString()}
                                                isLoading={isLoading}
                                            >
                                                <NetworkLineChart
                                                    data={chartsData}
                                                    isAnimationActive={false}
                                                    query={query}
                                                    setQuery={setQuery}
                                                    lines={chart.lines}
                                                    xAxis="timestamp"
                                                    xAxisFormatter={(v) =>
                                                        formatTimestamp(
                                                            v,
                                                            query
                                                        )
                                                    }
                                                    yAxisFormatter={(v) => v}
                                                    xAxisDomain={xAxisDomain}
                                                    yAxisDomain={[
                                                        0,
                                                        chart.maxBandwidth,
                                                    ]}
                                                    height={250}
                                                />
                                            </IfInView>
                                        </EuiFlexItem>
                                    </EuiFlexGrid>
                                    <EuiSpacer />
                                </EuiFlexItem>
                            ))}
                        </EuiFlexGrid>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default SnmpTrafficChart
