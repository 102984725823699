import { CSSProperties, FC, useMemo } from 'react'
import {
    EuiBadge,
    EuiBeacon,
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPageHeaderContent,
    EuiPageHeaderSection,
    EuiText,
    EuiTitle,
} from '@elastic/eui'
import { formatFullDate, usePrettyInterval } from '@utils/dates'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import { AlarmsControls } from '../AlarmsControls/AlarmsControls'
import { AlarmsFiltersValues } from '@services/alarms'
import moment from 'moment'
import { setAlarmsFilters } from '@store/alarms'
import { useTranslation } from 'react-i18next'

type AlarmsPageTitleProps = {
    lastUpdated?: number
}

type AlarmFilterBadgeProps = {
    label: string
    value: string | string[]
    isRange?: boolean
    onClear: () => void
}

const defaultBagdeStyles: CSSProperties = {
    textTransform: 'initial',
}

const CheckFiltersEmpty = (filters: AlarmsFiltersValues) =>
    !Object.values(filters).some(
        (v) => v !== undefined && Array.isArray(v) && v.length > 0
    )

const AlarmFilterBadge: FC<AlarmFilterBadgeProps> = ({
    label,
    value,
    isRange,
    onClear,
}) => {
    return (
        <EuiBadge iconType="filter">
            <EuiFlexGroup gutterSize="xs" alignItems="center">
                <EuiFlexItem grow={false}>
                    <EuiText size="xs">
                        <strong>{label}:</strong>
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiText size="xs">
                        {value instanceof Array
                            ? value.join(isRange ? ' ~ ' : ', ')
                            : value}
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="cross"
                        size="xs"
                        iconSize="s"
                        color="text"
                        onClick={onClear}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiBadge>
    )
}

const AlarmsPageTitle: FC<AlarmsPageTitleProps> = ({ lastUpdated }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['common', 'alarms'])
    const { interval, realtime, filters } = useAppSelector(
        (state) => state.alarms
    )

    const isFiltersEmpty = filters ? CheckFiltersEmpty(filters) : true

    const lastUpdatedText = useMemo(
        () => moment(lastUpdated).format('HH:mm:ss'),
        [lastUpdated]
    )

    const handleOnClear = (
        key:
            | keyof AlarmsFiltersValues
            | [keyof AlarmsFiltersValues, keyof AlarmsFiltersValues]
    ) => {
        if (Array.isArray(key)) {
            dispatch(
                setAlarmsFilters({
                    ...filters,
                    [key[0]]: undefined,
                    [key[1]]: undefined,
                })
            )

            return
        }

        dispatch(setAlarmsFilters({ ...filters, [key]: undefined }))
    }

    return (
        <>
            <EuiPageHeaderContent paddingSize="s">
                <EuiPageHeaderSection>
                    <EuiFlexGroup alignItems="center" gutterSize="m">
                        <EuiFlexItem grow={false}>
                            <EuiIcon
                                type="watchesApp"
                                size="xl"
                                onClick={() =>
                                    dispatch(setAlarmsFilters(undefined))
                                }
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiTitle size="l">
                                <h1>{t('alarms:title')}</h1>
                            </EuiTitle>
                        </EuiFlexItem>
                        {interval && (
                            <EuiFlexItem grow={false}>
                                <EuiBeacon
                                    title={usePrettyInterval(
                                        Boolean(interval === undefined),
                                        interval ?? 0
                                    )}
                                />
                            </EuiFlexItem>
                        )}
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup gutterSize="xs" alignItems="center">
                                {interval && (
                                    <EuiFlexItem grow={false}>
                                        <EuiBadge
                                            color="hollow"
                                            style={defaultBagdeStyles}
                                        >
                                            {`${t('alarms:refresh_interval')} ${usePrettyInterval(
                                                Boolean(interval === undefined),
                                                interval ?? 0,
                                                { shortHand: true }
                                            )}`}
                                        </EuiBadge>
                                    </EuiFlexItem>
                                )}
                                <EuiFlexItem grow={false}>
                                    <EuiText size="xs" color="subdued">
                                        <EuiBadge
                                            color="hollow"
                                            style={defaultBagdeStyles}
                                        >
                                            {t('alarms:last_updated')}
                                            {lastUpdatedText}
                                        </EuiBadge>
                                    </EuiText>
                                </EuiFlexItem>
                                {realtime && (
                                    <EuiFlexItem grow={false}>
                                        <EuiBadge
                                            color="primary"
                                            title={t(
                                                'alarms:only_active_alarms_text'
                                            )}
                                            style={defaultBagdeStyles}
                                        >
                                            {t('alarms:active_label')}
                                        </EuiBadge>
                                    </EuiFlexItem>
                                )}
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPageHeaderSection>
                <EuiPageHeaderSection>
                    <AlarmsControls />
                </EuiPageHeaderSection>
            </EuiPageHeaderContent>
            <EuiFlexGroup gutterSize="xs" alignItems="center" wrap>
                {filters && !isFiltersEmpty && (
                    <EuiFlexItem grow={false}>
                        <EuiText
                            size="xs"
                            style={{ fontWeight: 'bold', marginRight: 5 }}
                        >
                            {t('alarms:filters_label')}
                        </EuiText>
                    </EuiFlexItem>
                )}
                {filters && filters.statuses && filters.statuses.length > 0 && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:status')}
                            value={filters.statuses.map((c) => c.label)}
                            onClear={() => handleOnClear('statuses')}
                        />
                    </EuiFlexItem>
                )}

                {filters && filters.types && filters.types.length > 0 && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:type')}
                            value={filters.types.map((c) => c.label)}
                            onClear={() => handleOnClear('types')}
                        />
                    </EuiFlexItem>
                )}

                {filters &&
                    filters.categories &&
                    filters.categories.length > 0 && (
                        <EuiFlexItem grow={false}>
                            <AlarmFilterBadge
                                label={t('alarms:categories')}
                                value={filters.categories.map((c) => c.label)}
                                onClear={() => handleOnClear('categories')}
                            />
                        </EuiFlexItem>
                    )}

                {filters && filters.sensor && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:sensor')}
                            value={filters.sensor}
                            onClear={() => handleOnClear('sensor')}
                        />
                    </EuiFlexItem>
                )}

                {filters && filters.device && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:device')}
                            value={filters.device}
                            onClear={() => handleOnClear('device')}
                        />
                    </EuiFlexItem>
                )}

                {filters && filters.agent && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:agent')}
                            value={filters.agent}
                            onClear={() => handleOnClear('agent')}
                        />
                    </EuiFlexItem>
                )}

                {filters && (filters.startDate || filters.endDate) && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:startDate')}
                            isRange
                            value={[
                                formatFullDate(filters.startDate),
                                formatFullDate(filters.endDate),
                            ]}
                            onClear={() =>
                                handleOnClear(['startDate', 'endDate'])
                            }
                        />
                    </EuiFlexItem>
                )}

                {filters && filters.isOk !== undefined && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:solved')}
                            value={
                                filters.isOk ? t('common:yes') : t('common:no')
                            }
                            onClear={() => handleOnClear('isOk')}
                        />
                    </EuiFlexItem>
                )}

                {filters && filters.isAcknowledged !== undefined && (
                    <EuiFlexItem grow={false}>
                        <AlarmFilterBadge
                            label={t('alarms:acknowledged')}
                            value={
                                filters.isAcknowledged
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            onClear={() => handleOnClear('isAcknowledged')}
                        />
                    </EuiFlexItem>
                )}

                {filters && !isFiltersEmpty && (
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            iconType="cross"
                            iconSize="s"
                            size="xs"
                            color="danger"
                            onClick={() =>
                                dispatch(setAlarmsFilters(undefined))
                            }
                        >
                            {t('common:clear_filters')}
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </>
    )
}

export default AlarmsPageTitle
