import RemoteSelectionBox, {
    RemoteSelectionBoxProps,
} from '../SelectionBox/RemoteSelectionBox'

import { EuiFormRow } from '@elastic/eui'
import { FC } from 'react'

type RemoteSelectionBoxFieldProps = RemoteSelectionBoxProps & {
    form: any // eslint-disable-line @typescript-eslint/no-explicit-any
    name: string
    label?: string
    isDisabled?: boolean
}

const RemoteSelectionBoxField: FC<RemoteSelectionBoxFieldProps> = (props) => {
    const { form, name, label, ...selectionBoxProps } = props

    return (
        <EuiFormRow
            fullWidth={props.fullWidth}
            label={props.label}
            isInvalid={
                props.form.touched[props.name] &&
                !!props.form.errors[props.name]
            }
            error={props.form.errors[props.name]}
            isDisabled={props.isDisabled}
        >
            <RemoteSelectionBox {...selectionBoxProps} />
        </EuiFormRow>
    )
}

export default RemoteSelectionBoxField
