import {
    EuiFlexGrid,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiStat,
    EuiTitle,
} from '@elastic/eui'
import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import { WmiServiceStates, WmiServiceStatuses } from '@services/sensors'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'
import { useEffect, useMemo, useState } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'

type WmiServiceChartProps = ChartProps

type ServiceInfo = {
    wmiServiceName: string
    wmiServicePathName: string
    wmiServiceState: string
    wmiServiceStatus: string
}

enum LineChartValues {
    Unknown = 0,
    Stopped = 1,
    Running = 2,
}

const WmiServiceChart = (props: WmiServiceChartProps) => {
    const { t } = useTranslation(['network'])
    const [lines, setLines] = useState<ChartLine[]>([])
    const [data, setData] = useState<any[]>([])
    const [snapshotService, setSnapshotService] = useState<
        ServiceInfo | undefined
    >(undefined)

    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    const getStateColor = (state: string) =>
        state === WmiServiceStates.Running ? 'success' : 'danger'

    const getStatusColor = (status: string) =>
        status === WmiServiceStatuses.Ok ? 'success' : 'danger'

    const getLineChartValue = (serviceData: ServiceInfo) => {
        if (
            serviceData.wmiServiceState === WmiServiceStates.Running &&
            serviceData.wmiServiceStatus === WmiServiceStatuses.Ok
        )
            return LineChartValues.Running

        if (
            serviceData.wmiServiceState === WmiServiceStates.Stopped &&
            serviceData.wmiServiceStatus === WmiServiceStatuses.Ok
        )
            return LineChartValues.Stopped

        if (serviceData.wmiServiceStatus === WmiServiceStatuses.Unknown)
            return LineChartValues.Unknown
    }

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const snapshot = _.findLast(props.data as any[], (s) => s)

            if (!snapshot) return

            const newLine: ChartLine = {
                key: 'lineChartValue',
                label: `${snapshot['wmiServiceName']}`,
            }

            setSnapshotService(snapshot)
            setLines([newLine])
            setData(
                props.data.map((x: ServiceInfo) => ({
                    ...x,
                    lineChartValue: getLineChartValue(x),
                }))
            )
        }
    }, [props.data])

    return (
        <>
            <NetworkLineChart
                data={data}
                query={props.query}
                setQuery={props.setQuery}
                lines={lines}
                xAxis="timestamp"
                xAxisFormatter={(v) => formatTimestamp(v, props.query)}
                xAxisDomain={xAxisDomain}
                yAxisTickCount={3}
                yAxisDomain={[0, 2]}
                yAxisFormatter={(v) => {
                    switch (v) {
                        case LineChartValues.Running:
                            return t('network:service_running')
                        case LineChartValues.Stopped:
                            return t('network:service_stopped')
                        case LineChartValues.Unknown:
                            return t('network:service_unknown')
                        default:
                            return ''
                    }
                }}
            />
            <EuiSpacer size="xl" />
            <EuiTitle size="s">
                <h4>{t('network:service_information')}</h4>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGrid>
                <EuiFlexItem grow={false}>
                    <EuiPanel
                        hasShadow={false}
                        color="subdued"
                        hasBorder={false}
                    >
                        <EuiTitle>
                            <h3>
                                {snapshotService?.wmiServiceName ??
                                    t('network:service_unknown')}
                            </h3>
                        </EuiTitle>
                        <EuiSpacer size="s" />
                        <EuiStat
                            description={t(
                                'network:data_key_wmi_service_path_name'
                            )}
                            title={
                                snapshotService?.wmiServicePathName ??
                                t('network:service_unknown')
                            }
                            titleSize="xxs"
                        />
                        <EuiSpacer size="s" />
                        <EuiStat
                            description={t(
                                'network:data_key_wmi_service_state'
                            )}
                            title={
                                snapshotService?.wmiServiceState ??
                                t('network:service_unknown')
                            }
                            titleSize="xxs"
                            titleColor={getStateColor(
                                snapshotService?.wmiServiceState ?? 'Unknown'
                            )}
                        />
                        <EuiSpacer size="s" />
                        <EuiStat
                            description={t(
                                'network:data_key_wmi_service_status'
                            )}
                            title={
                                snapshotService?.wmiServiceStatus ??
                                t('network:service_unknown')
                            }
                            titleSize="xxs"
                            titleColor={getStatusColor(
                                snapshotService?.wmiServiceStatus ?? 'Unknown'
                            )}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGrid>
        </>
    )
}

export default WmiServiceChart
