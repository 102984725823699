import {
    EuiButton,
    EuiButtonEmpty,
    EuiEmptyPrompt,
    EuiImage,
    EuiThemeColorMode,
    useEuiTheme,
} from '@elastic/eui'

import { Page } from '@components/layout'
import pageNotFoundDark from '@assets/empty-prompt/pageNotFound--dark.png'
import pageNotFoundDark2x from '@assets/empty-prompt/pageNotFound--dark@2x.png'
import pageNotFoundLight from '@assets/empty-prompt/pageNotFound--light.png'
import pageNotFoundLight2x from '@assets/empty-prompt/pageNotFound--light@2x.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const theme: EuiThemeColorMode =
    (localStorage.getItem('theme') as EuiThemeColorMode) ?? 'light'

const PageNotFound = () => {
    const { t } = useTranslation(['common'])
    const isDarkTheme = theme === 'dark'
    const navigate = useNavigate()

    const pageNotFound = isDarkTheme ? pageNotFoundDark : pageNotFoundLight
    const pageNotFound2x = isDarkTheme
        ? pageNotFoundDark2x
        : pageNotFoundLight2x

    return (
        <Page title="">
            <div
                style={{
                    height: '85vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <EuiEmptyPrompt
                    color="subdued"
                    icon={
                        <EuiImage
                            size="fullWidth"
                            srcSet={`${pageNotFound} 1x, ${pageNotFound2x} 2x`}
                            src={pageNotFound}
                            alt="not found 404"
                        />
                    }
                    title={<h2>{t('common:page_not_found_title')}</h2>}
                    layout="vertical"
                    body={<p>{t('common:page_not_found_text')}</p>}
                    actions={[
                        <EuiButton
                            color="primary"
                            key="go-home"
                            fill
                            onClick={() => navigate('/')}
                        >
                            {t('common:go_home')}
                        </EuiButton>,
                        <EuiButtonEmpty
                            iconType="arrowLeft"
                            key="go-back"
                            flush="both"
                            onClick={() => navigate(-1)}
                        >
                            {t('common:go_back')}
                        </EuiButtonEmpty>,
                    ]}
                />
            </div>
        </Page>
    )
}
export default PageNotFound
