import { AlarmsCounterStats, AlarmsSensorsStats } from '@services/alarms'
import { CSSProperties, FC, useCallback, useMemo, useState } from 'react'
import {
    Cell,
    DefaultLegendContentProps,
    Legend,
    Pie,
    PieChart,
    Tooltip,
} from 'recharts'
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiTitle,
    formatNumber,
    useEuiTheme,
} from '@elastic/eui'

import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { useTranslation } from 'react-i18next'

export type AlarmStatData = {
    name: string
    color: string
    textColor: string
    value: number
}

type AlarmStatPayload = Payload['payload'] & {
    color: string
    textColor: string
}

type AlarmStatChartProps = {
    title: string
    data: AlarmsSensorsStats | AlarmsCounterStats
}

type MapableColorKeys = keyof AlarmsSensorsStats | keyof AlarmsCounterStats

type ColorMapper = {
    mapper: (key: MapableColorKeys, isText: boolean) => string
}

const getDataPoints = (
    data: AlarmsSensorsStats | AlarmsCounterStats,
    mapColors: ColorMapper['mapper']
): AlarmStatData[] => {
    return Object.entries(data).map(([key, value]) => ({
        name: key,
        color: mapColors(key as MapableColorKeys, false),
        textColor: mapColors(key as MapableColorKeys, true),
        value,
    }))
}

const AlarmStatChart: FC<AlarmStatChartProps> = ({ title, data }) => {
    const { euiTheme } = useEuiTheme()
    const { t } = useTranslation(['common', 'alarms'])

    const [active, setActive] = useState<number | undefined>(undefined)

    const mapColors: ColorMapper['mapper'] = useCallback(
        (key, isText) => {
            switch (key) {
                case 'errors':
                    return isText
                        ? euiTheme.colors.dangerText
                        : euiTheme.colors.danger
                case 'acknowledgedErrors':
                    return isText
                        ? euiTheme.colors.accentText
                        : euiTheme.colors.accent
                case 'warnings':
                    return isText
                        ? euiTheme.colors.warningText
                        : euiTheme.colors.warning
                case 'online':
                    return isText
                        ? euiTheme.colors.successText
                        : euiTheme.colors.success
                case 'paused':
                    return isText
                        ? euiTheme.colors.primaryText
                        : euiTheme.colors.primary
                case 'offline':
                    return euiTheme.colors.subduedText
                default:
                    return euiTheme.colors.mediumShade
            }
        },
        [euiTheme.colors]
    )

    const points = useMemo(
        () => getDataPoints(data, mapColors),
        [data, mapColors]
    )

    const renderLegend = ({ payload }: DefaultLegendContentProps) => (
        <EuiFlexGroup direction="column" gutterSize="s">
            {payload?.map((entry, index) => {
                const alarmPayload = entry.payload as AlarmStatPayload
                const opacity =
                    active === undefined ? 1 : active === index ? 1 : 0.5

                return (
                    <EuiFlexItem
                        key={`legend-${index}`}
                        onMouseEnter={() => setActive(index)}
                        onMouseLeave={() => setActive(undefined)}
                    >
                        <EuiFlexGroup gutterSize="s" alignItems="center">
                            <EuiFlexItem grow={false}>
                                <EuiText color="subdued" size="xs">
                                    {formatNumber(alarmPayload.value as number)}
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText
                                    size="s"
                                    color={alarmPayload.textColor}
                                    style={{
                                        opacity,
                                    }}
                                >
                                    {t(`alarms:${entry.value}`)}
                                </EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                )
            })}
        </EuiFlexGroup>
    )

    return (
        <EuiFlexGroup
            gutterSize="xs"
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <EuiFlexItem>
                <EuiTitle size="xs">
                    <h3>{title}</h3>
                </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
                <PieChart width={400} height={200}>
                    <Pie
                        data={points}
                        dataKey="value"
                        innerRadius={60}
                        outerRadius={80}
                        onMouseEnter={(_, i) => setActive(i)}
                        onMouseLeave={() => setActive(undefined)}
                        activeIndex={active}
                        fill="#8884d8"
                    >
                        {points.map((entry, index) => (
                            <Cell key={index} fill={entry.color} />
                        ))}
                    </Pie>
                    <Tooltip
                        formatter={(value, name) => [
                            formatNumber(value as number),
                            t(`alarms:${name}`),
                        ]}
                    />
                    <Legend
                        verticalAlign="middle"
                        align="right"
                        layout="vertical"
                        content={renderLegend}
                    />
                </PieChart>
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}

export default AlarmStatChart
