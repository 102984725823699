import { EuiFormRow, EuiSwitch } from '@elastic/eui'

type SwitchFieldProps = {
    form: any // eslint-disable-line @typescript-eslint/no-explicit-any
    name: string
    label?: string
    showSwitchLabel?: boolean
    value?: boolean
    compressed?: boolean
    onChange: (value: boolean) => void
    isDisabled?: boolean
}

const SwitchField = (props: SwitchFieldProps) => {
    return (
        <EuiFormRow label={props.label} isDisabled={props.isDisabled}>
            <EuiSwitch
                showLabel={!!props.showSwitchLabel}
                label={props.label}
                checked={props.value ?? false}
                onChange={(e) => props.onChange(e.target.checked)}
                compressed={props.compressed}
            />
        </EuiFormRow>
    )
}
export default SwitchField
