import { CollectionResult, Query } from '../interfaces'
import { CreateUserRequest, UpdateUserRequest, User } from '@services/users'
import {
    buildQueryParams,
    invalidateCacheTag,
    provideCacheTag,
} from '../helpers'

import { appApi } from '../app.api'

const agentApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchUsers: builder.query<CollectionResult<User>, Query>({
            providesTags: (result) => provideCacheTag(result, 'User'),
            query: (q) => ({
                url: 'users',
                method: 'GET',
                params: buildQueryParams(q, ['tenant', 'userRoles.role']),
            }),
        }),
        findUser: builder.query<User, { id: string }>({
            providesTags: (result) => provideCacheTag(result, 'User'),
            query: (q) => ({
                url: `users/${q.id}`,
                method: 'GET',
            }),
        }),
        createUser: builder.mutation<User, CreateUserRequest>({
            query: (req) => ({
                url: 'users',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: (result) => invalidateCacheTag('User', result?.id),
        }),
        updateUser: builder.mutation<User, UpdateUserRequest>({
            query: (req) => ({
                url: `users/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) => invalidateCacheTag('User', result?.id),
        }),
        deleteUser: builder.mutation<void, string>({
            query: (id) => ({
                url: `users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('User', req),
        }),
    }),
})

export const {
    useFetchUsersQuery,
    useFindUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = agentApi
