import { EuiConfirmModal, EuiSpacer, EuiText } from '@elastic/eui'
import { getDefaultQuery, useFetchDevicesQuery } from '@services/api'
import { useCallback, useState } from 'react'

import { SelectionBox } from '@components/form'
import { useTranslation } from 'react-i18next'

type DeviceConfiguratorProps = {
    id?: string | undefined
    onConfirm: (deviceTemplateId: number | undefined) => void
    onCancel: () => void
}

const DeviceConfigurator = ({
    id,
    onConfirm,
    onCancel,
}: DeviceConfiguratorProps) => {
    const { t } = useTranslation(['common', 'deviceConfigurator'])

    // Queries
    const { data: deviceTemplates, isLoading: templatesLoading } =
        useFetchDevicesQuery({
            ...getDefaultQuery(),
            filters: ['isTemplate==true'],
        })

    // States
    const [selectedTemplateId, setSelectedTemplateId] = useState<
        number | undefined
    >(undefined)

    const getTitle = useCallback(() => {
        if (id === 'addSensors')
            return t('deviceConfigurator:title_add_sensors')
        else return t('deviceConfigurator:title_new')
    }, [id])

    const getPlaceholder = useCallback(() => {
        if (id === 'addSensors')
            return t('deviceConfigurator:selection_placeholder_add_sensors')
        else return t('deviceConfigurator:selection_placeholder')
    }, [id])

    const getConfirmButtonState = useCallback(() => {
        if (id === 'addSensors' && !selectedTemplateId) return true
        else return false
    }, [id, selectedTemplateId])

    return (
        <EuiConfirmModal
            title={getTitle()}
            onCancel={onCancel}
            onConfirm={() => onConfirm(selectedTemplateId)}
            cancelButtonText={t('common:cancel')}
            confirmButtonText={t('common:confirm')}
            buttonColor="danger"
            defaultFocusedButton="confirm"
            isLoading={templatesLoading}
            confirmButtonDisabled={getConfirmButtonState()}
        >
            <>
                <EuiText>
                    <p>{t('deviceConfigurator:label')}</p>
                </EuiText>
                <EuiSpacer />
                <SelectionBox
                    fullWidth
                    placeholder={getPlaceholder()}
                    value={selectedTemplateId}
                    onChange={(value) => setSelectedTemplateId(value[0]?.value)}
                    options={
                        deviceTemplates?.items?.map((item) => ({
                            label: item.templateName ?? item.name,
                            value: item.id,
                        })) || []
                    }
                />
            </>
        </EuiConfirmModal>
    )
}

export default DeviceConfigurator
