import { DATE_FILTER_TYPES, DateFilterType } from '@services/data'
import {
    EuiButtonEmpty,
    EuiContextMenuItem,
    EuiContextMenuPanel,
    EuiFocusTrap,
    EuiPopover,
    useGeneratedHtmlId,
} from '@elastic/eui'

import NetworkCustomDateFilter from './NetworkCustomDateFilter'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type NetworkDateFilterProps = {
    selectedFilter: DateFilterType
    onChange: (type: DateFilterType) => void
}
const NetworkDateFilter = (props: NetworkDateFilterProps) => {
    const datePopoverId = useGeneratedHtmlId({ prefix: 'date-popover' })
    const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false)
    const [showCustomDateFilter, setShowCustomDateFilter] =
        useState<boolean>(false)
    const { t } = useTranslation(['network'])

    const handleItemOnClick = (type: DateFilterType) => {
        if (type === 'custom') setShowCustomDateFilter(true)
        else props.onChange(type as DateFilterType)

        setIsDatePopoverOpen(false)
    }

    const items = DATE_FILTER_TYPES.map((type) => (
        <EuiContextMenuItem
            key={type}
            icon="calendar"
            onClick={() => handleItemOnClick(type as DateFilterType)}
        >
            {t(`network:date_filter_${type}`)}
        </EuiContextMenuItem>
    ))

    return (
        <>
            {showCustomDateFilter && (
                <EuiFocusTrap
                    onClickOutside={() => setShowCustomDateFilter(false)}
                >
                    <NetworkCustomDateFilter
                        onClose={() => setShowCustomDateFilter(false)}
                    />
                </EuiFocusTrap>
            )}
            <EuiPopover
                id={datePopoverId}
                button={
                    <EuiButtonEmpty
                        size="s"
                        onClick={() => setIsDatePopoverOpen(true)}
                        iconType="calendar"
                    >
                        {t(`network:date_filter_${props.selectedFilter}`)}
                    </EuiButtonEmpty>
                }
                isOpen={isDatePopoverOpen}
                repositionOnScroll
                closePopover={() => setIsDatePopoverOpen(false)}
            >
                <EuiContextMenuPanel items={items} />
            </EuiPopover>
        </>
    )
}

export default NetworkDateFilter
