import {
    ALL_ALARM_OPTION_TYPES,
    Option,
    getAvailableNotificationTypes,
} from '@services/options'
import { DetailsGroupRequest, Group, GroupRole } from '@services/groups'
import { EuiForm, EuiSpacer } from '@elastic/eui'
import { SelectionBoxField, TextField } from '@components/form'
import { Tab, Tabs } from '@components/tabs'
import { array, number, object, string } from 'yup'
import { useCallback, useEffect, useState } from 'react'
import {
    useCreateGroupMutation,
    useFetchCategoriesQuery,
    useFetchGroupsQuery,
    useFetchRolesQuery,
    useFetchTenantsQuery,
    useFindGroupQuery,
    useUpdateGroupMutation,
} from '@services/api'
import { useNavigate, useParams } from 'react-router'

import { DetailsPage } from '@components/layout'
import { GroupSelectionBoxTree } from '@components/groups'
import { OptionsEditor } from '@components/options'
import { Permissions } from '@services/auth'
import { Role } from '@services/roles'
import { getNumberOrDefault } from '@utils/numbers'
import { mergeArraysBy } from '@utils/arrays'
import { refreshTree } from '@store/network'
import { useAppDispatch } from '@hooks/store'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const groupSchema = object({
    name: string().required(),
    parentGroupId: number().nullable().optional(),
    categoryId: number().nullable().optional(),
    tenantId: string().required(),
    roles: array().optional(),
    options: array().optional(),
})

const getRolesOptions = (
    ids: GroupRole[] | undefined,
    roles: Role[] | undefined
) => {
    if (!ids || !roles) return []

    return ids.map((id) => {
        const role = roles.find((r) => r.id === id.authRoleId)
        return {
            label: role?.name ?? '',
            value: id.authRoleId,
        }
    })
}

const GroupDetailsPage = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['common', 'groups'])
    const { id, parentGroup } = useParams()
    const idNumber = getNumberOrDefault(id, 0)
    const [skip, setSkip] = useState(true)
    const [groupsState, setGroupsState] = useState<Group[]>([])

    const canViewCategories = useHasPermission(Permissions.category.canView)

    const { data, isLoading } = useFindGroupQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip }
    )

    const { data: tenants } = useFetchTenantsQuery({})
    const { data: categories } = useFetchCategoriesQuery(
        {},
        { skip: !canViewCategories }
    )
    const { data: roles } = useFetchRolesQuery({})

    const { data: parentGroups } = useFetchGroupsQuery({
        filters: [`Id!=${id !== 'new' ? id : 0}`],
    })
    const [createGroup, { isLoading: isCreating }] = useCreateGroupMutation()
    const [updateGroup, { isLoading: isUpdating }] = useUpdateGroupMutation()

    const notificationTypes = getAvailableNotificationTypes()

    const onSubmit = async (record: DetailsGroupRequest) => {
        const roles = record.roles?.map((r: any) => r.authRoleId)
        const options = record.options || []

        try {
            if (data && id) {
                await updateGroup({ ...record, id: idNumber, roles, options })
            } else {
                await createGroup({ ...record, roles, options })
            }
            navigate('/network')
            dispatch(refreshTree({}))
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<DetailsGroupRequest>({
        initialValues: {
            name: '',
            tenantId: '',
            options: [],
            roles: [],
            parentGroupId: parentGroup ? +parentGroup : undefined,
        },
        validationSchema: groupSchema,
        onSubmit,
    })

    const handleOptionsChange = (opts: Option[]) => {
        const newOptions = mergeArraysBy<Option>(
            [...((form.values.options as Option[]) || [])],
            opts,
            'key'
        )
        form.setFieldValue('options', newOptions)
    }

    const reduceFunc = (data: Group[], searchVal: string): Group[] => {
        return data.reduce((result: Group[], x) => {
            if (x.name.includes(searchVal))
                result.push({
                    ...x,
                    subGroups:
                        x.subGroups && x.subGroups.length > 0
                            ? reduceFunc(x.subGroups, searchVal)
                            : x.subGroups,
                })
            else if (x.subGroups && x.subGroups.length > 0)
                result.push(...reduceFunc(x.subGroups, searchVal))

            return result
        }, [])
    }

    const getInheritanceSources = useCallback(() => {
        if (id === 'new') {
            const result = []
            if (form.values.parentGroupId != undefined)
                result.push(`parentGroupId=${form.values.parentGroupId}`)

            if (
                form.values.tenantId !== undefined &&
                form.values.tenantId !== ''
            )
                result.push(`parentTenantId=${form.values.tenantId}`)

            return result
        } else {
            return undefined
        }
    }, [id, form.values.parentGroupId, form.values.tenantId])

    useEffect(() => {
        if (id && id !== 'new' && !isNaN(+id)) {
            setSkip(false)
        }
    })

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    useEffect(() => {
        setGroupsState(parentGroups?.items ?? [])
    }, [parentGroups])

    return (
        <DetailsPage
            onClose={() => navigate('/network')}
            size="s"
            title={data ? t('groups:edit_title') : t('groups:create_title')}
            loading={isLoading || isCreating || isUpdating}
            onSave={form.handleSubmit}
            onCancel={() => navigate('/network')}
        >
            <EuiForm component="form" onSubmit={form.handleSubmit}>
                <Tabs>
                    <Tab title={t('common:details')}>
                        <>
                            <EuiSpacer />
                            <TextField
                                form={form}
                                name="name"
                                fullWidth
                                label={t('groups:name')}
                                placeholder={t('groups:name')}
                            />
                            <SelectionBoxField
                                form={form}
                                name="tenantId"
                                label={t('groups:tenant')}
                                fullWidth
                                isClearable={false}
                                value={form.values.tenantId}
                                onChange={(value) => {
                                    form.setFieldValue(
                                        'parentGroupId',
                                        undefined
                                    )
                                    form.setFieldValue(
                                        'tenantId',
                                        value[0]?.value
                                    )
                                }}
                                options={
                                    tenants?.items?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })) ?? []
                                }
                            />
                            <GroupSelectionBoxTree
                                form={form}
                                name="parentGroupId"
                                label={t('devices:group')}
                                fullWidth
                                value={form.values.parentGroupId}
                                onChange={(value) => {
                                    form.setFieldValue('parentGroupId', value)
                                }}
                                options={groupsState.filter((x) =>
                                    form.values.tenantId !== undefined &&
                                    form.values.tenantId !== ''
                                        ? x.tenantId === form.values.tenantId
                                        : x
                                )}
                                onSearch={(searchVal) => {
                                    if (searchVal !== '') {
                                        if (parentGroups?.items) {
                                            const filteredGroups = reduceFunc(
                                                parentGroups.items,
                                                searchVal
                                            )
                                            setGroupsState(filteredGroups)
                                        }
                                    } else {
                                        setGroupsState(
                                            parentGroups?.items ?? []
                                        )
                                    }
                                }}
                                isClearable={false}
                            />
                            <SelectionBoxField
                                form={form}
                                name="roles"
                                label={t('users:roles')}
                                fullWidth
                                multiple
                                isClearable
                                value={getRolesOptions(
                                    form.values.roles,
                                    roles?.items
                                )}
                                onChange={(value) => {
                                    form.setFieldValue(
                                        'roles',
                                        value.map((v: any) => ({
                                            authRoleId: v.value,
                                            groupId: idNumber,
                                        }))
                                    )
                                }}
                                options={
                                    roles?.items?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })) ?? []
                                }
                            />
                            {canViewCategories && (
                                <SelectionBoxField
                                    form={form}
                                    name="categoryId"
                                    label={t('groups:category')}
                                    fullWidth
                                    isClearable={false}
                                    value={form.values.categoryId}
                                    onChange={(value) =>
                                        form.setFieldValue(
                                            'categoryId',
                                            value[0]?.value
                                        )
                                    }
                                    options={
                                        categories?.items?.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        })) ?? []
                                    }
                                />
                            )}
                        </>
                    </Tab>
                    <Tab title={t('common:settings')}>
                        <OptionsEditor
                            source="group"
                            sourceId={id === 'new' ? 0 : id}
                            inheritanceSources={getInheritanceSources()}
                            onChange={handleOptionsChange}
                        />
                    </Tab>
                    <Tab title={t('common:alarms')}>
                        <OptionsEditor
                            source="group"
                            sourceId={id === 'new' ? 0 : id}
                            inheritanceSources={getInheritanceSources()}
                            onChange={handleOptionsChange}
                            optionsGroups={ALL_ALARM_OPTION_TYPES}
                        />
                    </Tab>
                    <Tab title={t('common:notifications')}>
                        <OptionsEditor
                            source="group"
                            sourceId={id}
                            optionsGroups={notificationTypes}
                            onChange={handleOptionsChange}
                        />
                    </Tab>
                </Tabs>
            </EuiForm>
        </DetailsPage>
    )
}

export default GroupDetailsPage
