import {
    EuiCallOut,
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiImage,
    EuiPageTemplate,
    EuiSpacer,
} from '@elastic/eui'

type AuthPageProps = {
    title?: string
    img?: string
    children: JSX.Element
    actions?: React.ReactNode
    bgImg?: string
    error?: boolean
    errorMessage?: string
}

const AuthPage = (props: AuthPageProps) => {
    return (
        <EuiPageTemplate
            style={{
                height: '100vh',
                backgroundImage: 'url(' + props.bgImg + ')',
            }}
            className="auth-page"
        >
            <EuiPageTemplate.EmptyPrompt>
                {props.img && (
                    <EuiFlexGroup justifyContent="spaceAround">
                        <EuiFlexItem grow={false}>
                            <EuiImage src={props.img} alt="Logo" size="s" />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                )}
                {props.error && (
                    <>
                        <EuiSpacer size="l" />
                        <EuiCallOut
                            title={props.errorMessage}
                            color="danger"
                            iconType="alert"
                        />
                    </>
                )}
                <EuiEmptyPrompt
                    title={<span>{props.title}</span>}
                    body={props.children}
                    actions={props.actions}
                />
            </EuiPageTemplate.EmptyPrompt>
        </EuiPageTemplate>
    )
}

export default AuthPage
