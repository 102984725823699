import {
    ALL_ALARM_OPTION_TYPES,
    ALL_NOTIFICATION_OPTION_TYPES,
    Option,
} from '@services/options'
import {
    DetailsDeviceRequest,
    DetailsDeviceTemplateRequest,
} from '@services/devices'
import {
    DeviceTemplateSensorDetails,
    DeviceTemplateSensors,
} from '@components/deviceTemplates'
import { EuiForm, EuiSpacer } from '@elastic/eui'
import { SelectionBoxField, SwitchField, TextField } from '@components/form'
import { Tab, Tabs } from '@components/tabs'
import { array, boolean, number, object, string } from 'yup'
import {
    useCreateDeviceTemplateMutation,
    useFetchAgentsQuery,
    useFetchCategoriesQuery,
    useFetchGroupsQuery,
    useFindDeviceTemplateQuery,
    useUpdateDeviceTemplateMutation,
} from '@services/api'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { DetailsPage } from '@components/layout'
import { Group } from '@services/groups'
import { GroupSelectionBoxTree } from '@components/groups'
import { OptionsEditor } from '@components/options'
import { Sensor } from '@services/sensors'
import { getNumberOrDefault } from '@utils/numbers'
import { mergeArraysBy } from '@utils/arrays'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

const deviceSchema = object({
    name: string().nullable().optional(),
    templateName: string().required(),
    hostName: string().nullable().optional(),
    isEnabled: boolean().required(),
    agentId: number().nullable().optional(),
    groupId: number().nullable().optional(),
    categoryId: number().nullable().optional(),
    options: array().optional(),
})

const DeviceTemplateDetailsPage = () => {
    const navigate = useNavigate()

    const { id } = useParams()
    const [skip, setSkip] = useState(true)
    const [searchParams] = useSearchParams([])
    const { data, isLoading } = useFindDeviceTemplateQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip }
    )
    const { data: agents } = useFetchAgentsQuery({})
    const { data: groups } = useFetchGroupsQuery({})
    const { data: categories } = useFetchCategoriesQuery({})

    const [createDeviceTemplate, { isLoading: isCreating }] =
        useCreateDeviceTemplateMutation()
    const [updateDeviceTemplate, { isLoading: isUpdating }] =
        useUpdateDeviceTemplateMutation()
    const { t } = useTranslation(['common', 'devices'])

    const [groupsState, setGroupsState] = useState<Group[]>([])
    const [sensors, setSensors] = useState<Sensor[]>([])
    const [editSensor, setEditSensor] = useState<Sensor | undefined>(undefined)

    const onSubmit = async (record: DetailsDeviceRequest) => {
        try {
            const mappedSensors = sensors.map((sensor) => ({
                ...sensor,
                device: undefined,
            }))

            if (data && id) {
                await updateDeviceTemplate({
                    ...record,
                    id: +id,
                    host: (record as any).hostName,
                    sensors: mappedSensors,
                })
            } else {
                await createDeviceTemplate({
                    ...(record as DetailsDeviceTemplateRequest),
                    host: (record as any).hostName,
                    sensors: mappedSensors,
                })
            }

            navigate('/deviceTemplates')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<any>({
        initialValues: {
            name: undefined,
            templateName: '',
            hostName: undefined,
            isEnabled: true,
            agentId: undefined,
            groupId: undefined,
            options: [],
        },
        validationSchema: deviceSchema,
        onSubmit,
    })

    /**
     * Handy function to set the host as name for creating a new device.
     * It will only be used when creating a new device and after entering
     * the name but before changing/adding the host.
     * @param v the entered name
     */
    const handleOnNameChange = (v: string) => {
        if (!form.touched.hostName && !!skip) {
            form.setFieldValue('hostName', v)
        }
    }

    const handleOptionsChange = (opts: Option[]) => {
        const newOptions = mergeArraysBy<Option>(
            [...((form.values.options as Option[]) || [])],
            opts,
            'key'
        )
        form.setFieldValue('options', newOptions)
    }

    const reduceFunc = (data: Group[], searchVal: string): Group[] => {
        return data.reduce((result: Group[], x) => {
            if (x.name.toLowerCase().includes(searchVal.toLowerCase()))
                result.push({
                    ...x,
                    subGroups:
                        x.subGroups && x.subGroups.length > 0
                            ? reduceFunc(x.subGroups, searchVal)
                            : x.subGroups,
                })
            else if (x.subGroups && x.subGroups.length > 0)
                result.push(...reduceFunc(x.subGroups, searchVal))

            return result
        }, [])
    }

    useEffect(() => {
        if (id && !isNaN(+id)) {
            setSkip(false)
        }
    }, [])

    useEffect(() => {
        if (data) {
            form.setValues({ ...data, hostName: data.host, options: [] })
            if (data.sensors) setSensors(data.sensors)
        }
    }, [data])

    useEffect(() => {
        if (searchParams) {
            const agentId = searchParams.get('agentId')
            if (agentId) form.setFieldValue('agentId', parseInt(agentId))

            const groupId = searchParams.get('groupId')
            if (groupId) form.setFieldValue('groupId', parseInt(groupId))
        }
    }, [searchParams])

    useEffect(() => {
        setGroupsState(groups?.items ?? [])
    }, [groups])

    return (
        <DetailsPage
            onClose={() => navigate('..')}
            size="m"
            title={
                data
                    ? t('deviceTemplates:edit_title')
                    : t('deviceTemplates:create_title')
            }
            loading={isLoading || isCreating || isUpdating}
            submitLoading={isLoading || isCreating || isUpdating}
            onSave={form.handleSubmit}
            onCancel={() => navigate('..')}
        >
            <>
                {editSensor && (
                    <DeviceTemplateSensorDetails
                        editSensor={editSensor}
                        setEditSensor={setEditSensor}
                        setSensors={setSensors}
                    />
                )}

                <EuiForm component="form" onSubmit={form.handleSubmit}>
                    <Tabs>
                        <Tab title={t('common:details')} id="details">
                            <EuiSpacer />
                            <TextField
                                form={form}
                                name="templateName"
                                fullWidth
                                label={t('devices:template_name')}
                                placeholder={t('devices:template_name')}
                            />
                            <TextField
                                form={form}
                                name="name"
                                fullWidth
                                label={t('devices:name')}
                                placeholder={t('devices:name')}
                                onChange={handleOnNameChange}
                            />
                            <TextField
                                form={form}
                                name="hostName"
                                fullWidth
                                label={t('devices:host')}
                                placeholder={t('devices:host')}
                            />
                            <SelectionBoxField
                                form={form}
                                name="agentId"
                                label={t('devices:agent')}
                                fullWidth
                                isClearable={false}
                                value={form.values.agentId}
                                onChange={(value) =>
                                    form.setFieldValue(
                                        'agentId',
                                        value[0]?.value
                                    )
                                }
                                options={
                                    agents?.items?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })) ?? []
                                }
                            />
                            <GroupSelectionBoxTree
                                form={form}
                                name="groupId"
                                label={t('devices:group')}
                                fullWidth
                                value={form.values.groupId}
                                onChange={(value) => {
                                    form.setFieldValue('groupId', value)
                                }}
                                options={groupsState}
                                onSearch={(searchVal) => {
                                    if (searchVal !== '') {
                                        if (groups?.items) {
                                            const filteredGroups = reduceFunc(
                                                groups.items,
                                                searchVal
                                            )
                                            setGroupsState(filteredGroups)
                                        }
                                    } else {
                                        setGroupsState(groups?.items ?? [])
                                    }
                                }}
                                isClearable={false}
                            />
                            <SelectionBoxField
                                form={form}
                                name="categoryId"
                                label={t('devices:category')}
                                fullWidth
                                isClearable={false}
                                value={form.values.categoryId}
                                onChange={(value) =>
                                    form.setFieldValue(
                                        'categoryId',
                                        value[0]?.value
                                    )
                                }
                                options={
                                    categories?.items?.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })) ?? []
                                }
                            />
                            <SwitchField
                                form={form}
                                name="isEnabled"
                                label={t('devices:is_enabled')}
                                value={form.values.isEnabled}
                                onChange={(value) =>
                                    form.setFieldValue('isEnabled', value)
                                }
                            />
                        </Tab>
                        <Tab title={t('common:settings')} id="settings">
                            <OptionsEditor
                                source="device"
                                sourceId={id === 'new' ? 0 : id}
                                inheritanceSourceId={
                                    id === 'new'
                                        ? form.values.groupId
                                        : undefined
                                }
                                onChange={handleOptionsChange}
                            />
                        </Tab>
                        <Tab title={t('common:alarms')} id="alarms">
                            <OptionsEditor
                                source="device"
                                sourceId={id}
                                optionsGroups={ALL_ALARM_OPTION_TYPES}
                                onChange={handleOptionsChange}
                            />
                        </Tab>
                        <Tab title={t('common:notifications')}>
                            <OptionsEditor
                                source="device"
                                sourceId={id}
                                optionsGroups={ALL_NOTIFICATION_OPTION_TYPES}
                                onChange={handleOptionsChange}
                            />
                        </Tab>
                        <Tab title={t('common:sensors')}>
                            <DeviceTemplateSensors
                                sensors={sensors}
                                setSensors={setSensors}
                                setEditSensor={setEditSensor}
                            />
                        </Tab>
                    </Tabs>
                </EuiForm>
            </>
        </DetailsPage>
    )
}

export default DeviceTemplateDetailsPage
