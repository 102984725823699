import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'

import { formatSize } from '@utils/numbers'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type SshMemoryChartProps = ChartProps

const SshMemoryChart = (props: SshMemoryChartProps) => {
    const { t } = useTranslation(['network'])
    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    const lines: ChartLine[] = [
        {
            key: 'sshMemoryTotal',
            label: t('network:data_key_ssh_memory_total'),
        },
        {
            key: 'sshMemoryFree',
            label: t('network:data_key_ssh_memory_free'),
        },
        {
            key: 'sshMemoryUsed',
            label: t('network:data_key_ssh_memory_used'),
        },
        {
            key: 'sshMemoryBuffer',
            label: t('network:data_key_ssh_memory_buffer'),
        },
    ]

    return (
        <NetworkLineChart
            data={props.data}
            query={props.query}
            setQuery={props.setQuery}
            lines={lines}
            xAxis="timestamp"
            xAxisFormatter={(v) => formatTimestamp(v, props.query)}
            xAxisDomain={xAxisDomain}
            yAxisFormatter={(v) => {
                if (v === Infinity || v === -Infinity) return v

                return formatSize(v, 'MB', 'GB')
            }}
        />
    )
}
export default SshMemoryChart
