import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface NotificationsState {
    overlayNotification: string | undefined
    exceptionMessages?: string[]
}

const initialState: NotificationsState = {
    overlayNotification: undefined,
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationsState: (
            state,
            action: PayloadAction<NotificationsState>
        ) => {
            // Merge exception messages together if it's for the same overlay notification
            if (
                state.overlayNotification === action.payload.overlayNotification
            ) {
                state.exceptionMessages = [
                    ...(state.exceptionMessages || []),
                    ...(action.payload.exceptionMessages || []),
                ]
            } else {
                state.exceptionMessages = action.payload.exceptionMessages
            }

            state.overlayNotification = action.payload.overlayNotification
        },
    },
})

export const { setNotificationsState } = notificationsSlice.actions
export default notificationsSlice.reducer
