import {
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import AlarmsFilters from '../AlarmsFilters/AlarmsFilters'
import AlarmsPrefrences from './AlarmsPrefrences'
import { FC } from 'react'
import { Permissions } from '@services/auth'
import { setAlarmsStats } from '@store/alarms'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

export const AlarmsControls: FC = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['common', 'alarms'])
    const { stats, prefrences } = useAppSelector((state) => state.alarms)

    // Permissons
    const canViewGlobal = useHasPermission(Permissions.alarm.canViewGlobal)

    const toggleStats = () => {
        dispatch(setAlarmsStats(!stats))
    }

    const statsButton = prefrences?.iconsOnly ? (
        <EuiButtonIcon
            iconType="stats"
            color={stats ? 'primary' : 'text'}
            aria-label={stats ? t('alarms:hide_stats') : t('alarms:show_stats')}
            title={stats ? t('alarms:hide_stats') : t('alarms:show_stats')}
            onClick={toggleStats}
        />
    ) : (
        <EuiButtonEmpty
            iconType="stats"
            color={stats ? 'primary' : 'text'}
            onClick={toggleStats}
        >
            {t('alarms:stats')}
        </EuiButtonEmpty>
    )

    return (
        <EuiFlexGroup
            gutterSize="s"
            alignItems="center"
            justifyContent="flexEnd"
        >
            <EuiFlexItem grow={false}>
                <AlarmsFilters />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <AlarmsPrefrences />
            </EuiFlexItem>
            {canViewGlobal && (
                <EuiFlexItem grow={false}>{statsButton}</EuiFlexItem>
            )}
        </EuiFlexGroup>
    )
}
