import {
    Dashboard,
    DashboardCollection,
    DetailsDashboardRequest,
} from '@services/dashboards'

import { Alarm } from '@services/alarms'
import { appApi } from '../app.api'
import { buildQueryParams } from '../helpers'

const dashboardApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        createDashboard: builder.mutation<Dashboard, DetailsDashboardRequest>({
            query: (req) => ({
                url: 'dashboards',
                method: 'POST',
                body: { ...req, options: { ...req.options, query: null } },
                params: req.options.query
                    ? buildQueryParams(req.options.query, ['agent'])
                    : undefined,
            }),
        }),

        fetchAlarmsDashboard: builder.query<
            DashboardCollection<Alarm>,
            { id: string; token: string }
        >({
            query: (q) => ({
                url: `dashboards/alarms/${q.id}?token=${q.token}`,
                method: 'GET',
            }),
        }),
    }),
})

export const { useCreateDashboardMutation, useFetchAlarmsDashboardQuery } =
    dashboardApi
