import {
    EuiButtonIcon,
    EuiDualRange,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormLabel,
    EuiFormRow,
    EuiSwitch,
} from '@elastic/eui'
import {
    Option,
    parseAlarmOptionValue,
    serializeAlarmOptionValue,
} from '@services/options'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

export type AlarmOptionFieldProps = {
    option: Option
    helpText?: JSX.Element | undefined
    unit?: string
    onChange: (option: Option) => void
    isDisabled?: boolean
    label?: string
    hideFieldLabel?: boolean
}

type AlarmOptionFieldValues = {
    warningLimit?: [number, number]
    errorLimit?: [number, number]
    isEnabled: boolean
    isInherited: boolean
}

const getParsedValues = (
    val: any
): [[number, number] | undefined, [number, number] | undefined] => {
    const parsedValues =
        typeof val === 'string' ? parseAlarmOptionValue(val) : val

    const warning =
        Array.isArray(parsedValues?.warning) &&
        parsedValues?.warning?.length == 2
            ? parsedValues.warning
            : undefined

    const error =
        Array.isArray(parsedValues?.error) && parsedValues?.error?.length == 2
            ? parsedValues.error
            : undefined

    return [warning, error]
}

const AlarmOptionField = (props: AlarmOptionFieldProps) => {
    const { t } = useTranslation(['options'])
    const label = props.label || t(`options:${props.option.key.toLowerCase()}`)
    const [values, setValues] = useState<AlarmOptionFieldValues>({
        isEnabled: false,
        isInherited: false,
    })
    const [errorLimit, setErrorLimit] = useState<[number, number] | undefined>(
        undefined
    )
    const [warningLimit, setWarningLimit] = useState<
        [number, number] | undefined
    >(undefined)
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const [initialValues, setInitialValues] = useState<
        AlarmOptionFieldValues | undefined
    >(undefined)

    const levels = useMemo(
        () => [
            {
                min: 0,
                max: warningLimit?.[0] || 0,
                color: 'warning',
            },
            {
                min: warningLimit?.[1] || 100,
                max: 100,
                color: 'warning',
            },
            {
                min: 0,
                max: errorLimit?.[0] || 0,
                color: 'danger',
            },
            {
                min: errorLimit?.[1] || 100,
                max: 100,
                color: 'danger',
            },
            {
                min: Math.max(warningLimit?.[0] || 0, errorLimit?.[0] || 100),
                max: Math.min(warningLimit?.[1] || 100, errorLimit?.[1] || 100),
                color: 'success',
            },
        ],
        [warningLimit, errorLimit]
    )

    const handleOnChange = useCallback(
        (
            val: [string | number, string | number] | undefined,
            type: 'warning' | 'error'
        ) => {
            const newValue: [number, number] | undefined = val
                ? [val[0] as number, val[1] as number]
                : undefined

            if (type === 'warning') setWarningLimit(newValue)
            if (type === 'error') setErrorLimit(newValue)

            setValues((prev) => ({
                ...prev,
                warningLimit: type === 'warning' ? newValue : prev.warningLimit,
                errorLimit: type === 'error' ? newValue : prev.errorLimit,
            }))
        },
        [
            values,
            setValues,
            warningLimit,
            setWarningLimit,
            errorLimit,
            setErrorLimit,
        ]
    )

    const handleOnClear = useCallback(() => {
        if (props.option.inheritanceSource?.value) {
            const [warning, error] = getParsedValues(
                props.option.inheritanceSource.value
            )
            setWarningLimit(warning)
            setErrorLimit(error)

            const newValues = {
                warningLimit: warning,
                errorLimit: error,
                isInherited: true,
            }

            setValues((prev) => ({ ...prev, ...newValues }))
            setInitialValues((prev) =>
                prev ? { ...prev, ...newValues } : { isEnabled, ...newValues }
            )
        } else {
            setValues((prev) => ({
                ...prev,
                warningLimit: [0, 100],
                errorLimit: [0, 100],
                isInherited: true,
            }))
        }
    }, [props.option.inheritanceSource])

    const handleOnEnableChange = (value: boolean) => {
        setIsEnabled(value)
        setValues((prev) => ({ ...prev, isEnabled: value }))
    }

    useEffect(() => {
        if (props.option.isInherited) {
            handleOnClear()
        } else {
            const [warning, error] = getParsedValues(props.option.value)
            setValues((prev) => ({
                ...prev,
                warningLimit: warning,
                errorLimit: error,
                isEnabled: props.option.isEnabled,
                isInherited: props.option.isInherited,
            }))
        }
    }, [props.option.isInherited])

    useEffect(() => {
        setValues((prev) => ({ ...prev, isEnabled: isEnabled }))
    }, [isEnabled])

    useEffect(() => {
        const [warning, error] = getParsedValues(props.option.value)

        if (
            props.option.isInherited !== initialValues?.isInherited ||
            (!initialValues && (warning || error))
        ) {
            setInitialValues({
                warningLimit: warning,
                errorLimit: error,
                isEnabled: props.option.isEnabled,
                isInherited: props.option.isInherited,
            })
        }
    }, [props.option.value])

    useEffect(() => {
        if (initialValues) {
            let warning = values.warningLimit
            let error = values.errorLimit

            if (initialValues.warningLimit) {
                warning = initialValues.warningLimit
                setWarningLimit(warning)
            }

            if (initialValues.errorLimit) {
                error = initialValues.errorLimit
                setErrorLimit(error)
            }

            setIsEnabled(initialValues.isEnabled)
            setValues((prev) => ({
                ...prev,
                warningLimit: warning,
                errorLimit: error,
            }))
        }
    }, [initialValues])

    useEffect(() => {
        const value = serializeAlarmOptionValue({
            warning: values.warningLimit,
            error: values.errorLimit,
        })

        const newOption = {
            ...props.option,
            value: value,
            isEnabled: isEnabled,
            isInherited: values.isInherited,
        }
        props.onChange(newOption)
    }, [values])

    return (
        <EuiFormRow
            {...(props.hideFieldLabel ? {} : { label: label })}
            helpText={props.helpText}
            fullWidth
            isDisabled={props.isDisabled}
        >
            <EuiFlexGroup justifyContent="center" alignItems="center">
                <EuiFlexItem>
                    <EuiFormLabel>{t('options:warning_limit')}</EuiFormLabel>
                    <EuiDualRange
                        min={0}
                        max={100}
                        value={warningLimit || [0, 100]}
                        levels={levels}
                        onChange={(val: [string | number, string | number]) => {
                            handleOnChange(val, 'warning')
                        }}
                        showInput="inputWithPopover"
                        showRange
                        append={props.unit}
                        compressed
                        disabled={
                            props.isDisabled ||
                            props.option.isInherited ||
                            !isEnabled
                        }
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormLabel>{t('options:error_limit')}</EuiFormLabel>
                    <EuiDualRange
                        min={0}
                        max={100}
                        levels={levels}
                        value={errorLimit || [0, 100]}
                        onChange={(val: [string | number, string | number]) => {
                            handleOnChange(val, 'error')
                        }}
                        showInput="inputWithPopover"
                        append={props.unit}
                        compressed
                        disabled={
                            props.isDisabled ||
                            props.option.isInherited ||
                            !isEnabled
                        }
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ paddingTop: '1vh' }}>
                    <EuiButtonIcon
                        iconType="cross"
                        iconSize="s"
                        size="s"
                        aria-label="Clear values"
                        title={t('options:clear_values')}
                        disabled={props.isDisabled || props.option.isInherited}
                        onClick={handleOnClear}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ paddingTop: '1vh' }}>
                    <EuiSwitch
                        label="Is enabled"
                        showLabel={false}
                        checked={isEnabled}
                        onChange={(e) => handleOnEnableChange(e.target.checked)}
                        disabled={props.isDisabled || props.option.isInherited}
                        compressed
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiFormRow>
    )
}
export default AlarmOptionField
