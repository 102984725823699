import { EuiFieldText, EuiFormRow, EuiTextArea } from '@elastic/eui'

type TextFieldProps = {
    form: any
    label?: string
    fullWidth?: boolean
    placeholder?: string
    name: string
    isLoading?: boolean
    textarea?: boolean
    compressed?: boolean
    help?: string
    onChange?: (value: string) => void
    isDisabled?: boolean
}

const TextField = (props: TextFieldProps) => {
    const handleOnChange = (e: any) => {
        if (props.onChange) {
            props.onChange(e.target.value)
        }
        props.form.handleChange(e)
    }

    const fieldProps = {
        name: props.name,
        placeholder: props.placeholder,
        onChange: handleOnChange,
        onBlur: props.form.handleBlur,
        fullWidth: props.fullWidth,
        value: props.form.values[props.name],
        isLoading: props.isLoading,
        compressed: props.compressed,
        isInvalid:
            props.form.touched[props.name] && !!props.form.errors[props.name],
    }

    return (
        <EuiFormRow
            isInvalid={
                props.form.touched[props.name] &&
                !!props.form.errors[props.name]
            }
            error={props.form.errors[props.name]}
            label={props.label}
            helpText={props.help}
            fullWidth={props.fullWidth}
            isDisabled={props.isDisabled}
        >
            {props.textarea ? (
                <EuiTextArea {...fieldProps} />
            ) : (
                <EuiFieldText {...fieldProps} />
            )}
        </EuiFormRow>
    )
}

export default TextField
