import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'

import { formatSize } from '@utils/numbers'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type WmiMemoryChartProps = ChartProps

const WmiMemoryChart = (props: WmiMemoryChartProps) => {
    const { t } = useTranslation(['network'])
    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    const lines: ChartLine[] = [
        {
            key: 'wmiMemoryTotal',
            label: t('network:data_key_wmi_memory_total'),
        },
        {
            key: 'wmiMemoryFree',
            label: t('network:data_key_wmi_memory_free'),
        },
        {
            key: 'wmiMemoryUsed',
            label: t('network:data_key_wmi_memory_used'),
        },
    ]

    return (
        <NetworkLineChart
            data={props.data}
            query={props.query}
            setQuery={props.setQuery}
            lines={lines}
            xAxis="timestamp"
            xAxisFormatter={(v) => formatTimestamp(v, props.query)}
            xAxisDomain={xAxisDomain}
            yAxisFormatter={(v) => {
                if (v === Infinity || v === -Infinity) return v

                return formatSize(v, 'MB', 'GB')
            }}
        />
    )
}
export default WmiMemoryChart
