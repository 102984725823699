import { EuiButton, EuiEmptyPrompt, EuiIcon } from '@elastic/eui'

import { logout } from '@store/auth'
import { setNotificationsState } from '@store/notifications'
import svg from '../ErrorImage.svg'
import { useAppDispatch } from '@hooks/store'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Unauthorized = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { t } = useTranslation(['common'])

    const handleOnLoginClick = () => {
        dispatch(
            setNotificationsState({
                overlayNotification: undefined,
                exceptionMessages: undefined,
            })
        )
        dispatch(logout())
        navigate('/auth/login')
    }

    return (
        <EuiEmptyPrompt
            color="subdued"
            icon={<EuiIcon type={svg} size="original" />}
            title={
                <h2 style={{ marginTop: '3rem' }}>
                    {t('errors:unauthorized_title')}
                </h2>
            }
            layout="vertical"
            body={<p>{t('errors:unauthorized_message')}</p>}
            actions={[
                <EuiButton
                    color="primary"
                    key="login-button"
                    onClick={handleOnLoginClick}
                    fill
                >
                    {t('common:go_login')}
                </EuiButton>,
            ]}
        />
    )
}

export default Unauthorized
