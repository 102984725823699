import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@hooks/auth'

const AuthRoute = () => {
    const { isAuthenticated } = useAuth()
    // eslint-disable-next-line prefer-const
    let location = useLocation()

    if (!isAuthenticated) {
        return <Navigate to="/auth/login" state={{ from: location }} replace />
    }

    return <Outlet />
}

export default AuthRoute
