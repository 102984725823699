import {
    AlarmBreadcrumb,
    AlarmStatus,
    alarmStatusColors,
    handleOnBreadcrumbClick,
} from '@services/alarms'
import { EuiBadge, EuiBreadcrumb, EuiBreadcrumbs, EuiLink } from '@elastic/eui'
import { FC, useMemo } from 'react'

import { AlarmCellProps } from '.'
import { getSensorTagName } from '@services/sensors'
import { useAppSelector } from '@hooks/store'

type AlarmSensorBadgeProps = {
    item: AlarmBreadcrumb
    status: AlarmStatus
}

const AlarmSensorBadge: FC<AlarmSensorBadgeProps> = ({ item, status }) => {
    const { name, sensorType } = item

    return (
        <>
            {item.type !== 'Sensor' || !item.sensorType ? (
                <EuiLink onClick={() => handleOnBreadcrumbClick(item)}>
                    {item.name}
                </EuiLink>
            ) : (
                <EuiBadge
                    color={alarmStatusColors[status]}
                    onClick={() => handleOnBreadcrumbClick(item)}
                    onClickAriaLabel="Click to view sensor details"
                >
                    {getSensorTagName({
                        name: name,
                        type: sensorType!,
                    })}
                </EuiBadge>
            )}
        </>
    )
}

type AlarmItemCellProps = AlarmCellProps & {
    itemType: 'sensor' | 'device' | 'agent'
}

const AlarmItemCell: FC<AlarmItemCellProps> = ({ record, itemType }) => {
    const { prefrences } = useAppSelector((state) => state.alarms)

    const item = useMemo(() => {
        if (!record.breadcrumbs?.agent) return null

        switch (itemType) {
            case 'agent':
                return record.breadcrumbs.agent[0]
            case 'device':
                return record.breadcrumbs.agent[1]
            case 'sensor':
                return record.breadcrumbs.agent[2]
            default:
                return null
        }
    }, [record.breadcrumbs?.agent])

    // Something went wrong, render nothing
    if (!item) return null

    return itemType === 'sensor' ? (
        <AlarmSensorBadge item={item} status={record.status} />
    ) : (
        <EuiLink onClick={() => handleOnBreadcrumbClick(item)}>
            {item.name}
        </EuiLink>
    )
}

export default AlarmItemCell
