import { EuiComboBox, EuiComboBoxOptionOption, EuiFormRow } from '@elastic/eui'

type AlarmOptionSelectFieldProps = {
    label: string
    helpText?: JSX.Element | undefined
    onChange: (options: EuiComboBoxOptionOption[]) => void
    options: EuiComboBoxOptionOption[]
    selectedOptions: EuiComboBoxOptionOption[]
    isDisabled?: boolean
}

const AlarmOptionSelectField = (props: AlarmOptionSelectFieldProps) => {
    return (
        <EuiFormRow
            label={props.label}
            helpText={props.helpText}
            fullWidth
            isDisabled={props.isDisabled}
        >
            <EuiComboBox
                fullWidth
                compressed
                options={props.options}
                selectedOptions={props.selectedOptions}
                onChange={props.onChange}
                isDisabled={props.isDisabled}
            />
        </EuiFormRow>
    )
}

export default AlarmOptionSelectField
