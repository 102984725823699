import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiProgress,
    EuiSpacer,
    EuiTitle,
    useGeneratedHtmlId,
} from '@elastic/eui'

import { useTranslation } from 'react-i18next'

type DetailsPageProps = {
    children?: JSX.Element[] | JSX.Element
    title?: string | React.ReactNode
    titleHasBorder?: boolean
    size?: 's' | 'm' | 'l'
    side?: 'left' | 'right'
    loading?: boolean
    submitLoading?: boolean
    onClose: () => void
    onCancel?: () => void
    onSave?: () => void
    confirmButton?: JSX.Element
}

const renderTitle = (title?: string | React.ReactNode) => {
    if (!title) return null

    if (typeof title === 'string') {
        return (
            <EuiTitle size="m">
                <h2>{title}</h2>
            </EuiTitle>
        )
    }

    return title
}

const DetailsPage = (props: DetailsPageProps) => {
    const { t } = useTranslation(['common'])
    const flyoutId = useGeneratedHtmlId({
        prefix: 'details-flyout',
    })

    return (
        <>
            <EuiFlyout
                ownFocus
                onClose={props.onClose}
                aria-labelledby={flyoutId}
                paddingSize="m"
                side={props.side ?? 'right'}
                size={props.size ?? 'm'}
            >
                {props.loading && (
                    <EuiProgress position="absolute" color="primary" />
                )}

                <EuiFlyoutHeader
                    hasBorder={
                        props.titleHasBorder === true ||
                        props.titleHasBorder === undefined
                    }
                    id={flyoutId}
                >
                    {renderTitle(props.title)}
                </EuiFlyoutHeader>
                <EuiFlyoutBody style={{ height: '100%' }}>
                    <EuiFlexGroup
                        direction="column"
                        justifyContent="spaceBetween"
                        alignItems="stretch"
                        style={{ height: '100%' }}
                    >
                        <EuiFlexItem grow={false}>{props.children}</EuiFlexItem>
                        {(props.onCancel || props.onSave) && (
                            <EuiFlexItem>
                                <EuiSpacer size="l" />
                                <EuiFlexGroup
                                    justifyContent="flexEnd"
                                    alignItems="flexEnd"
                                >
                                    {props.onSave && (
                                        <EuiFlexItem grow={false}>
                                            <EuiButton
                                                color="primary"
                                                fill
                                                size="s"
                                                iconType="check"
                                                isLoading={props.submitLoading}
                                                onClick={props.onSave}
                                            >
                                                {t('common:save')}
                                            </EuiButton>
                                        </EuiFlexItem>
                                    )}
                                    {props.confirmButton && (
                                        <EuiFlexItem grow={false}>
                                            {props.confirmButton}
                                        </EuiFlexItem>
                                    )}

                                    {props.onCancel && (
                                        <EuiFlexItem grow={false}>
                                            <EuiButtonEmpty
                                                size="s"
                                                iconType="cross"
                                                onClick={props.onCancel}
                                            >
                                                {t('common:cancel')}
                                            </EuiButtonEmpty>
                                        </EuiFlexItem>
                                    )}
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        )}
                    </EuiFlexGroup>
                </EuiFlyoutBody>
            </EuiFlyout>
        </>
    )
}
export default DetailsPage
