import AlarmDateCell from './AlarmDateCell'
import AlarmItemCell from './AlarmItemCell'
import AlarmPathCell from './AlarmPathCell'
import AlarmStatusCell from './AlarmStatusCell'
import AlarmTypeCell from './AlarmTypeCell'
import { AlarmsState } from '@store/alarms'
import { TableColumn } from '@components/table'
import i18n from '@locale/i18n'

export const getAlarmsTableColumns = (
    prefrences: AlarmsState['prefrences']
): TableColumn[] => {
    const columns: TableColumn[] = [
        {
            id: 'status',
            field: 'status',
            name: i18n.t('alarms:status'),
            sortable: true,
            width: '20%',
            formatter: (value, record) => (
                <AlarmStatusCell value={value} record={record} />
            ),
        },
        {
            id: 'created',
            field: 'created',
            name: i18n.t('alarms:created'),
            sortable: true,
            width: '10%',
            formatter: (value, record) => (
                <AlarmDateCell value={value} record={record} />
            ),
        },
        {
            id: 'path',
            field: 'additionalData.agent',
            name: i18n.t('alarms:path'),
            sortable: false,
            hidden:
                !prefrences?.displayAgentPath && !prefrences?.displayGroupPath,
            width: '20%',
            formatter: (value, record) => (
                <AlarmPathCell value={value} record={record} />
            ),
        },
        {
            id: 'agent',
            field: 'additionalData.agent',
            name: i18n.t('alarms:agent'),
            sortable: true,
            formatter: (value, record) => (
                <AlarmItemCell value={value} record={record} itemType="agent" />
            ),
        },
        {
            id: 'device',
            field: 'additionalData.device',
            name: i18n.t('alarms:device'),
            sortable: true,
            formatter: (value, record) => (
                <AlarmItemCell
                    value={value}
                    record={record}
                    itemType="device"
                />
            ),
        },
        {
            id: 'sensor',
            field: 'additionalData.name',
            name: i18n.t('alarms:sensor'),
            sortable: true,
            formatter: (value, record) => (
                <AlarmItemCell
                    value={value}
                    record={record}
                    itemType="sensor"
                />
            ),
        },
        {
            id: 'type',
            field: 'type',
            name: i18n.t('alarms:type'),
            sortable: true,
            formatter: (value, record) => (
                <AlarmTypeCell value={value} record={record} />
            ),
        },
    ]

    // Throw an error if there is more than one column with the same id
    const ids = columns.map((column) => column.id)
    if (new Set(ids).size !== ids.length) {
        throw new Error('Duplicate column ids')
    }

    return columns
}
