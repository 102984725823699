import {
    AnyAction,
    Middleware,
    MiddlewareAPI,
    isRejectedWithValue,
} from '@reduxjs/toolkit'

import { addToast } from './toasts'
import i18n from '@locale/i18n'
import { setNotificationsState } from './notifications'

const getErrorToast = (action: AnyAction) => {
    const errorCode = action?.payload?.status

    switch (errorCode) {
        case 403:
            return {
                id: 'unauthorized',
                title: i18n.t('errors:unauthorized_title'),
                text: i18n.t('errors:unauthorized_message'),
                color: 'danger',
            }

        case 400:
            return {
                id: 'bad-request',
                title: i18n.t('errors:invalid_request_title'),
                text: i18n.t('errors:invalid_request_message'),
                color: 'danger',
            }

        case 404:
            return {
                id: 'not-found',
                title: i18n.t('errors:not_found_title'),
                text: i18n.t('errors:not_found_message'),
                color: 'danger',
            }
        case 500:
            return {
                id: 'server-error',
                title: i18n.t('errors:server_error'),
                text: i18n.t('errors:server_error_message'),
                color: 'danger',
            }
    }
}

const getOverlayNotification = (action: any): string | undefined => {
    const errorCode = action?.payload?.status

    switch (errorCode) {
        case 401:
            return 'unauthorized'
        case 403:
            return 'forbidden-access'
        default:
            return undefined
    }
}

const errorHandlingMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            const overlayNotification = getOverlayNotification(action)
            const toast = getErrorToast(action)

            if (overlayNotification) {
                api.dispatch(
                    setNotificationsState({
                        overlayNotification: overlayNotification,
                        exceptionMessages: (action.payload as any)?.data
                            ?.errors,
                    })
                )
            } else if (toast) {
                api.dispatch(addToast(toast))
            }
        }

        return next(action)
    }

export default errorHandlingMiddleware
