import {
    EuiButtonIcon,
    EuiHeader,
    EuiHeaderSection,
    EuiHeaderSectionItem,
} from '@elastic/eui'

import HeaderNavMenu from './HeaderNavMenu'
import HeaderUserMenu from './HeaderUserMenu'
import logo from '@assets/logo_default_dark_sm.png'
import { logout } from '@store/auth'
import { useAppDispatch } from '@hooks/store'
import { useAuth } from '@hooks/auth'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Header = () => {
    const auth = useAuth()
    const { t } = useTranslation(['common'])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isDarkTheme, setIsDarkTheme] = useState(
        localStorage.getItem('theme') === 'dark'
    )

    const handleLogout = () => {
        dispatch(logout())
        navigate('/auth/login')
    }

    const handleThemeChange = () => {
        localStorage.setItem('theme', isDarkTheme ? 'light' : 'dark')
        setIsDarkTheme(!isDarkTheme)
        window.location.reload()
    }

    return (
        <EuiHeader position="fixed" theme="dark">
            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    <img
                        src={logo}
                        style={{
                            transform: 'scale(.8)',
                            paddingRight: '3rem',
                        }}
                        alt="Logo"
                    />
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    <HeaderNavMenu />
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
            <EuiHeaderSection side="right">
                {auth.user && (
                    <EuiHeaderSectionItem>
                        <HeaderUserMenu
                            displayName={`${auth.user?.firstName} ${auth.user?.lastName}`}
                            onLogout={handleLogout}
                        />
                    </EuiHeaderSectionItem>
                )}
                <EuiHeaderSectionItem>
                    <EuiButtonIcon
                        iconType={isDarkTheme ? 'sun' : 'moon'}
                        color="warning"
                        title={
                            isDarkTheme
                                ? t('common:switch_light_theme')
                                : t('common:switch_dark_theme')
                        }
                        size="m"
                        aria-label={
                            isDarkTheme
                                ? t('common:switch_light_theme')
                                : t('common:switch_dark_theme')
                        }
                        style={{ color: isDarkTheme ? '#ffc414' : '#fff' }}
                        onClick={handleThemeChange}
                    />
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
        </EuiHeader>
    )
}

export default Header
