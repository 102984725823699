import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { isDebug } from '@utils/globals'
import langEn from './en/translations.json'

export const resources = {
    en: langEn,
} as const
export const defaultNS = 'translations'

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        debug: isDebug(),
        ns: ['translations'],
        defaultNS,
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
    })

export default i18n
