import { Category, CreateCategoryCommand } from '@services/categories'
import {
    EuiBadge,
    EuiForm,
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from '@elastic/eui'
import {
    Query,
    useCreateCategoryMutation,
    useFetchCategoriesQuery,
    useFetchTenantsQuery,
    useFindCategoryQuery,
    useUpdateCategoryMutation,
} from '@services/api'
import { SelectionBoxField, TextField } from '@components/form'
import { object, string } from 'yup'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { DEBOUNCE_DELAY } from '@utils/globals'
import { DetailsPage } from '@components/layout'
import { Permissions } from '@services/auth'
import { buildSearchQueryWithType } from '@services/api/helpers'
import { debounce } from 'lodash'
import { getNumberOrDefault } from '@utils/numbers'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const categorySchema = object({
    name: string().required(),
    description: string().optional(),
    tenantId: string().required(),
})

const excludeItem = (id: number) => (item: Category) => item.id !== id

const CategoryDetailsPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(['common', 'categories'])
    const [searchQuery, setSearchQuery] = useState<Query | undefined>(undefined)
    const [isSearchLoading, setIsSearchLoading] = useState(false)

    const canCreateCategory = useHasPermission(Permissions.category.canCreate)
    const canUpdateCategory = useHasPermission(Permissions.category.canUpdate)

    const canViewTenants = useHasPermission(Permissions.tenant.canView)

    const { id } = useParams()
    const { data, isLoading } = useFindCategoryQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip: !id || isNaN(+id) }
    )

    // Fetches
    const { data: tenants } = useFetchTenantsQuery(
        {},
        { skip: !canViewTenants }
    )
    const {
        data: similarCategoriesRaw,
        isLoading: isSimilarCategoriesLoading,
    } = useFetchCategoriesQuery(searchQuery ?? {}, {
        skip: !searchQuery,
        refetchOnMountOrArgChange: true,
    })

    // Just a holder for the filtered categories without current one (if editing existing category)
    const similarCategories = useMemo(
        () => similarCategoriesRaw?.items.filter(excludeItem(+(id ?? '0'))),
        [similarCategoriesRaw?.items, id]
    )

    // Mutations
    const [createCategory, { isLoading: isCreating }] =
        useCreateCategoryMutation()
    const [updateCategory, { isLoading: isUpdating }] =
        useUpdateCategoryMutation()

    const isEditing = useMemo(() => data && id, [data, id])

    const isFormDisabled = useMemo(() => {
        if (isEditing && canUpdateCategory) return false
        if (!isEditing && canCreateCategory) return false

        return true
    }, [isEditing, canCreateCategory, canUpdateCategory])

    // Form
    const handleOnSubmit = async (record: CreateCategoryCommand) => {
        try {
            if (data && id) {
                await updateCategory({ ...record, id: +id })
            } else {
                await createCategory(record)
            }
            navigate('/categories')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<CreateCategoryCommand>({
        initialValues: {
            name: '',
            description: '',
            tenantId: '',
        },
        validationSchema: categorySchema,
        onSubmit: handleOnSubmit,
    })

    // Search
    const handleOnSearch = useCallback(
        debounce((value: string) => {
            if (value) {
                setSearchQuery(() =>
                    buildSearchQueryWithType<Category>(value, ['name'])
                )
            } else {
                setSearchQuery(undefined)
            }

            setIsSearchLoading(false)
        }, DEBOUNCE_DELAY),
        []
    )

    const handleOnChange = (value: string) => {
        setIsSearchLoading(true)
        handleOnSearch(value)
    }

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    return (
        <DetailsPage
            onClose={() => navigate('..')}
            onCancel={() => navigate('..')}
            size="s"
            title={data ? data.name : t('categories:new')}
            loading={isLoading || isCreating || isUpdating}
            onSave={isFormDisabled ? undefined : form.handleSubmit}
        >
            <EuiForm component="form" onSubmit={form.handleSubmit}>
                <TextField
                    form={form}
                    name="name"
                    isDisabled={isFormDisabled}
                    fullWidth
                    isLoading={isSearchLoading || isSimilarCategoriesLoading}
                    onChange={handleOnChange}
                    label={t('categories:name')}
                />
                {similarCategories && similarCategories?.length > 0 && (
                    <>
                        <EuiPanel
                            hasShadow={false}
                            hasBorder={false}
                            color="warning"
                        >
                            <EuiTitle size="xxs">
                                <p>
                                    {t('categories:similar_categories_found')}
                                </p>
                            </EuiTitle>
                            <EuiText size="xs">
                                {t(
                                    'categories:similar_categories_found_description'
                                )}
                            </EuiText>
                            <EuiSpacer size="m" />
                            {similarCategories?.map((item) => (
                                <EuiBadge
                                    key={item.id}
                                    iconType="link"
                                    onClickAriaLabel="Click to view category details"
                                    onClick={() =>
                                        navigate(`/categories/${item.id}`)
                                    }
                                >
                                    {item.name}
                                </EuiBadge>
                            ))}
                        </EuiPanel>
                        <EuiSpacer size="s" />
                    </>
                )}
                <TextField
                    form={form}
                    name="description"
                    isDisabled={isFormDisabled}
                    fullWidth
                    textarea
                    label={t('categories:description')}
                />
                {canViewTenants && (
                    <SelectionBoxField
                        fullWidth
                        form={form}
                        disabled={isFormDisabled}
                        name="tenantId"
                        label={t('categories:tenant')}
                        value={form.values.tenantId}
                        onChange={(value) =>
                            form.setFieldValue('tenantId', value[0]?.value)
                        }
                        options={
                            tenants?.items?.map((item) => ({
                                label: item.name,
                                value: item.id,
                            })) ?? []
                        }
                    />
                )}
            </EuiForm>
        </DetailsPage>
    )
}

export default CategoryDetailsPage
