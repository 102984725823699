import {
    EuiButton,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPopover,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui'

import { Option } from '@services/options'
import OptionField from '@components/options/OptionsEditor/OptionField'
import { Sensor } from '@services/sensors'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateSensorChannelOptionMutation } from '@services/api/endpoints/options.endpoint'

type NetworkChannelOptionPopoverProps = {
    sensorId: Sensor['id']
    option: Option
}

const NetworkChannelOptionPopover = ({
    sensorId,
    option,
}: NetworkChannelOptionPopoverProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [optionValue, setOptionValue] = useState<Option>(option)
    const { t } = useTranslation(['options'])

    const [update, { isLoading }] = useUpdateSensorChannelOptionMutation()

    const onSave = async () => {
        if (option) await update({ ...optionValue, sensorId } as Option)
        setIsOpen(false)
    }

    const onChange = (o: Option) => {
        setOptionValue((prev) => ({
            ...prev,
            value: o.value ? `${o.value};${option.channel}` : undefined,
            isInherited: o.value === undefined || o.isInherited,
        }))
    }

    return (
        <EuiPopover
            button={
                <EuiButtonIcon
                    iconType="watchesApp"
                    onClick={() => setIsOpen(!isOpen)}
                />
            }
            anchorPosition="upLeft"
            isOpen={isOpen}
            closePopover={() => setIsOpen(false)}
        >
            <EuiTitle size="xs">
                <h6>{t('custom_alarm')}</h6>
            </EuiTitle>
            <EuiSpacer size="m" />
            <OptionField
                option={option}
                onChange={onChange}
                fieldLabel={option.channel}
            />
            <EuiSpacer size="m" />
            <EuiFlexGroup>
                <EuiFlexItem />
                <EuiFlexItem grow={false}>
                    <EuiButton
                        color="primary"
                        fill
                        size="s"
                        iconType="check"
                        isLoading={isLoading}
                        onClick={async () => await onSave()}
                        disabled={!option}
                    >
                        {t('common:save')}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPopover>
    )
}

export default NetworkChannelOptionPopover
