import {
    ALARMS_DEFAULT_REFRESH_INTERVAL,
    ALARMS_MIN_REFRESH_INTERVAL,
} from '@utils/globals'
import {
    AlarmsControls,
    AlarmsPageTitle,
    AlarmsStats,
    AlarmsTable,
} from '@components/alarms'
import { EuiFlexGroup, EuiFlexItem, useEuiTheme } from '@elastic/eui'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import { Outlet } from 'react-router-dom'
import { Page } from '@components/layout'
import { Permissions } from '@services/auth'
import { useEffect } from 'react'
import { useFetchAlarmsQuery } from '@services/api'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const getInterval = (interval: number | undefined) => {
    if (!interval || interval < ALARMS_MIN_REFRESH_INTERVAL)
        return ALARMS_DEFAULT_REFRESH_INTERVAL
    return interval
}

const AlarmsPage = () => {
    const {
        query,
        filters,
        realtime,
        interval,
        stats: storedStats,
        prefrences,
    } = useAppSelector((state) => state.alarms)

    // Permissons
    const canViewGlobal = useHasPermission(Permissions.alarm.canViewGlobal)

    const stats = canViewGlobal ? storedStats : false

    // Queries
    const { data, isLoading, isFetching, fulfilledTimeStamp } =
        useFetchAlarmsQuery(
            {
                query,
                realtime,
                stats: stats || false,
                resolvedTemp: prefrences.displayResolved,
                filters,
            },
            { pollingInterval: getInterval(interval) }
        )

    return (
        <>
            <Outlet />
            <Page
                title={<AlarmsPageTitle lastUpdated={fulfilledTimeStamp} />}
                loading={isLoading}
                paddingSize={{
                    body: 'none',
                }}
            >
                <EuiFlexGroup direction="column">
                    {stats && (
                        <EuiFlexItem>
                            <AlarmsStats
                                data={data?.stats}
                                loading={isLoading}
                            />
                        </EuiFlexItem>
                    )}
                    <EuiFlexItem>
                        <AlarmsTable
                            data={data}
                            loading={isLoading || isFetching}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </Page>
        </>
    )
}

export default AlarmsPage
