import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIconTip } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import { useDeleteRoleMutation, useFetchRolesQuery } from '@services/api'
import { Table, TableColumn } from '@components/table'

import { Page } from '@components/layout'
import { Role } from '@services/roles'
import { useHasDependentPermissions } from '@hooks/auth'
import { useQuery } from '@hooks/query'
import { useTranslation } from 'react-i18next'

const RolesPage = () => {
    const { t } = useTranslation(['common', 'roles'])
    const navigate = useNavigate()
    const {
        query,
        handleQueryChange,
        handleQueryFiltersChange,
        clearQueryFilters,
    } = useQuery()

    const canCreateRole = useHasDependentPermissions('role', [
        'canView',
        'canCreate',
    ])
    const canUpdateRole = useHasDependentPermissions('role', [
        'canView',
        'canUpdate',
    ])
    const canDeleteRole = useHasDependentPermissions('role', ['canDelete'])

    const { data, isLoading, isFetching } = useFetchRolesQuery(query)
    const [deleteRole, { isLoading: isDeleteLoading }] = useDeleteRoleMutation()

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('roles:name'),
            sortable: true,
            filterType: 'text',
            filterPlaceholder: t('roles:filter_by_name'),
            formatter: (value: string, row: Role) =>
                row.cannotBeDeleted ? (
                    <EuiFlexGroup direction="row" gutterSize="s">
                        <EuiFlexItem grow={false}>{value}</EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiIconTip
                                size="s"
                                title={t('roles:cannot_be_deleted')}
                                type="lock"
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                ) : (
                    <span>{value}</span>
                ),
        },
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateRole ? 'onEdit' : 'onView']: (role: Role) =>
                navigate(`/roles/${role.id}`),
            onDelete: canDeleteRole
                ? (role: Role) => deleteRole(role.id)
                : undefined,
            isDeleteEnabled: (role: Role) => !role.cannotBeDeleted,
        },
    ]

    return (
        <>
            <Outlet />
            <Page
                title={t('roles:title')}
                iconType="securityApp"
                items={
                    canCreateRole
                        ? [
                              <EuiButton
                                  key="btn_new_role"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/roles/new')}
                              >
                                  {t('roles:new_role')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <Table<Role>
                    loading={isLoading || isFetching || isDeleteLoading}
                    items={data?.items || []}
                    totalCount={data?.totalCount || 0}
                    columns={columns}
                    onFilter={handleQueryFiltersChange}
                    onFilterClear={clearQueryFilters}
                    onChange={handleQueryChange}
                />
            </Page>
        </>
    )
}

export default RolesPage
