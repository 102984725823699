import {
    PermissionType,
    PermissionValueKeyType,
    getPermissionWithDependencies,
} from '@services/auth'

import { hasPermissions } from '@services/auth/auth.service'
import { useAppSelector } from '@hooks/store'
import { useMemo } from 'react'

export const useHasPermission = (permission: string) => {
    return useHasPermissions([permission])
}

export const useHasPermissions = (permissions: string[]) => {
    const { user } = useAppSelector((state) => state.auth)
    return useMemo(() => hasPermissions(permissions), [user, permissions])
}

export const useHasDependentPermissions = (
    group: keyof PermissionType,
    permissions: PermissionValueKeyType[]
) => {
    const { user } = useAppSelector((state) => state.auth)
    return useMemo(
        () => hasPermissions(getPermissionWithDependencies(group, permissions)),
        [user, group, permissions]
    )
}
