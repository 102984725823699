import { formatFullDate, formatReadableDate } from '@utils/dates'

import { AlarmCellProps } from '.'
import { EuiText } from '@elastic/eui'
import { FC } from 'react'
import { useAppSelector } from '@hooks/store'

const AlarmDateCell: FC<AlarmCellProps> = ({ value, record }) => {
    const { prefrences } = useAppSelector((state) => state.alarms)

    const title = prefrences?.humanReadableDates
        ? formatFullDate(value, true)
        : formatReadableDate(value, true)

    const text = prefrences?.humanReadableDates
        ? formatReadableDate(value, true)
        : formatFullDate(value, true)

    return (
        <EuiText size={prefrences?.compressedLayout ? 'xs' : 's'} title={title}>
            {text}
        </EuiText>
    )
}

export default AlarmDateCell
