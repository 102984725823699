import { createSlice } from '@reduxjs/toolkit'

export interface ToastsState {
    toasts: any[]
}

const initialState: ToastsState = {
    toasts: [],
}

export const toastsSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addToast: (state, { payload: toast }) => {
            state.toasts = [
                ...state.toasts.filter((t) => t.id !== toast.id),
                toast,
            ]
        },
        removeToast: (state, { payload: id }) => {
            state.toasts = state.toasts.filter((toast) => toast.id !== id)
        },
    },
})

export const { addToast, removeToast } = toastsSlice.actions
export default toastsSlice.reducer
