import { EuiButton, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import {
    getDefaultQuery,
    useDeleteSensorMutation,
    useDeleteSensorsMutation,
    useFetchDevicesQuery,
    useFetchSensorsQuery,
} from '@services/api'
import { Sensor, SensorType, getSensorTypeTranslation } from '@services/sensors'
import { Table, TableColumn } from '@components/table'
import { useHasDependentPermissions, useHasPermission } from '@hooks/auth'

import { Page } from '@components/layout'
import { Permissions } from '@services/auth'
import { getOptionsByEnum } from '@utils/enums'
import { useQuery } from '@hooks/query'
import { useTranslation } from 'react-i18next'

const SensorTemplatesPage = () => {
    const { t } = useTranslation(['common', 'sensors'])
    const navigate = useNavigate()
    const {
        query,
        handleQueryChange,
        handleQueryFiltersChange,
        clearQueryFilters,
    } = useQuery(['(deviceIsTemplate|isTemplate)==true'])

    // Permissions
    const canCreateSensorTemplates = useHasDependentPermissions('sensor', [
        'canViewTemplates',
        'canCreateTemplates',
    ])
    const canUpdateSensorTemplates = useHasDependentPermissions('sensor', [
        'canViewTemplates',
        'canUpdateTemplates',
    ])
    const canDeleteSensorTemplates = useHasDependentPermissions('sensor', [
        'canDeleteTemplates',
    ])
    const canViewSensors = useHasPermission(Permissions.sensor.canView)

    const canViewDeviceTemplates = useHasPermission(
        Permissions.device.canViewTemplates
    )

    // Queries
    const { data: devices } = useFetchDevicesQuery(
        {
            ...getDefaultQuery(),
            filters: ['isTemplate==true'],
        },
        { skip: !canViewDeviceTemplates }
    )
    const { data, isLoading } = useFetchSensorsQuery(query)

    // Mutations
    const [deleteSensor, { isLoading: isDeleteLoading }] =
        useDeleteSensorMutation()
    const [deleteSensors, { isLoading: isDeletesLoading }] =
        useDeleteSensorsMutation()

    const columns: TableColumn[] = [
        {
            field: 'templateName',
            name: t('sensors:template_name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('sensors:filter_by_template_name'),
        },
        {
            field: 'isEnabled',
            name: t('sensors:is_enabled'),
            renderAs: 'boolean',
            sortable: true,
            filterable: true,
            filterType: 'boolean',
            filterPlaceholder: t('sensors:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        {
            field: 'type',
            name: t('sensors:type'),
            formatter: (v: any) => <span>{getSensorTypeTranslation(v)}</span>,
            sortable: true,
            filterable: true,
            filterField: 'type',
            filterType: 'options',
            filterPlaceholder: t('sensors:filter_by_type'),
            filterOptions:
                getOptionsByEnum(SensorType).map(({ value }) => ({
                    value,
                    label: getSensorTypeTranslation(value),
                })) ?? [],
        },
        ...(canViewDeviceTemplates
            ? [
                  {
                      field: 'device.templateName',
                      name: t('sensors:device'),
                      sortable: true,
                      filterable: true,
                      filterField: 'deviceId',
                      filterType: 'options',
                      filterPlaceholder: t('sensors:filter_by_device'),
                      filterOptions:
                          devices?.items?.map((item) => ({
                              label: item.templateName,
                              value: item.id,
                          })) ?? [],
                  } as TableColumn,
              ]
            : []),
        {
            field: 'action',
            renderAs: 'action',
            [canUpdateSensorTemplates ? 'onEdit' : 'onView']: (
                sensor: Sensor
            ) => navigate(`/sensorTemplates/${sensor.id}`),
            onDelete: canDeleteSensorTemplates
                ? (sensor: Sensor) => deleteSensor(sensor.id)
                : undefined,
        },
    ]

    return (
        <>
            <Outlet />
            <Page
                title={t('sensors:templates_title')}
                iconType="managementApp"
                items={
                    canCreateSensorTemplates
                        ? [
                              <EuiButton
                                  key="btn_new_sensor"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() =>
                                      navigate('/sensorTemplates/new')
                                  }
                              >
                                  {t('sensors:new_sensor_template')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <>
                    <EuiTabs>
                        {canViewSensors && (
                            <EuiTab
                                key="sensors"
                                name="Sensors"
                                onClick={() => navigate('/sensors')}
                            >
                                {t('sensors:title')}
                            </EuiTab>
                        )}
                        <EuiTab
                            key="sensor-templates"
                            name="Device templates"
                            isSelected={true}
                        >
                            {t('sensors:templates_title')}
                        </EuiTab>
                    </EuiTabs>
                    <EuiSpacer size="s" />
                    <Table<Sensor>
                        loading={
                            isLoading || isDeleteLoading || isDeletesLoading
                        }
                        items={data?.items ?? []}
                        totalCount={data?.totalCount ?? 0}
                        columns={columns}
                        onFilter={handleQueryFiltersChange}
                        onFilterClear={clearQueryFilters}
                        onChange={handleQueryChange}
                        selectable={canDeleteSensorTemplates}
                        itemSelectable={() => true}
                        deleteSelectedRows={
                            canDeleteSensorTemplates
                                ? (items) =>
                                      deleteSensors(items.map((x) => x.id))
                                : undefined
                        }
                    />
                </>
            </Page>
        </>
    )
}

export default SensorTemplatesPage
