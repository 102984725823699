import {
    EuiButton,
    EuiButtonEmpty,
    EuiEmptyPrompt,
    EuiEmptyPromptProps,
    EuiImage,
    EuiPanel,
    EuiThemeColorMode,
} from '@elastic/eui'

import { FC } from 'react'
import noResultsDark from '@assets/empty-prompt/no-results--dark.svg'
import noResultsLight from '@assets/empty-prompt/no-results--light.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const theme: EuiThemeColorMode =
    (localStorage.getItem('theme') as EuiThemeColorMode) ?? 'light'

type NoResultsProps = {
    title?: string | React.ReactNode | undefined
    text?: string | React.ReactNode | undefined
    image?: string | undefined
    layout?: EuiEmptyPromptProps['layout']
    isRefreshing?: boolean | undefined
    onRefresh?: () => void | undefined
    onBack?: () => void | undefined
}

const NoResults: FC<NoResultsProps> = ({
    title,
    text,
    isRefreshing,
    onRefresh,
    onBack,
    ...rest
}) => {
    const navigate = useNavigate()

    const { t } = useTranslation(['common'])
    const isDarkTheme = theme === 'dark'

    const image = rest.image || (isDarkTheme ? noResultsDark : noResultsLight)

    return (
        <EuiEmptyPrompt
            color="subdued"
            icon={
                <EuiImage size="fullWidth" src={image} alt="no results found" />
            }
            title={<h2>{title || t('common:no_results_found_title')}</h2>}
            layout={rest.layout || 'vertical'}
            body={<p>{text || t('common:no_results_found_text')}</p>}
            actions={[
                onRefresh && (
                    <EuiButton
                        color="primary"
                        key="go-home"
                        fill
                        onClick={onRefresh}
                        isLoading={isRefreshing}
                    >
                        {t('common:refresh')}
                    </EuiButton>
                ),
                onBack && (
                    <EuiButtonEmpty
                        iconType="arrowLeft"
                        key="go-back"
                        flush="both"
                        onClick={onBack}
                    >
                        {t('common:go_back')}
                    </EuiButtonEmpty>
                ),
            ]}
        />
    )
}

export default NoResults
