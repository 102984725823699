import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiHeaderLink,
    EuiHeaderLinks,
    EuiPopover,
    EuiText,
} from '@elastic/eui'
import { NavItem, navigationItems } from '@config/nav'
import { useCallback, useMemo, useState } from 'react'

import { hasPermissions } from '@services/auth/auth.service'
import { resolvePath } from 'react-router-dom'
import { useAppSelector } from '@hooks/store'
import { useTranslation } from 'react-i18next'

const buildHref = (href: string | undefined) =>
    href ? `#${resolvePath(href).pathname}` : undefined

const isRouteActive = (route: string | undefined) => {
    if (!route) {
        return false
    }

    const currentRoute = window.location.hash
    return (
        currentRoute.endsWith(route) &&
        (route.indexOf('new') !== -1 || currentRoute.indexOf('new') === -1)
    )
}

const isNavActive = (item: NavItem) =>
    isRouteActive(item.href) || item.items?.some((i) => isRouteActive(i.href))

type HeaderNavPopoverProps = {
    item: NavItem
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
}

const HeaderNavPopover = ({
    item,
    isOpen,
    onOpen,
    onClose,
}: HeaderNavPopoverProps) => {
    const { t } = useTranslation(['navigation'])
    const hasSubItems = item.items && item.items.length > 0

    const link = (
        <EuiHeaderLink
            size="s"
            iconType={item.icon}
            onMouseEnter={hasSubItems ? onOpen : undefined}
            isActive={isNavActive(item)}
            href={!hasSubItems ? buildHref(item.href) : undefined}
        >
            <EuiText size="s">{t(item.name)}</EuiText>
        </EuiHeaderLink>
    )

    return (
        <EuiPopover
            key={item.id}
            isOpen={isOpen}
            closePopover={onClose}
            panelPaddingSize="s"
            button={link}
        >
            <EuiFlexGroup
                direction="column"
                onMouseLeave={hasSubItems ? onClose : undefined}
                justifyContent="center"
                alignItems="center"
            >
                {hasSubItems &&
                    item.items &&
                    item.items.map((sub) => (
                        <EuiFlexItem
                            grow
                            style={{ width: '100%' }}
                            key={sub.id}
                        >
                            <EuiHeaderLink
                                style={{ width: '100%' }}
                                href={buildHref(sub.href)}
                                isActive={isNavActive(sub)}
                                iconType={sub.icon}
                            >
                                <EuiText size="s">{t(sub.name)}</EuiText>
                            </EuiHeaderLink>
                        </EuiFlexItem>
                    ))}
            </EuiFlexGroup>
        </EuiPopover>
    )
}

const HeaderNavMenu = () => {
    const { user } = useAppSelector((state) => state.auth)
    const [openNavItem, setOpenNavItem] = useState<string | undefined>(
        undefined
    )

    const getNavItemsForUser = useCallback(
        (items: NavItem[]) =>
            items.reduce((acc: NavItem[], curr) => {
                const hasPermission =
                    !curr.permissions ||
                    curr.permissions.length === 0 ||
                    hasPermissions(curr.permissions)

                if (hasPermission) return [...acc, curr]

                if (curr.alt) {
                    const hasAltPermission =
                        !curr.alt.permissions ||
                        curr.alt.permissions.length === 0 ||
                        hasPermissions(curr.alt.permissions)

                    if (hasAltPermission)
                        return [
                            ...acc,
                            {
                                ...curr,
                                href: curr.alt.href || curr.href,
                                name: curr.alt.name || curr.name,
                            },
                        ]
                }

                return acc
            }, []),
        [user]
    )

    const navItems = useMemo(() => {
        let userItems: NavItem[] = []
        getNavItemsForUser(navigationItems).forEach((i) => {
            if (i.items && i.items.length > 0) {
                const subItems = getNavItemsForUser(i.items)
                if (subItems && subItems.length > 0)
                    userItems = [...userItems, { ...i, items: subItems }]
            } else {
                userItems = [...userItems, { ...i }]
            }
        })
        return userItems
    }, [user, navigationItems])

    return (
        <EuiHeaderLinks gutterSize="m">
            {navItems.map((item) => (
                <HeaderNavPopover
                    item={item}
                    onClose={() => setOpenNavItem(undefined)}
                    onOpen={() => setOpenNavItem(item.id)}
                    isOpen={openNavItem === item.id}
                    key={item.id}
                />
            ))}
        </EuiHeaderLinks>
    )
}

export default HeaderNavMenu
