import {
    EuiBadge,
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiPanel,
    EuiSideNav,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui'
import { useEffect, useMemo, useState } from 'react'

type ChangelogProps = {
    isOpen: boolean
    onClose: () => void
}

type ChangeLogVersion = {
    version: string
    date: string
    content: JSX.Element | JSX.Element[]
}

const Changelog = (props: ChangelogProps) => {
    const [selectedLog, setSelectedLog] = useState<string>('')

    const changelogs: ChangeLogVersion[] = []

    const menuItems = useMemo(() => {
        return changelogs.map((log) => ({
            name: log.version,
            id: log.version,
            onClick: () => setSelectedLog(log.version),
        }))
    }, [changelogs])

    const selectedVersion = useMemo(() => {
        return changelogs.find((log) => log.version === selectedLog)
    }, [changelogs, selectedLog])

    useEffect(() => {
        if (selectedLog.length === 0) {
            setSelectedLog(changelogs[0]?.version ?? '')
        }
    }, [changelogs])

    return props.isOpen ? (
        <EuiModal
            onClose={props.onClose}
            style={{ width: 800 }}
            maxWidth={'90%'}
        >
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Change log</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                        <EuiSideNav
                            mobileTitle="Nav Items"
                            items={[
                                {
                                    name: 'Versions',
                                    id: 'versions-item',
                                    items: menuItems,
                                },
                            ]}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiPanel color="subdued">
                            <EuiPageHeader bottomBorder>
                                <EuiPageHeaderSection>
                                    <EuiTitle size="s">
                                        <h2>{selectedVersion?.version}</h2>
                                    </EuiTitle>
                                </EuiPageHeaderSection>
                                <EuiPageHeaderSection>
                                    <EuiBadge>{selectedVersion?.date}</EuiBadge>
                                </EuiPageHeaderSection>
                            </EuiPageHeader>
                            <EuiSpacer />
                            {selectedVersion?.content}
                        </EuiPanel>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButton onClick={props.onClose} fill>
                    Close
                </EuiButton>
            </EuiModalFooter>
        </EuiModal>
    ) : null
}

export default Changelog
