import { DetailsPage, LoadingSpinner } from '@components/layout'
import { EuiCodeBlock, EuiLoadingSpinner, EuiText } from '@elastic/eui'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getAppConfig } from '@utils/globals'

const scriptPath = `${process.env.PUBLIC_URL}/agent-install.ps1`

const AgentInstallPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { t } = useTranslation(['common', 'agents'])
    const [scriptContent, setScriptContent] = useState('')

    const script = useMemo(
        () =>
            scriptContent
                .replaceAll('{{url}}', getAppConfig().apiUrl ?? '')
                .replaceAll('{{version}}', getAppConfig().workerVersion ?? '')
                .replaceAll('{{agentId}}', id ?? '')
                .replace(/[\r\n]/gm, '')
                .replace(/\s+/g, ' ')
                .trim(),
        [scriptContent, id]
    )

    useEffect(() => {
        fetch(scriptPath)
            .then((res) => res.text())
            .then(setScriptContent)
            .catch(console.error)
    }, [])

    return (
        <DetailsPage
            onClose={() => navigate('..')}
            size="s"
            title={t('agents:install_title')}
            onCancel={() => navigate('..')}
        >
            <EuiText style={{ marginBottom: 20 }}>
                {/* Custom trans to interpolate translation with HTML markup 
                (info: https://react.i18next.com/latest/trans-component) */}
                <Trans i18nKey="agents:install_win_description">
                    To install the agent (service) on a Windows target-computer.
                    Please, run the script below from an
                    <strong>Administrator</strong> PowerShell command prompt.
                </Trans>
            </EuiText>

            <LoadingSpinner
                loading={!scriptContent || scriptContent.length == 0}
            >
                <EuiCodeBlock language="powershell" isCopyable={true}>
                    {script}
                </EuiCodeBlock>
            </LoadingSpinner>
        </DetailsPage>
    )
}

export default AgentInstallPage
