import { CACHE_TAG_TYPES, globalBaseQuery } from './helpers'
import { LoginRequest, RefreshTokenRequest, TokenModel } from '@services/auth'

import { AppInfo } from '.'
import { createApi } from '@reduxjs/toolkit/query/react'

export const appApi = createApi({
    baseQuery: globalBaseQuery,
    tagTypes: CACHE_TAG_TYPES,
    endpoints: (builder) => ({
        fetchAppInfo: builder.query<AppInfo, any>({
            providesTags: ['AppInfo'],
            query: () => 'application',
        }),

        // Account endpoints
        login: builder.mutation<TokenModel, LoginRequest>({
            query: (req) => ({
                url: 'account/token',
                method: 'POST',
                body: req,
            }),
        }),
        refreshToken: builder.mutation<TokenModel, RefreshTokenRequest>({
            query: (req) => ({
                url: 'account/refresh-token',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useFetchAppInfoQuery,
    useLoginMutation,
    useRefreshTokenMutation,
} = appApi
