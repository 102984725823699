import { EuiConfirmModal, EuiSpacer, EuiText } from '@elastic/eui'
import { useNavigate, useParams } from 'react-router-dom'

import { SelectionBox } from '@components/form'
import { getNumberOrDefault } from '@utils/numbers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTroubleshootSensorMutation } from '@services/api'

const DefaultInterval = '10'

const SensorTroubleshootingConfigurator = () => {
    const { t } = useTranslation(['common', 'sensors'])
    const { id } = useParams()

    const IntervalOptions = [
        {
            value: '1',
            label: `1 ${t('sensors:interval_suffix_second')}`,
        },
        {
            value: '5',
            label: `5 ${t('sensors:interval_suffix_seconds')}`,
        },
        {
            value: '10',
            label: `10 ${t('sensors:interval_suffix_seconds')}`,
        },
        {
            value: '15',
            label: `15 ${t('sensors:interval_suffix_seconds')}`,
        },
        {
            value: '20',
            label: `20 ${t('sensors:interval_suffix_seconds')}`,
        },
        {
            value: '25',
            label: `25 ${t('sensors:interval_suffix_seconds')}`,
        },
    ]

    const navigate = useNavigate()

    // Mutations
    const [startTroubleshootingSensor] = useTroubleshootSensorMutation()

    // States
    const [selectedInterval, setSelectedInterval] =
        useState<string>(DefaultInterval)

    const onCancel = () => {
        navigate('/network')
    }

    const onConfirm = async () => {
        const parsedId = getNumberOrDefault(id, 0)
        if (parsedId) {
            await startTroubleshootingSensor({
                sensorId: parsedId,
                intervalInSeconds: selectedInterval,
            })
        }
        onCancel()
    }

    return (
        <EuiConfirmModal
            title="Sensor troubleshooting"
            onCancel={onCancel}
            onConfirm={onConfirm}
            cancelButtonText={t('common:cancel')}
            confirmButtonText={t('sensors:troubleshoot_sensor_start')}
            buttonColor="danger"
            defaultFocusedButton="confirm"
        >
            <>
                <EuiText>
                    <p>{t('sensors:troubleshoot_interval_description')}</p>
                </EuiText>
                <EuiSpacer />
                <SelectionBox
                    fullWidth
                    value={selectedInterval}
                    onChange={(value) => setSelectedInterval(value[0]?.value)}
                    options={IntervalOptions}
                />
            </>
        </EuiConfirmModal>
    )
}

export default SensorTroubleshootingConfigurator
