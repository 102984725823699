import {
    EuiFieldPassword,
    EuiFieldText,
    EuiFormRow,
    EuiTextArea,
} from '@elastic/eui'

import { Option } from '@services/options'
import { isTimeFormat } from '@utils/dates'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type OptionTextFieldProps = {
    option: Option
    helpText?: JSX.Element | undefined
    inputType?: 'text' | 'number' | 'url' | 'time-string' | 'code'
    append?: JSX.Element
    placeholder?: string
    onChange: (option: Option) => void
    isDisabled?: boolean
}

const OptionTextField = ({
    option,
    helpText,
    inputType,
    append,
    placeholder,
    onChange,
    isDisabled,
}: OptionTextFieldProps) => {
    const { t } = useTranslation(['options'])
    const [error, setError] = useState<string | undefined>(undefined)

    const label = `${option.key.toLowerCase()}`

    const handleChange = (inputValue: string) => {
        onChange({ ...option, value: inputValue })

        if (
            inputValue.length > 0 &&
            inputType === 'time-string' &&
            !isTimeFormat(inputValue)
        ) {
            setError(t('options:invalid_time_format'))
            return
        }

        // At this point, the input is valid
        if (error) {
            setError(undefined)
        }
    }

    return (
        <EuiFormRow
            label={t(label)}
            isDisabled={isDisabled || option.isInherited}
            helpText={helpText}
            isInvalid={error !== undefined}
            error={error}
            fullWidth={true}
        >
            {inputType === 'code' ? (
                <EuiTextArea
                    isInvalid={error !== undefined}
                    name={option.key}
                    placeholder={placeholder ?? t(label)}
                    disabled={
                        isDisabled ||
                        option.isInherited ||
                        option.isEnabled === false
                    }
                    compressed
                    fullWidth={true}
                    value={(option.value as string) ?? ''}
                    onChange={(e) => handleChange(e.target.value)}
                />
            ) : (
                <>
                    {!option.isSecure && (
                        <EuiFieldText
                            isInvalid={error !== undefined}
                            name={option.key}
                            placeholder={placeholder ?? t(label)}
                            disabled={
                                isDisabled ||
                                option.isInherited ||
                                option.isEnabled === false
                            }
                            type={inputType}
                            compressed
                            fullWidth={true}
                            value={(option.value as string) ?? ''}
                            append={append}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                    )}
                    {option.isSecure && (
                        <EuiFieldPassword
                            isInvalid={error !== undefined}
                            name={option.key}
                            type="password"
                            placeholder={placeholder ?? t(label)}
                            autoComplete="new-password"
                            compressed
                            disabled={
                                isDisabled ||
                                option.isInherited ||
                                option.isEnabled === false
                            }
                            fullWidth={true}
                            value={(option.value as string) ?? ''}
                            append={append}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                    )}
                </>
            )}
        </EuiFormRow>
    )
}
export default OptionTextField
