import {
    DataPoint,
    SensorDataRequest,
    encodeUrlDataQuery,
} from '@services/data'
import {
    NetworkCollection,
    TreeState,
    encodeUrlTreeState,
} from '@services/network'

import { Agent } from '@services/agents'
import { Group } from '@services/groups'
import { appApi } from '../app.api'

const networkApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchNetworkAgents: builder.query<NetworkCollection<Agent>, TreeState>({
            providesTags: ['AgentNetworkTree'],
            query: (q) => `/network/agents?${encodeUrlTreeState(q)}`,
        }),
        fetchNetworkGroups: builder.query<NetworkCollection<Group>, TreeState>({
            providesTags: ['GroupNetworkTree'],
            query: (q) => `/network/groups?${encodeUrlTreeState(q)}`,
        }),
        fetchNetworkSensorData: builder.query<DataPoint[], SensorDataRequest>({
            query: (q) =>
                `/sensors/${q.sensor}/data?${encodeUrlDataQuery(q.query)}`,
        }),
        fetchNetworkSensorSnapshot: builder.query<DataPoint, number>({
            query: (sensorId) => `/sensors/${sensorId}/snapshot`,
        }),
    }),
})

export const {
    useFetchNetworkAgentsQuery,
    useFetchNetworkGroupsQuery,
    useFetchNetworkSensorDataQuery,
    useFetchNetworkSensorSnapshotQuery,
} = networkApi
