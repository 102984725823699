import { ApiKey, CreateApiKeyRequest } from '@services/auth'
import { CollectionResult, Query } from '../interfaces'

import { appApi } from '../app.api'

const apiKeysApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchApiKeys: builder.query<CollectionResult<ApiKey>, Query>({
            providesTags: ['ApiKey'],
            query: (q) => ({
                url: 'apiKeys',
                method: 'GET',
                params: q,
            }),
        }),
        createApikey: builder.mutation<ApiKey, CreateApiKeyRequest>({
            query: (apiKey) => ({
                url: 'apiKeys',
                method: 'POST',
                body: apiKey,
            }),
            invalidatesTags: ['ApiKey'],
        }),
        deleteApiKey: builder.mutation<void, string>({
            query: (id) => ({
                url: `apiKeys/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ApiKey'],
        }),
    }),
})

export const {
    useFetchApiKeysQuery,
    useCreateApikeyMutation,
    useDeleteApiKeyMutation,
} = apiKeysApi
