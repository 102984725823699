import { SelectionBoxField, SwitchField, TextField } from '@components/form'
import { boolean, object, string } from 'yup'
import {
    useCreateAgentMutation,
    useFetchTenantsQuery,
    useFindAgentQuery,
    useUpdateAgentMutation,
} from '@services/api'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { DetailsAgentRequest } from '@services/agents'
import { DetailsPage } from '@components/layout'
import { EuiForm } from '@elastic/eui'
import { getNumberOrDefault } from '@utils/numbers'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const agentSchema = object({
    name: string().required(),
    isEnabled: boolean().required(),
    tenantId: string().required(),
})

const AgentDetailsPage = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams([])

    // eslint-disable-next-line prefer-const
    let { id } = useParams()
    const [skip, setSkip] = useState(true)
    const { data, isLoading } = useFindAgentQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip }
    )
    const { data: tenants } = useFetchTenantsQuery({})
    const [createAgent, { isLoading: isCreating }] = useCreateAgentMutation()
    const [updateAgent, { isLoading: isUpdating }] = useUpdateAgentMutation()
    const { t } = useTranslation(['common', 'agents'])

    const onSubmit = async (record: DetailsAgentRequest) => {
        try {
            if (data && id) {
                await updateAgent({ ...record, id: +id })
            } else {
                await createAgent(record)
            }

            if (searchParams.get('referral') == 'network') navigate('/network')
            else navigate('/agents')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik({
        initialValues: {
            name: '',
            isEnabled: true,
            tenantId: '',
        },
        validationSchema: agentSchema,
        onSubmit,
    })

    useEffect(() => {
        if (id && !isNaN(+id)) {
            setSkip(false)
        }
    }, [])

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    return (
        <DetailsPage
            onClose={() => navigate('..')}
            size="s"
            title={data ? t('agents:edit_title') : t('agents:create_title')}
            loading={isLoading || isCreating || isUpdating}
            submitLoading={isLoading || isCreating || isUpdating}
            onSave={form.handleSubmit}
            onCancel={() => navigate('..')}
        >
            <EuiForm component="form" onSubmit={form.handleSubmit}>
                <TextField
                    form={form}
                    name="name"
                    fullWidth
                    label={t('agents:name')}
                    placeholder={t('agents:name')}
                />
                <SelectionBoxField
                    form={form}
                    name="tenantId"
                    label={t('agents:tenant')}
                    fullWidth
                    isClearable={false}
                    value={form.values.tenantId}
                    onChange={(value) =>
                        form.setFieldValue('tenantId', value[0]?.value)
                    }
                    options={
                        tenants?.items?.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })) ?? []
                    }
                />
                <SwitchField
                    form={form}
                    name="isEnabled"
                    label={t('agents:is_enabled')}
                    value={form.values.isEnabled}
                    onChange={(value) => form.setFieldValue('isEnabled', value)}
                />
            </EuiForm>
        </DetailsPage>
    )
}

export default AgentDetailsPage
