import {
    BaseQueryFn,
    EndpointBuilder,
    FetchArgs,
    FetchBaseQueryError,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query'
import { CollectionResult, Query } from './interfaces'
import { logout, storeToken } from '@store/auth'

import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import { Mutex } from 'async-mutex'
import { TokenModel } from '@services/auth'
import { getAppConfig } from '@utils/globals'
import { setMainNavigate } from '@store/main'

export const QueryFilterOperator = Object.freeze({
    Equals: '==',
    NotEquals: '!=',
    Contains: '@=',
    NotContains: '!@=',
    StartsWith: '_=',
    NotStartsWith: '!_=',
    EndsWith: '_-=',
    NotEndsWith: '!_-=',
    GreaterThan: '>',
    GreaterThanOrEqual: '>=',
    LessThan: '<',
    LessThanOrEqual: '<=',
})

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
    baseUrl: getAppConfig().apiUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as any).auth.user?.token
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    },
})

export const globalBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                if (await handleRefreshToken(args, api, extraOptions)) {
                    result = await baseQuery(args, api, extraOptions)
                }
            } finally {
                release()
            }
        } else {
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }

    return result
}

export const buildQueryParams = (
    query: Query,
    includes: string[] = [],
    extras: any = null
) => {
    const params = new URLSearchParams()

    if (!query) return params

    if (query.page) {
        params.set('page', query.page.toString())
    }

    if (query.pageSize) {
        params.set('pageSize', query.pageSize.toString())
    }

    if (query.sorts) {
        params.set('sorts', query.sorts.join(','))
    }

    if (includes && includes.length > 0) {
        includes.forEach((include, index) => {
            params.set(`includes[${index}]`, include)
        })
    }

    if (query.filters && query.filters.length > 0) {
        params.set('filters', query.filters.join(','))
    }

    if (extras) {
        Object.keys(extras).forEach((key) => {
            params.set(key, extras[key])
        })
    }

    return params
}

/**
 * Build a search query.
 * @param search Search phrase to be used
 * @param fields Fields to search on
 * @param operator Operator to use in the search request (default: Contains)
 * @param sortBy Sort by field and direction
 * @returns Search query that can be used in the API request
 */
export const buildSearchQuery = (
    search: string,
    fields: string[],
    operator: keyof typeof QueryFilterOperator = 'Contains',
    sortBy:
        | { field: string; direction: 'asc' | 'desc' }
        | undefined = undefined,
    page: number = 1,
    pageSize: number = 10
): Query => {
    const searchField = fields.length > 1 ? `(${fields.join('|')})` : fields[0]
    const sort = sortBy
        ? `${sortBy.direction === 'desc' ? '-' : ''}${sortBy.field}`
        : undefined

    return {
        filters:
            searchField && search
                ? [`${searchField}${QueryFilterOperator[operator]}${search}`]
                : undefined,
        sorts: sort ? [sort] : undefined,
        page,
        pageSize,
    }
}

export const objectToQueryParams = (
    obj: Record<string, any>
): URLSearchParams => {
    const params = new URLSearchParams()

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key]
            // Skip undefined and null values
            if (value !== undefined && value !== null) {
                if (Array.isArray(value)) {
                    // Handle arrays by adding multiple key=value pairs
                    value.forEach((item) => {
                        if (item !== undefined && item !== null)
                            params.append(key, item)
                    })
                } else {
                    if (value !== undefined && value !== null)
                        params.append(key, value)
                }
            }
        }
    }

    return params
}

export const mergeURLSearchParams = (
    params1: URLSearchParams,
    params2: URLSearchParams
): URLSearchParams => {
    const mergedParams = new URLSearchParams()

    // Append all entries from params1 to mergedParams
    params1.forEach((value, key) => {
        mergedParams.append(key, value)
    })

    // Append all entries from params2 to mergedParams
    params2.forEach((value, key) => {
        mergedParams.append(key, value)
    })

    return mergedParams
}

/**
 * Build a search query with type.
 * @param search Search phrase to be used
 * @param fields Fields to search on
 * @param operator Operator to use in the search request (default: Contains)
 * @param sortBy Sort by field and direction
 * @returns Search query that can be used in the API request
 */
export const buildSearchQueryWithType = <T>(
    search: string,
    fields: (keyof T)[],
    operator: keyof typeof QueryFilterOperator = 'Contains',
    sortBy:
        | { field: keyof T; direction: 'asc' | 'desc' }
        | undefined = undefined,
    page: number = 1,
    pageSize: number = 10
): Query =>
    buildSearchQuery(
        search,
        fields as string[],
        operator,
        sortBy as { field: string; direction: 'asc' | 'desc' },
        page,
        pageSize
    )

/**
 * Default page size options.
 * @type {number[]}
 */
export const DEFAULT_PAGE_SIZE_OPTS: number[] = [25, 50, 100]
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTS[1] // 50 rows per page

export const getDefaultQuery = (
    sortField?: string,
    sortDir?: 'asc' | 'desc'
): Query => ({
    pageSize: DEFAULT_PAGE_SIZE,
    sorts: sortField ? [`${sortDir === 'desc' ? '-' : ''}${sortField}`] : [],
})

/**
 * Handles refreshing the token.
 * @returns boolean indicating if the token was refreshed.
 */
const handleRefreshToken = async (args: any, api: any, extraOptions: any) => {
    const user = (api.getState() as any)['auth']?.user
    if (user) {
        const newToken = await baseQuery(
            {
                url: 'account/refresh-token',
                method: 'POST',
                body: {
                    token: user.token,
                    refreshToken: user.refreshToken,
                },
            },
            api,
            extraOptions
        )
        if (newToken.data) {
            api.dispatch(storeToken(newToken.data as TokenModel))
            return true
        }
    }

    api.dispatch(logout())
    api.dispatch(setMainNavigate('/auth/login'))
    return false
}

export const CACHE_TAG_TYPES = [
    'Tenant',
    'Agent',
    'Device',
    'Group',
    'Sensor',
    'User',
    'Role',
    'NetworkGroup',
    'Option',
    'Alarm',
    'AppInfo',
]

export const provideCacheTag = <T>(
    result: T | CollectionResult<T>,
    type: (typeof CACHE_TAG_TYPES)[number]
) => {
    // check if the result is a collection
    if (result && (result as CollectionResult<T>).items) {
        return (result as CollectionResult<T>).items.map((item) => ({
            type,
            id: (item as any).id,
        }))
    }

    // otherwise, it's a single item
    if (result) {
        return [{ type, id: (result as any).id }]
    }

    return [type]
}

export const invalidateCacheTag = (
    type: (typeof CACHE_TAG_TYPES)[number],
    id?: any | any[]
): any[] => {
    if (id && !Array.isArray(id)) {
        return [{ type, id }]
    }

    if (id && Array.isArray(id)) {
        return id.map((i) => ({ type, id: i }))
    }

    return [type]
}

/**
 * Creates a stream query that listens to a stream of data from the server.
 * @param builder Query builder to use
 * @param url URL to stream data from. Can be a string or a function that returns a string based on the request args.
 * @param onMessage Function to call when a message is received from the server. This function should return the new state of the data.
 */
export const streamQuery = <TResult, TRequest>(
    builder: EndpointBuilder<any, any, any>,
    url: string | ((arg: TRequest) => string),
    onMessage: (item: TResult, state: MaybeDrafted<TResult[]>) => TResult[]
) => {
    return builder.query<TResult[], TRequest>({
        queryFn: () => ({}) as any,
        onCacheEntryAdded: async (
            arg,
            { getState, updateCachedData, cacheEntryRemoved }
        ) => {
            try {
                // if url function is provided, call it to get the url
                if (typeof url === 'function') {
                    url = url(arg)
                }

                const state = getState()
                const token = (state.auth as any).user?.token

                const response = await fetch(`${getAppConfig().apiUrl}${url}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                const reader = response.body?.getReader()
                if (!reader) return

                const decoder = new TextDecoder()

                // eslint-disable-next-line no-constant-condition
                while (true) {
                    const { done, value } = await reader.read()
                    if (done) break

                    const text = decoder
                        .decode(value)
                        .replace(/\[|]/g, '')
                        .replace(/^,/, '')

                    if (!text) continue

                    const message = JSON.parse(text)

                    updateCachedData((data) => onMessage(message, data || []))
                }

                reader.releaseLock()
            } catch (error) {
                console.error(error)
            }

            await cacheEntryRemoved
        },
    })
}
