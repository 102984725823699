import { ApiKey, CreateApiKeyRequest } from '@services/auth'
import {
    DEFAULT_DATE_CULTURE,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_TIME_FORMAT,
} from '@utils/dates'
import {
    EuiButton,
    EuiCodeBlock,
    EuiDatePicker,
    EuiForm,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiSpacer,
    EuiText,
} from '@elastic/eui'
import { SelectionBoxField, TextField } from '@components/form'
import { date, object, string } from 'yup'
import { useCreateApikeyMutation, useFetchRolesQuery } from '@services/api'
import { useMemo, useState } from 'react'

import { DetailsPage } from '@components/layout'
import { getRolesOptions } from '@services/roles'
import moment from 'moment'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const validationSchema = object({
    name: string().required(),
    description: string().optional(),
    expiredAt: date().optional(),
    roleId: string().optional(),
})

const ApiKeyDetailsPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(['common', 'apiKeys'])
    const [result, setResult] = useState<ApiKey | undefined>()
    const [createApiKey, { isLoading }] = useCreateApikeyMutation()
    const { data: roles } = useFetchRolesQuery({})

    const form = useFormik<CreateApiKeyRequest>({
        initialValues: {
            name: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const newApiKey = await createApiKey(values).unwrap()
                setResult(newApiKey)
            } catch (error) {
                console.error(error)
            }
        },
    })

    const selected = useMemo(() => {
        if (form.values.expiredAt) {
            return moment(form.values.expiredAt)
        }

        return null
    }, [form.values.expiredAt])

    const startDate = useMemo(() => moment(), [])

    return (
        <>
            {result && (
                <EuiModal
                    onClose={() => console.log('close')}
                    style={{ maxWidth: 500 }}
                >
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            {t('apiKeys:created_successfully_title')}
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiText>
                            {t('apiKeys:created_successfully_text')}
                        </EuiText>
                        <EuiSpacer />
                        <EuiCodeBlock isCopyable>{result.token}</EuiCodeBlock>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton fill onClick={() => navigate('..')}>
                            {t('common:close')}
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            )}
            <DetailsPage
                onClose={() => navigate('..')}
                onCancel={() => navigate('..')}
                size="s"
                title={t('apiKeys:new')}
                loading={isLoading}
                onSave={form.handleSubmit}
            >
                <EuiForm component="form" onSubmit={form.handleSubmit}>
                    <TextField
                        form={form}
                        name="name"
                        fullWidth
                        label={t('apiKeys:name')}
                    />
                    <TextField
                        form={form}
                        name="description"
                        fullWidth
                        textarea
                        label={t('apiKeys:description')}
                    />
                    <SelectionBoxField
                        form={form}
                        name="roleId"
                        label={t('apiKey:role')}
                        fullWidth
                        isClearable={false}
                        helpText={t('apiKeys:role_help')}
                        value={form.values.roleId}
                        onChange={(opt) => {
                            form.setFieldValue('roleId', opt[0].value)
                        }}
                        options={
                            roles?.items?.map((item) => ({
                                label: item.name,
                                value: item.id,
                            })) ?? []
                        }
                    />
                    <EuiFormRow label={t('apiKeys:expires_at')} fullWidth>
                        <EuiDatePicker
                            showTimeSelect
                            fullWidth
                            dateFormat={DEFAULT_DATE_TIME_FORMAT}
                            timeFormat={DEFAULT_TIME_FORMAT}
                            selected={selected}
                            startDate={startDate}
                            minDate={startDate}
                            onChange={(date) =>
                                form.setFieldValue('expiredAt', date?.toDate())
                            }
                        />
                    </EuiFormRow>
                </EuiForm>
            </DetailsPage>
        </>
    )
}

export default ApiKeyDetailsPage
