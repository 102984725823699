import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'
import { useEffect, useMemo, useState } from 'react'

import { formatAsPercentage } from '@utils/numbers'
import { useTranslation } from 'react-i18next'

type SnmpProcessorChartProps = ChartProps

const SnmpProcessorChart = (props: SnmpProcessorChartProps) => {
    const [lines, setLines] = useState<ChartLine[]>([])
    const { t } = useTranslation(['network'])
    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const snapshot = (props.data as any[]).find(
                (d) => d.snmpProcessorNumberOfCores
            )

            const numOfLines = +(snapshot?.snmpProcessorNumberOfCores || 0)

            setLines([
                ...Array.from({ length: numOfLines }, (_, i) => ({
                    key: `snmpProcessorCoreLoad_${i}`,
                    label: `${t('network:data_key_snmp_processor_core')} Cpu ${i}`,
                })),
                {
                    key: 'snmpProcessorLoad',
                    label: t('network:data_key_snmp_processor_load'),
                },
            ])
        }
    }, [props.data])

    return (
        <NetworkLineChart
            data={props.data}
            query={props.query}
            setQuery={props.setQuery}
            lines={lines}
            xAxis="timestamp"
            xAxisFormatter={(v) => formatTimestamp(v, props.query)}
            yAxisFormatter={(v) => formatAsPercentage(+v)}
            xAxisDomain={xAxisDomain}
            yAxisDomain={[0, 100]} // Percentage
        />
    )
}

export default SnmpProcessorChart
