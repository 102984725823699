import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiIcon,
    EuiMarkdownEditor,
    EuiTextArea,
    EuiToolTip,
    getDefaultEuiMarkdownPlugins,
} from '@elastic/eui'

import { Option } from '@services/options'
import { useTranslation } from 'react-i18next'

type NotificationPushOptionFieldProps = {
    option: Option
    helpText?: JSX.Element | undefined
    extraInfo?: { title?: string; content?: string | JSX.Element } | undefined
    html?: boolean
    onChange: (option: Option) => void
    isDisabled?: boolean
}

const { parsingPlugins, processingPlugins, uiPlugins } =
    getDefaultEuiMarkdownPlugins({ exclude: ['tooltip'] })

const NotificationTemplateOptionField = (
    props: NotificationPushOptionFieldProps
) => {
    const { t } = useTranslation(['options'])

    const handleOnChange = (value: string) => {
        props.onChange({ ...props.option, value: value })
    }

    return (
        <EuiFormRow
            isDisabled={props.isDisabled || props.option.isInherited}
            label={
                <EuiFlexGroup alignItems="center" gutterSize="xs">
                    <EuiFlexItem>
                        {t(props.option.key.toLowerCase())}
                    </EuiFlexItem>
                    {props.extraInfo && (
                        <EuiFlexItem grow={false}>
                            <EuiToolTip
                                content={
                                    <div>
                                        {props.extraInfo.title && (
                                            <p>
                                                {t(
                                                    `alarms:${props.extraInfo.title}`
                                                )}
                                            </p>
                                        )}
                                        {props.extraInfo.content}
                                    </div>
                                }
                            >
                                <EuiIcon type="iInCircle" />
                            </EuiToolTip>
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            }
            fullWidth
            helpText={props.helpText}
        >
            {props.html ? (
                <EuiMarkdownEditor
                    aria-label={props.option.key}
                    readOnly={props.option.isInherited}
                    initialViewMode={
                        props.option.isInherited ? 'viewing' : 'editing'
                    }
                    parsingPluginList={parsingPlugins}
                    processingPluginList={processingPlugins}
                    uiPlugins={uiPlugins}
                    value={(props.option?.value as string) ?? ''}
                    onChange={handleOnChange}
                />
            ) : (
                <EuiTextArea
                    aria-label={props.option.key}
                    value={(props.option?.value as string) ?? ''}
                    fullWidth
                    onChange={(e) => handleOnChange(e.target.value)}
                />
            )}
        </EuiFormRow>
    )
}
export default NotificationTemplateOptionField
