import {
    EuiBadge,
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiFieldSearch,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiText,
} from '@elastic/eui'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useMemo, useState } from 'react'

import { Category } from '@services/categories'
import { Permissions } from '@services/auth'
import { SelectionBox } from '@components/form'
import { TreeState } from '@services/network'
import { setTreeCategories } from '@store/network'
import { useFetchCategoriesQuery } from '@services/api'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

type NetworkTreeFiltersProps = {
    treeState?: TreeState
    onSearch: (value: string) => void
    onCategoryChange: (categories: Category[]) => void
}

const mapToOptions = (categories?: Category[]) =>
    categories?.map((i) => ({
        label: i.name,
        value: i.id,
    })) ?? []

const NetworkTreeFilters = ({
    treeState,
    onSearch,
    onCategoryChange,
}: NetworkTreeFiltersProps) => {
    const { t } = useTranslation(['common', 'network'])
    const { categoryFilter } = useAppSelector((state) => state.network)
    const dispatch = useAppDispatch()

    const canViewCategory = useHasPermission(Permissions.category.canView)

    // Fetch
    const { data, isLoading } = useFetchCategoriesQuery({}, { skip: true })

    // States
    const [isFilterVisible, setIsFilterVisible] = useState(
        categoryFilter?.isActive ?? false
    )
    const [categories, setCategories] = useState<Category[]>(
        categoryFilter?.categories ?? []
    )

    const options = useMemo(() => mapToOptions(data?.items), [data])

    const handleRemoveCategory = (category: Category) => {
        const cats = categories.filter((i) => i !== category)
        setCategories(cats)
        onCategoryChange(cats)
        dispatch(
            setTreeCategories({
                isActive: isFilterVisible,
                categories: cats ?? [],
            })
        )
    }

    const handleOnCategoryChange = (value: any[]) => {
        const cats = data?.items?.filter((c) =>
            value.some((v) => v.value === c.id)
        )
        setCategories(cats ?? [])
        onCategoryChange(cats ?? [])
        dispatch(
            setTreeCategories({
                isActive: isFilterVisible,
                categories: cats ?? [],
            })
        )
    }

    const handleOnHide = () => {
        setIsFilterVisible(false)
        dispatch(
            setTreeCategories({
                categories,
                isActive: false,
            })
        )
    }

    const handleOnClear = () => {
        dispatch(setTreeCategories(undefined))
        setIsFilterVisible(false)
        setCategories([])
        onCategoryChange([])
    }

    return (
        <>
            <EuiFieldSearch
                placeholder={t('common:search_tree')}
                compressed
                fullWidth
                incremental
                onSearch={onSearch}
                isClearable={true}
                defaultValue={treeState?.search}
            />
            {isFilterVisible && (
                <EuiPanel color="accent" paddingSize="xs" hasShadow={false}>
                    <EuiFlexGroup gutterSize="xs" alignItems="center">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                iconType="eyeClosed"
                                title={t('network:hide_filter')}
                                size="xs"
                                onClick={handleOnHide}
                            />
                            <EuiButtonIcon
                                iconType="cross"
                                title={t('network:clear_filter')}
                                size="xs"
                                onClick={handleOnClear}
                            />
                        </EuiFlexItem>
                        {canViewCategory && (
                            <EuiFlexItem>
                                <SelectionBox
                                    multiple
                                    fullWidth
                                    compressed
                                    isClearable
                                    loading={isLoading}
                                    value={mapToOptions(categories)}
                                    options={options}
                                    onChange={handleOnCategoryChange}
                                    placeholder={t(
                                        'network:filter_by_categories'
                                    )}
                                />
                            </EuiFlexItem>
                        )}
                    </EuiFlexGroup>
                </EuiPanel>
            )}

            {canViewCategory && !isFilterVisible && categories.length > 0 && (
                <>
                    <EuiSpacer size="xs" />
                    {categories.map((c) => (
                        <EuiBadge
                            key={c.id}
                            onClick={() => setIsFilterVisible(true)}
                            onClickAriaLabel="Change category"
                            iconOnClickAriaLabel="Remove category"
                            iconType="cross"
                            iconOnClick={() => handleRemoveCategory(c)}
                        >
                            {c.name}
                        </EuiBadge>
                    ))}
                </>
            )}

            {canViewCategory && !isFilterVisible && categories.length === 0 && (
                <EuiButtonEmpty
                    onClick={() => setIsFilterVisible(!isFilterVisible)}
                    size="xs"
                    iconSide="left"
                    iconType="filter"
                >
                    <EuiText size="xs">
                        {t('network:filter_by_categories')}
                    </EuiText>
                </EuiButtonEmpty>
            )}
        </>
    )
}

export default NetworkTreeFilters
