import {
    AlarmAllStatus,
    AlarmTypes,
    AlarmsFiltersValues,
} from '@services/alarms'
import { CheckboxField, SelectionBoxField, TextField } from '@components/form'
import {
    EuiButton,
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiDatePicker,
    EuiDatePickerRange,
    EuiForm,
    EuiFormRow,
} from '@elastic/eui'
import { boolean, date, object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useEffect, useRef, useState } from 'react'

import { DEFAULT_DATE_CULTURE } from '@utils/dates'
import { DetailsPage } from '@components/layout'
import { Moment } from 'moment'
import { Permissions } from '@services/auth'
import { setAlarmsFilters } from '@store/alarms'
import { useFetchCategoriesQuery } from '@services/api'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const validationSchema = object({
    sensor: string().nullable().optional(),
    device: string().nullable().optional(),
    agent: string().nullable().optional(),
    isOk: boolean().nullable().optional(),
    isAcknowledged: boolean().nullable().optional(),
    startDate: date().nullable().optional(),
    endDate: date().nullable().optional(),
})

const AlarmsFilters = () => {
    const { prefrences, filters } = useAppSelector((state) => state.alarms)
    const { t } = useTranslation(['common', 'alarms'])
    const [isOpen, setIsOpen] = useState(false)
    const startDateRef = useRef<any>(null)
    const endDateRef = useRef<any>(null)
    const dispatch = useAppDispatch()

    const canViewCategories = useHasPermission(Permissions.category.canView)

    // Queries
    const { data: categories } = useFetchCategoriesQuery(
        {},
        { skip: !canViewCategories }
    )

    const onSubmit = (record: AlarmsFiltersValues) => {
        dispatch(setAlarmsFilters(record))
        setIsOpen(false)
    }

    const form = useFormik<AlarmsFiltersValues>({
        initialValues: filters || {},
        validationSchema,
        onSubmit,
    })

    const handleDateChange = (key: 'startDate' | 'endDate', date: Moment) => {
        form.setFieldValue(key, date)

        if (key === 'startDate') {
            endDateRef.current?.setOpen(true)
            startDateRef.current?.setOpen(false)
        }
    }

    useEffect(() => {
        form.setValues(filters || {})
    }, [filters])

    return (
        <>
            {prefrences?.iconsOnly ? (
                <EuiButtonIcon
                    iconType="filter"
                    color={isOpen ? 'primary' : 'text'}
                    size="s"
                    aria-label={t('alarms:filters')}
                    title={t('alarms:filters')}
                    onClick={() => setIsOpen(!isOpen)}
                />
            ) : (
                <EuiButtonEmpty
                    iconType="filter"
                    color={isOpen ? 'primary' : 'text'}
                    size="s"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {t('alarms:filters')}
                </EuiButtonEmpty>
            )}
            {isOpen && (
                <DetailsPage
                    size="s"
                    onClose={() => setIsOpen(false)}
                    title={t('alarms:filters')}
                    onCancel={() => setIsOpen(false)}
                    confirmButton={
                        <EuiButton
                            color="primary"
                            fill
                            size="s"
                            iconType="check"
                            onClick={() => form.handleSubmit()}
                        >
                            {t('common:apply')}
                        </EuiButton>
                    }
                >
                    <EuiForm component="form" onSubmit={form.handleSubmit}>
                        <SelectionBoxField
                            form={form}
                            name="statuses"
                            multiple
                            isClearable
                            fullWidth
                            label={t('alarms:status')}
                            compressed={prefrences?.compressedLayout}
                            value={form.values.statuses}
                            options={AlarmAllStatus.map((s) => ({
                                label: t(`alarms:status_${s.toLowerCase()}`),
                                value: s,
                            }))}
                            onChange={(value) =>
                                form.setFieldValue('statuses', value)
                            }
                        />

                        <SelectionBoxField
                            form={form}
                            name="types"
                            multiple
                            isClearable
                            fullWidth
                            label={t('alarms:type')}
                            compressed={prefrences?.compressedLayout}
                            value={form.values.types}
                            options={AlarmTypes.map((s) => ({
                                label: t(`alarms:type_${s}`),
                                value: s,
                            }))}
                            onChange={(value) =>
                                form.setFieldValue('types', value)
                            }
                        />

                        {canViewCategories && (
                            <SelectionBoxField
                                form={form}
                                name="categories"
                                multiple
                                isClearable
                                fullWidth
                                label={t('alarms:categories')}
                                compressed={prefrences?.compressedLayout}
                                value={form.values.categories}
                                options={
                                    categories?.items.map(({ id, name }) => ({
                                        label: name,
                                        value: id,
                                    })) || []
                                }
                                onChange={(value) =>
                                    form.setFieldValue('categories', value)
                                }
                            />
                        )}

                        <TextField
                            form={form}
                            name="sensor"
                            fullWidth
                            compressed={prefrences?.compressedLayout}
                            placeholder={t('alarms:filter_by_sensor')}
                            label={t('alarms:sensor')}
                        />

                        <TextField
                            form={form}
                            name="device"
                            fullWidth
                            compressed={prefrences?.compressedLayout}
                            placeholder={t('alarms:filter_by_device')}
                            label={t('alarms:device')}
                        />

                        <TextField
                            form={form}
                            name="agent"
                            fullWidth
                            compressed={prefrences?.compressedLayout}
                            placeholder={t('alarms:filter_by_agent')}
                            label={t('alarms:agent')}
                        />

                        <EuiFormRow label={t('alarms:created')} fullWidth>
                            <EuiDatePickerRange
                                compressed={prefrences?.compressedLayout}
                                fullWidth
                                startDateControl={
                                    <EuiDatePicker
                                        locale={DEFAULT_DATE_CULTURE}
                                        selected={form.values.startDate}
                                        startDate={form.values.startDate}
                                        endDate={form.values.endDate}
                                        inputRef={startDateRef}
                                        onChange={(date: Moment) =>
                                            handleDateChange('startDate', date)
                                        }
                                        fullWidth
                                        showTimeSelect
                                    />
                                }
                                endDateControl={
                                    <EuiDatePicker
                                        locale={DEFAULT_DATE_CULTURE}
                                        selected={form.values.endDate}
                                        startDate={form.values.startDate}
                                        endDate={form.values.endDate}
                                        inputRef={endDateRef}
                                        onChange={(date: Moment) =>
                                            handleDateChange('endDate', date)
                                        }
                                        fullWidth
                                        showTimeSelect
                                    />
                                }
                            />
                        </EuiFormRow>

                        <EuiFormRow>
                            <>
                                <CheckboxField
                                    form={form}
                                    name="isOk"
                                    label={t('alarms:solved')}
                                    value={form.values.isOk}
                                    indeterminate={
                                        form.values.isOk === undefined
                                    }
                                    compressed={prefrences?.compressedLayout}
                                    onChange={(value) =>
                                        form.setFieldValue('isOk', value)
                                    }
                                />

                                <CheckboxField
                                    form={form}
                                    name="isAcknowledged"
                                    label={t('alarms:acknowledged')}
                                    value={form.values.isAcknowledged}
                                    indeterminate={
                                        form.values.isAcknowledged === undefined
                                    }
                                    compressed={prefrences?.compressedLayout}
                                    onChange={(value) =>
                                        form.setFieldValue(
                                            'isAcknowledged',
                                            value
                                        )
                                    }
                                />
                            </>
                        </EuiFormRow>
                    </EuiForm>
                </DetailsPage>
            )}
        </>
    )
}

export default AlarmsFilters
