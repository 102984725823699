import {
    Agent,
    AgentDebugRequest,
    AgentDebugStatus,
    DetailsAgentRequest,
} from '@services/agents'
import { CollectionResult, Query } from '../interfaces'
import {
    buildQueryParams,
    invalidateCacheTag,
    provideCacheTag,
    streamQuery,
} from '../helpers'

import { LogLevel } from '@utils/globals/globals'
import { appApi } from '../app.api'
import { debug } from 'console'

const agentApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchAgents: builder.query<CollectionResult<Agent>, Query>({
            providesTags: (result) => provideCacheTag(result, 'Agent'),
            query: (q) => ({
                url: 'agents',
                method: 'GET',
                params: buildQueryParams(q, ['tenant', 'machines']),
            }),
        }),
        findAgent: builder.query<Agent, { id: number }>({
            providesTags: (result) => provideCacheTag(result, 'Agent'),
            query: (q) => ({
                url: `agents/${q.id}`,
                method: 'GET',
            }),
        }),
        agentUpdateAction: builder.mutation<
            void,
            { id: number; reinstall: boolean }
        >({
            query: (q) => ({
                url: `agents/${q.id}/update?reinstall=${q.reinstall}`,
                method: 'POST',
            }),
            invalidatesTags: ['Agent'],
        }),
        fetchAgentDebugStatus: builder.query<AgentDebugStatus, { id: number }>({
            query: (q) => ({
                url: `agents/${q.id}/debug`,
                method: 'GET',
            }),
        }),
        debugAgent: builder.mutation<void, AgentDebugRequest>({
            query: ({ agentId, logLevel, isTroubleshooting }) => ({
                url: `agents/${agentId}/debug`,
                method: 'POST',
                body: { agentId, logLevel, isTroubleshooting },
            }),
        }),
        createAgent: builder.mutation<Agent, DetailsAgentRequest>({
            query: (req) => ({
                url: 'agents',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: ['Agent'],
        }),
        updateAgent: builder.mutation<Agent, Agent>({
            query: (req) => ({
                url: `agents/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Agent'],
        }),
        deleteAgent: builder.mutation<void, number>({
            query: (id) => ({
                url: `agents/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Agent', req),
        }),
        deleteAgents: builder.mutation<void, number[]>({
            query: (ids) => ({
                url: 'agents',
                method: 'DELETE',
                body: {
                    agentIds: ids,
                },
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Agent', req),
        }),
    }),
})

export const {
    useFetchAgentsQuery,
    useFindAgentQuery,
    useFetchAgentDebugStatusQuery,
    useAgentUpdateActionMutation,
    useDebugAgentMutation,
    useCreateAgentMutation,
    useUpdateAgentMutation,
    useDeleteAgentMutation,
    useDeleteAgentsMutation,
} = agentApi
