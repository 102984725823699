import { AgentDebugRequest, getLevelColor, getLevelTag } from '@services/agents'
import {
    EuiAccordion,
    EuiBadge,
    EuiBeacon,
    EuiButtonEmpty,
    EuiCode,
    EuiCodeBlock,
    EuiDescriptionList,
    EuiDescriptionListDescription,
    EuiDescriptionListTitle,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiPanel,
    EuiSpacer,
    EuiText,
} from '@elastic/eui'
import {
    useDebugAgentMutation,
    useFetchAgentDebugStatusQuery,
} from '@services/api'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AgentDebugController } from '@components/agents'
import { DetailsPage } from '@components/layout'
import { LogLevel } from '@utils/globals/globals'
import { NoResults } from '@pages/common'
import { Permissions } from '@services/auth'
import defaultImage from '@assets/empty-prompt/illustration.svg'
import { formatFullDate } from '@utils/dates'
import { getNumberOrDefault } from '@utils/numbers'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const pollingInterval = 10000 // in ms
const AgentDebugPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const agentId = getNumberOrDefault(id, 0)
    const { t } = useTranslation(['common', 'agents'])

    const [status, setStatus] = useState<AgentDebugRequest>({
        agentId,
        logLevel: 'Information',
        isTroubleshooting: false,
    })

    const canStartDebugging = useHasPermission(
        Permissions.agent.canEnableTroubleshoot
    )

    // Queries
    const {
        data,
        refetch,
        isLoading: isStatusLoading,
    } = useFetchAgentDebugStatusQuery(
        {
            id: agentId,
        },
        {
            skip: !status,
            pollingInterval,
        }
    )

    // Mutations
    const [debugAgent, { isLoading: isDebugLoading }] = useDebugAgentMutation()

    const isLoading = isStatusLoading || isDebugLoading
    const isActive = status.isTroubleshooting

    const handeOnStart = async (level: LogLevel) => {
        const newStatus = {
            ...status,
            logLevel: level,
            isTroubleshooting: true,
        }
        setStatus(newStatus)

        try {
            await debugAgent(newStatus)
        } catch (error) {
            console.error(error)
        }
    }

    const handleOnStop = () => {
        debugAgent({
            agentId,
            logLevel: status.logLevel,
            isTroubleshooting: false,
        })
        setStatus({ ...status, isTroubleshooting: false })
    }

    useEffect(() => {
        if (!data) return

        setStatus({
            agentId,
            logLevel: data.logLevel,
            isTroubleshooting: data.isTroubleshooting,
        })
    }, [data])

    return (
        <DetailsPage
            onClose={() => navigate('..')}
            loading={isLoading}
            size="m"
            titleHasBorder={false}
            title={
                <>
                    <EuiFlexGroup
                        gutterSize="s"
                        alignItems="center"
                        justifyContent="flexEnd"
                        style={{ marginRight: 20 }}
                    >
                        <EuiFlexItem grow={false}>
                            <EuiText
                                size="s"
                                color={isActive ? 'success' : 'subdued'}
                            >
                                <h3>
                                    {isActive
                                        ? t('agents:debug_agent_active')
                                        : t('agents:debug_agent_inactive')}
                                </h3>
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem>{isActive && <EuiBeacon />}</EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <AgentDebugController
                                isActive={isActive}
                                isLoading={isDebugLoading}
                                disabled={!canStartDebugging}
                                onStop={handleOnStop}
                                onStart={handeOnStart}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                iconType="refresh"
                                size="xs"
                                isLoading={isStatusLoading}
                                onClick={() => refetch()}
                            >
                                {t('agents:debug_agent_refresh')}
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    {isActive && data && (
                        <>
                            <EuiSpacer size="m" />
                            <EuiPanel color="primary" paddingSize="s">
                                <EuiDescriptionList
                                    compressed
                                    type="inline"
                                    textStyle="reverse"
                                >
                                    <EuiDescriptionListTitle>
                                        {t('agents:debug_agent')}
                                    </EuiDescriptionListTitle>
                                    <EuiDescriptionListDescription>
                                        {data.name}
                                    </EuiDescriptionListDescription>
                                    <EuiDescriptionListTitle>
                                        {t('agents:debug_agent_version')}
                                    </EuiDescriptionListTitle>
                                    <EuiDescriptionListDescription>
                                        {data.version}
                                    </EuiDescriptionListDescription>
                                    <EuiDescriptionListTitle>
                                        {t('agents:debug_agent_hostname')}
                                    </EuiDescriptionListTitle>
                                    <EuiDescriptionListDescription>
                                        {data.hostname}
                                    </EuiDescriptionListDescription>
                                    <EuiDescriptionListTitle>
                                        {t('agents:debug_agent_started_at')}
                                    </EuiDescriptionListTitle>
                                    {data?.troubleshootingStartedAt && (
                                        <EuiDescriptionListDescription>
                                            {formatFullDate(
                                                data.troubleshootingStartedAt
                                            )}
                                        </EuiDescriptionListDescription>
                                    )}
                                    <EuiDescriptionListTitle>
                                        {t('agents:debug_agent_log_level')}
                                    </EuiDescriptionListTitle>
                                    <EuiDescriptionListDescription>
                                        <EuiBadge
                                            color={getLevelColor(data.logLevel)}
                                        >
                                            {getLevelTag(data.logLevel)}
                                        </EuiBadge>
                                    </EuiDescriptionListDescription>
                                </EuiDescriptionList>
                            </EuiPanel>
                        </>
                    )}
                </>
            }
        >
            <EuiFlexGroup direction="column" gutterSize="s">
                {data?.isTroubleshooting &&
                    data?.logs.length > 0 &&
                    data?.logs.map((l) => (
                        <>
                            <EuiFlexItem key={l.id}>
                                <EuiFlexGroup gutterSize="xs">
                                    <EuiFlexItem grow={false}>
                                        <EuiBadge
                                            color={getLevelColor(l.level)}
                                        >
                                            {getLevelTag(l.level)}
                                        </EuiBadge>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiBadge
                                            title={formatFullDate(l.createdAt)}
                                        >
                                            {formatFullDate(l.createdAt)}
                                        </EuiBadge>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiCode key={l.id}>
                                            {l.message} | &nbsp;
                                            {l.exceptionMessage}
                                        </EuiCode>
                                        {l.exceptionStackTrace && (
                                            <EuiAccordion
                                                id={`error-stack-${l.id}`}
                                                arrowDisplay="none"
                                                buttonContent={
                                                    <EuiButtonEmpty size="xs">
                                                        Stacktrace
                                                    </EuiButtonEmpty>
                                                }
                                            >
                                                <EuiCodeBlock>
                                                    {l.exceptionStackTrace}
                                                </EuiCodeBlock>
                                            </EuiAccordion>
                                        )}
                                        {l.innerExceptionMessage && (
                                            <EuiAccordion
                                                id={`error-inner-${l.id}`}
                                                arrowDisplay="none"
                                                buttonContent={
                                                    <EuiButtonEmpty size="xs">
                                                        Inner Exception
                                                    </EuiButtonEmpty>
                                                }
                                            >
                                                <EuiCode>
                                                    {l.innerExceptionMessage}
                                                </EuiCode>

                                                {l.innerExceptionStackTrace && (
                                                    <EuiCodeBlock>
                                                        {
                                                            l.innerExceptionStackTrace
                                                        }
                                                    </EuiCodeBlock>
                                                )}
                                            </EuiAccordion>
                                        )}
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                            <EuiHorizontalRule margin="none" />
                        </>
                    ))}

                {data?.isTroubleshooting && data?.logs.length === 0 && (
                    <>
                        <EuiSpacer size="m" />
                        <NoResults
                            title={t('agents:debug_no_logs_title')}
                            text={t('agents:debug_no_logs_text')}
                            onRefresh={() => {
                                refetch()
                            }}
                            isRefreshing={isStatusLoading}
                            onBack={() => navigate('..')}
                        />
                    </>
                )}

                {!data?.isTroubleshooting && (
                    <>
                        <EuiSpacer size="m" />
                        <NoResults
                            image={defaultImage}
                            title={t('agents:debug_not_active_title')}
                            text={t('agents:debug_not_active_text')}
                            onBack={() => navigate('..')}
                        />
                    </>
                )}
            </EuiFlexGroup>
        </DetailsPage>
    )
}

export default AgentDebugPage
