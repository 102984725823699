import { CollectionResult, Query } from '../interfaces'
import { DetailsTenantRequest, Tenant } from '@services/tenants'
import {
    buildQueryParams,
    invalidateCacheTag,
    provideCacheTag,
} from '../helpers'

import { appApi } from '../app.api'

const tenantApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchTenants: builder.query<CollectionResult<Tenant>, Query>({
            providesTags: (result) => provideCacheTag(result, 'Tenant'),
            query: (q) => ({
                url: 'tenants',
                method: 'GET',
                params: buildQueryParams(q),
            }),
        }),
        findTenant: builder.query<Tenant, { id: string }>({
            providesTags: (result) => provideCacheTag(result, 'Tenant'),
            query: (q) => ({
                url: `tenants/${q.id}`,
                method: 'GET',
            }),
        }),
        createTenant: builder.mutation<Tenant, DetailsTenantRequest>({
            query: (req) => ({
                url: 'tenants',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Tenant', result?.id).concat(['Option']),
        }),
        updateTenant: builder.mutation<Tenant, Tenant>({
            query: (req) => ({
                url: `tenants/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Tenant', result?.id).concat(['Option']),
        }),
        deleteTenant: builder.mutation<void, string>({
            query: (id) => ({
                url: `tenants/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Tenant', req).concat(['Option', 'Agent']),
        }),
        deleteTenants: builder.mutation<void, string[]>({
            query: (ids) => ({
                url: 'tenants',
                method: 'DELETE',
                body: {
                    tenantIds: ids,
                },
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Tenant', req).concat(['Option', 'Agent']),
        }),
    }),
})

export const {
    useFetchTenantsQuery,
    useFindTenantQuery,
    useCreateTenantMutation,
    useUpdateTenantMutation,
    useDeleteTenantMutation,
    useDeleteTenantsMutation,
} = tenantApi
