import {
    EuiButtonIcon,
    EuiDatePicker,
    EuiDatePickerRange,
    EuiFieldSearch,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
} from '@elastic/eui'
import { useCallback, useEffect, useState } from 'react'

import { DEBOUNCE_DELAY } from '@utils/globals'
import { DEFAULT_DATE_CULTURE } from '@utils/dates'
import { Moment } from 'moment'
import { SelectionBox } from '@components/form'
import { useDebounce } from '@hooks/utils'

type TableFiltersProps = {
    filters: {
        field: string
        value: string
        type: 'boolean' | 'text' | 'options' | 'date'
        placeholder?: string
        options?: any[]
    }[]
    onFilter?: (filters: string[]) => void
    onClear?: () => void
}

type FilterValue = {
    field: string
    value?: any
}

const TableFilters = (props: TableFiltersProps) => {
    const [filtersValues, setFiltersValues] = useState<FilterValue[]>([])
    const debounce = useDebounce(filtersValues, DEBOUNCE_DELAY)

    const handleOnChange = (field: string, value: any) => {
        const newFiltersValues = [...filtersValues]
        const index = newFiltersValues.findIndex((f) => f.field === field)

        if (index === -1) {
            newFiltersValues.push({ field, value })
        } else {
            newFiltersValues[index] = { field, value }
        }

        setFiltersValues(newFiltersValues)
    }

    const handleOnClear = () => {
        setFiltersValues([])
        if (props.onClear) {
            props.onClear()
        }
    }

    const handleOnFilter = () => {
        if (props.onFilter) {
            const filterAsStrings = filtersValues.map((f) => {
                const filterDef = props.filters.find(
                    (fd) => fd.field === f.field
                )

                if (!filterDef) {
                    return ''
                }
                // Options or boolean filter string
                if (
                    filterDef.type === 'options' ||
                    filterDef.type === 'boolean'
                ) {
                    const selectedValue = f.value[0]?.value
                    if (selectedValue) {
                        return `${f.field}==${selectedValue}`
                    }
                }

                // Text filter string (contains)
                else if (filterDef.type === 'text') {
                    return `${f.field}@=${f.value}`
                }

                // Date filter string (between)
                else if (filterDef.type === 'date') {
                    const dateFrom = f.value[0]?.toDate().toISOString()
                    const dateTo = f.value[1]?.toDate().toISOString()
                    if (dateFrom && dateTo) {
                        return `${f.field}>=${dateFrom},${f.field}<=${dateTo}`
                    }
                }

                return ''
            })

            if (filterAsStrings.length > 0) {
                props.onFilter(filterAsStrings)
            }
        }
    }

    const getValue = useCallback(
        (field: string) => filtersValues.find((f) => f.field === field)?.value,
        [filtersValues]
    )

    useEffect(() => {
        handleOnFilter()
    }, [debounce])

    return (
        <>
            <EuiFlexGroup alignItems="center">
                {props.filters.map((filter) => (
                    <EuiFlexItem grow={false} key={filter.field}>
                        {filter.type === 'text' && (
                            <EuiFieldSearch
                                placeholder={filter.placeholder}
                                value={getValue(filter.field) ?? ''}
                                compressed={true}
                                onChange={(e) =>
                                    handleOnChange(filter.field, e.target.value)
                                }
                            />
                        )}
                        {(filter.type === 'options' ||
                            filter.type === 'boolean') && (
                            <SelectionBox
                                options={filter.options ?? []}
                                key={filter.field}
                                style={{ minWidth: '200px' }}
                                placeholder={filter.placeholder}
                                compressed={true}
                                optionsAsValue={getValue(filter.field)}
                                onChange={(value) =>
                                    handleOnChange(filter.field, value)
                                }
                            />
                        )}

                        {filter.type === 'date' && (
                            <EuiDatePickerRange
                                key={filter.field}
                                startDateControl={
                                    <EuiDatePicker
                                        locale={DEFAULT_DATE_CULTURE}
                                        placeholder={filter.placeholder}
                                        selected={getValue(filter.field)?.[0]}
                                        startDate={getValue(filter.field)?.[0]}
                                        endDate={getValue(filter.field)?.[1]}
                                        onChange={(date: Moment) => {
                                            const value = getValue(filter.field)
                                            handleOnChange(filter.field, [
                                                date,
                                                value?.[1],
                                            ])
                                        }}
                                        showTimeSelect
                                    />
                                }
                                endDateControl={
                                    <EuiDatePicker
                                        locale={DEFAULT_DATE_CULTURE}
                                        placeholder={filter.placeholder}
                                        selected={getValue(filter.field)?.[1]}
                                        startDate={getValue(filter.field)?.[0]}
                                        endDate={getValue(filter.field)?.[1]}
                                        onChange={(date: Moment) => {
                                            const value = getValue(filter.field)
                                            handleOnChange(filter.field, [
                                                value?.[0],
                                                date,
                                            ])
                                        }}
                                        showTimeSelect
                                    />
                                }
                            />
                        )}
                    </EuiFlexItem>
                ))}

                <EuiFlexItem grow={false}>
                    <EuiFlexGroup alignItems="center" gutterSize="xs">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="Filter"
                                iconType="search"
                                size="s"
                                onClick={handleOnFilter}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="Clear filters"
                                iconType="cross"
                                color="text"
                                size="s"
                                onClick={handleOnClear}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    )
}

export default TableFilters
