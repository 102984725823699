import { EuiFocusTrap, EuiOverlayMask } from '@elastic/eui'

import { ForbiddenAccess } from '@components/errors'
import Unauthorized from '@components/errors/Unauthorized/Unauthorized'
import { useAppSelector } from '@hooks/store'

const OverlayNotification = () => {
    const { overlayNotification } = useAppSelector(
        (state) => state.notifications
    )

    return overlayNotification ? (
        <EuiOverlayMask style="top:0;">
            <EuiFocusTrap>
                {overlayNotification === 'forbidden-access' && (
                    <ForbiddenAccess />
                )}
                {overlayNotification === 'unauthorized' && <Unauthorized />}
            </EuiFocusTrap>
        </EuiOverlayMask>
    ) : (
        <></>
    )
}

export default OverlayNotification
