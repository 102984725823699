import {
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLink,
    EuiPanel,
    EuiStat,
    EuiToolTip,
} from '@elastic/eui'

import AlarmStatChart from './AlarmStatChart'
import { AlarmsCollectionResult } from '@services/alarms'
import { FC } from 'react'
import { LoadingSpinner } from '@components/layout'
import { NoResults } from '@pages/common'
import { formatNumber } from '@utils/numbers'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type AlarmsStatsProps = {
    data: AlarmsCollectionResult['stats']
    loading?: boolean
}

const AlarmsStats: FC<AlarmsStatsProps> = ({ data, loading }) => {
    const { t } = useTranslation(['common', 'alarms'])

    const navigate = useNavigate()

    return (
        <EuiPanel
            hasBorder={false}
            hasShadow={false}
            color="subdued"
            style={{ position: 'relative' }}
        >
            {loading && (
                <LoadingSpinner loading={loading} style={{ height: 260 }} />
            )}

            {!data && !loading && <NoResults layout="horizontal" />}

            {data && !loading && (
                <EuiFlexGroup
                    gutterSize="xl"
                    alignItems="center"
                    justifyContent="center"
                    wrap
                >
                    <EuiFlexItem grow={false}>
                        <AlarmStatChart
                            title={t('alarms:stats_all_sensors')}
                            data={data.sensors}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <AlarmStatChart
                            title={t('alarms:stats_active_alarms')}
                            data={data.activeAlarms}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup
                            direction="column"
                            style={{ paddingLeft: 50 }}
                        >
                            <EuiFlexItem>
                                <EuiToolTip
                                    content={t('alarms:offline_agents_text')}
                                >
                                    <EuiButtonEmpty
                                        onClick={() =>
                                            navigate('/agents?status=offline')
                                        }
                                        color="text"
                                    >
                                        <EuiStat
                                            titleSize="s"
                                            title={formatNumber(
                                                data.offlineAgents
                                            )}
                                            titleColor="danger"
                                            description={t(
                                                'alarms:offline_agents'
                                            )}
                                        />
                                    </EuiButtonEmpty>
                                </EuiToolTip>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiToolTip
                                    content={t('alarms:outdated_agents_text')}
                                >
                                    <EuiButtonEmpty
                                        onClick={() =>
                                            navigate('/agents?status=outdated')
                                        }
                                        color="text"
                                    >
                                        <EuiStat
                                            titleSize="s"
                                            title={formatNumber(
                                                data.outdatedAgents
                                            )}
                                            titleColor="accent"
                                            description={t(
                                                'alarms:outdated_agents'
                                            )}
                                        />
                                    </EuiButtonEmpty>
                                </EuiToolTip>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiToolTip
                                    content={t('alarms:disabled_devices_text')}
                                >
                                    <EuiButtonEmpty
                                        onClick={() =>
                                            navigate('/devices?isEnabled=false')
                                        }
                                        color="text"
                                    >
                                        <EuiStat
                                            titleSize="s"
                                            title={formatNumber(
                                                data.disabledDevices
                                            )}
                                            titleColor="subdued"
                                            description={t(
                                                'alarms:disabled_devices'
                                            )}
                                        />
                                    </EuiButtonEmpty>
                                </EuiToolTip>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </EuiPanel>
    )
}

export default AlarmsStats
