import {
    Alarm,
    AlarmAcknowledgement,
    AlarmBreadcrumb,
    AlarmsCollectionResult,
    AlarmsFiltersValues,
} from '@services/alarms'
import {
    buildQueryParams,
    invalidateCacheTag,
    mergeURLSearchParams,
    objectToQueryParams,
    provideCacheTag,
} from '../helpers'

import { Query } from '../interfaces'
import { appApi } from '../app.api'

const alarmApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchAlarms: builder.query<
            AlarmsCollectionResult,
            {
                query: Query
                realtime: boolean
                stats: boolean
                resolvedTemp?: boolean
                filters?: AlarmsFiltersValues
            }
        >({
            providesTags: (result) => provideCacheTag(result, 'Alarm'),
            query: ({ query, realtime, stats, resolvedTemp, filters }) => {
                let params = buildQueryParams(query, ['agent'], {
                    realtime,
                    resolvedTemp: resolvedTemp || false,
                    stats,
                })

                if (filters) {
                    params = mergeURLSearchParams(
                        params,
                        objectToQueryParams({
                            ...filters,
                            statuses: filters.statuses?.map((x) => x.value),
                            types: filters.types?.map((x) => x.value),
                            categories: filters.categories?.map((c) => c.value),
                        })
                    )
                }

                return {
                    url: 'alarms',
                    method: 'GET',
                    params,
                }
            },
            transformResponse: (response: AlarmsCollectionResult) => {
                response.items.forEach((item) => {
                    item.breadcrumbs = {}

                    if (item.additionalData.groupBreadcrumb) {
                        item.breadcrumbs.groups = JSON.parse(
                            item.additionalData.groupBreadcrumb
                        ) as AlarmBreadcrumb[]
                    }

                    if (item.additionalData.agentBreadcrumb) {
                        item.breadcrumbs.agent = JSON.parse(
                            item.additionalData.agentBreadcrumb
                        ) as AlarmBreadcrumb[]
                    }
                })

                return response
            },
        }),
        findAlarm: builder.query<Alarm, { id: string }>({
            providesTags: (result) => provideCacheTag(result, 'Alarm'),
            query: (q) => ({
                url: `alarms/${q.id}`,
                method: 'GET',
            }),
        }),
        acknowledgeAlarm: builder.mutation<undefined, AlarmAcknowledgement>({
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Alarm', req.alarmIds),
            query: (req) => ({
                url: 'alarms/acknowledge',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useFetchAlarmsQuery,
    useFindAlarmQuery,
    useAcknowledgeAlarmMutation,
} = alarmApi
