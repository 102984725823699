import {
    ALL_ALARM_OPTION_TYPES,
    Option,
    getAvailableNotificationTypes,
} from '@services/options'
import { EuiForm, EuiSpacer } from '@elastic/eui'
import { SwitchField, TextField } from '@components/form'
import { Tab, Tabs } from '@components/tabs'
import { array, boolean, object, string } from 'yup'
import {
    useCreateTenantMutation,
    useFindTenantQuery,
    useUpdateTenantMutation,
} from '@services/api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { DetailsPage } from '@components/layout'
import { DetailsTenantRequest } from '@services/tenants'
import { EmptyGuid } from '@utils/guids'
import { OptionsEditor } from '@components/options'
import { Permissions } from '@services/auth'
import { mergeArraysBy } from '@utils/arrays'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const tenantSchema = object({
    name: string().required(),
    isEnabled: boolean().required(),
    options: array().optional(),
})

const TenantDetailsPage = () => {
    const navigate = useNavigate()

    // eslint-disable-next-line prefer-const
    let { id } = useParams()
    const [skip, setSkip] = useState(true)

    const canCreateTenant = useHasPermission(Permissions.tenant.canCreate)
    const canUpdateTenant = useHasPermission(Permissions.tenant.canUpdate)

    const { data, isLoading } = useFindTenantQuery({ id: id ?? '' }, { skip })
    const [createTenant, { isLoading: isCreating }] = useCreateTenantMutation()
    const [updateTenant, { isLoading: isUpdating }] = useUpdateTenantMutation()
    const { t } = useTranslation(['common', 'tenants'])

    const notificationTypes = getAvailableNotificationTypes()

    const isEditing = useMemo(() => data && id, [data, id])

    const isFormDisabled = useMemo(() => {
        if (isEditing && canUpdateTenant) return false
        if (!isEditing && canCreateTenant) return false

        return true
    }, [isEditing, canCreateTenant, canUpdateTenant])

    const onSubmit = async (record: DetailsTenantRequest) => {
        try {
            if (data && id) {
                await updateTenant({ ...record, id: id })
            } else {
                await createTenant(record)
            }
            navigate('/tenants')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<DetailsTenantRequest>({
        initialValues: {
            name: '',
            isEnabled: true,
            options: [],
        },
        validationSchema: tenantSchema,
        onSubmit,
    })

    const handleOptionsChange = (opts: Option[]) => {
        const newOptions = mergeArraysBy<Option>(
            [...((form.values.options as Option[]) || [])],
            opts,
            'key'
        )
        form.setFieldValue('options', newOptions)
    }

    useEffect(() => {
        if (id && id !== 'new') {
            setSkip(false)
        }
    }, [])

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    return (
        <DetailsPage
            onClose={() => navigate('/tenants')}
            size="s"
            title={
                data && canUpdateTenant
                    ? t('tenants:edit_title')
                    : data && !canUpdateTenant
                      ? t('tenants:view_title')
                      : t('tenants:create_title')
            }
            loading={isLoading || isCreating || isUpdating}
            submitLoading={isLoading || isCreating || isUpdating}
            onSave={isFormDisabled ? undefined : form.handleSubmit}
            onCancel={() => navigate('/tenants')}
        >
            <EuiForm>
                <Tabs>
                    <Tab title={t('common:details')} id="details">
                        <EuiSpacer />
                        <TextField
                            form={form}
                            name="name"
                            isDisabled={isFormDisabled}
                            fullWidth
                            label={t('tenants:name')}
                            placeholder={t('tenants:name')}
                        />

                        <SwitchField
                            form={form}
                            name="isEnabled"
                            isDisabled={isFormDisabled}
                            label={t('tenants:is_enabled')}
                            value={form.values.isEnabled}
                            onChange={(value) =>
                                form.setFieldValue('isEnabled', value)
                            }
                        />
                    </Tab>
                    <Tab title={t('common:settings')} id="settings">
                        <OptionsEditor
                            source="tenant"
                            sourceId={id === 'new' ? EmptyGuid : id}
                            isDisabled={isFormDisabled}
                            onChange={handleOptionsChange}
                        />
                    </Tab>
                    <Tab title={t('common:alarms')}>
                        <OptionsEditor
                            source="tenant"
                            sourceId={id === 'new' ? EmptyGuid : id}
                            isDisabled={isFormDisabled}
                            optionsGroups={ALL_ALARM_OPTION_TYPES}
                            onChange={handleOptionsChange}
                        />
                    </Tab>

                    <Tab title={t('common:notifications')}>
                        <OptionsEditor
                            source="tenant"
                            sourceId={id}
                            isDisabled={isFormDisabled}
                            optionsGroups={notificationTypes}
                            onChange={handleOptionsChange}
                        />
                    </Tab>
                </Tabs>
            </EuiForm>
        </DetailsPage>
    )
}
export default TenantDetailsPage
