import { EuiFormRow, EuiSwitch, EuiSwitchEvent } from '@elastic/eui'

import { Option } from '@services/options'
import { useTranslation } from 'react-i18next'

type OptionBooleanFieldProps = {
    option: Option
    helpText?: JSX.Element | undefined
    onChange: (option: Option) => void
    isDisabled?: boolean
}

const OptionBooleanField = ({
    option,
    helpText,
    onChange,
    isDisabled,
}: OptionBooleanFieldProps) => {
    const { t } = useTranslation(['options'])
    const label = `${option.key.toLowerCase()}`

    const handleOnChange = (e: EuiSwitchEvent) => {
        onChange({ ...option, value: e.target.checked })
    }

    return (
        <EuiFormRow
            label={t(label)}
            helpText={helpText}
            fullWidth={true}
            isDisabled={isDisabled || option.isInherited}
        >
            <EuiSwitch
                showLabel={false}
                label={t(label)}
                disabled={isDisabled || option.isInherited}
                checked={option.value?.toString() === 'true'}
                defaultChecked={true}
                onChange={handleOnChange}
                compressed
            />
        </EuiFormRow>
    )
}
export default OptionBooleanField
