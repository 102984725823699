import {
    EuiComboBox,
    EuiComboBoxOptionOption,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiIcon,
    EuiToolTip,
} from '@elastic/eui'

import { Option } from '@services/options'
import { useFetchUsersQuery } from '@services/api'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type NotificationSubscribersOptionFieldProps = {
    option: Option
    helpText?: JSX.Element | undefined
    extraInfo?: { title?: string; content?: string | JSX.Element } | undefined
    html?: boolean
    onChange: (option: Option) => void
    isDisabled?: boolean
}

const NotificationSubscribersOptionField = (
    props: NotificationSubscribersOptionFieldProps
) => {
    const { t } = useTranslation(['options'])
    const { data: users, isLoading } = useFetchUsersQuery({})

    const items = useMemo(() => {
        return (
            users?.items.map((u) => ({
                label: `${u.firstName} ${u.lastName}`,
                value: u.id,
            })) ?? []
        )
    }, [users])

    const selectedItems = useMemo(() => {
        const selectedValues: string[] = props.option.value
            ? (props.option.value as string).split(',')
            : []

        if (selectedValues.length === 0) {
            return []
        }

        return items.filter((i) => selectedValues.includes(i.value))
    }, [props.option.value, items])

    const handleOnChange = (value: EuiComboBoxOptionOption<string>[]) => {
        const newValue = value.map((v) => v.value).join(',')

        props.onChange({
            ...props.option,
            value: newValue,
        })
    }

    return (
        <EuiFormRow
            label={
                <EuiFlexGroup alignItems="center" gutterSize="xs">
                    <EuiFlexItem>
                        {t(props.option.key.toLowerCase())}
                    </EuiFlexItem>
                    {props.extraInfo && (
                        <EuiFlexItem grow={false}>
                            <EuiToolTip
                                content={
                                    <div>
                                        {props.extraInfo.title && (
                                            <p>
                                                {t(
                                                    `alarms:${props.extraInfo.title}`
                                                )}
                                            </p>
                                        )}
                                        {props.extraInfo.content}
                                    </div>
                                }
                            >
                                <EuiIcon type="iInCircle" />
                            </EuiToolTip>
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            }
            isDisabled={props.isDisabled || props.option.isInherited}
            fullWidth
            helpText={props.helpText}
        >
            <EuiComboBox
                fullWidth
                isDisabled={props.option.isInherited}
                isLoading={isLoading}
                selectedOptions={selectedItems}
                options={items}
                onChange={handleOnChange}
            />
        </EuiFormRow>
    )
}

export default NotificationSubscribersOptionField
