import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui'
import NetworkLineChart, { ChartProps } from './NetworkLineChart'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type HttpChartProps = ChartProps

const HttpChart = (props: HttpChartProps) => {
    const { t } = useTranslation(['network'])
    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    // Map HTTP status code to label
    const mapHttpStatusCode = (code: number) => {
        if (code >= 200 && code < 300) return 'OK'
        if (code >= 300 && code < 400) return 'Redirect'
        if (code >= 400 && code < 500) return 'Client Error'
        if (code >= 500 && code < 600) return 'Server Error'
        return 'Unknown'
    }

    return (
        <EuiFlexGroup direction="column">
            <EuiFlexItem>
                <EuiTitle size="xs">
                    <h4>{t('network:http_title_alive')}</h4>
                </EuiTitle>
                <NetworkLineChart
                    height={250}
                    legendWidth={175}
                    data={props.data}
                    query={props.query}
                    setQuery={props.setQuery}
                    lines={[
                        {
                            key: 'httpIsAlive',
                            label: t('network:data_key_http_is_alive'),
                        },
                    ]}
                    xAxis="timestamp"
                    xAxisFormatter={(v) => formatTimestamp(v, props.query)}
                    xAxisDomain={xAxisDomain}
                    yAxisDomain={[0, 1]}
                    yAxisFormatter={(v) => {
                        if (v === Infinity || v === -Infinity) return v

                        return v === 1 || v === '1'
                            ? t('network:data_value_alive')
                            : v === 0 || v === '0'
                              ? t('network:data_value_dead')
                              : ''
                    }}
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiTitle size="xs">
                    <h4>{t('network:http_title_response_time')}</h4>
                </EuiTitle>
                <NetworkLineChart
                    height={250}
                    legendWidth={175}
                    data={props.data}
                    query={props.query}
                    setQuery={props.setQuery}
                    lines={[
                        {
                            key: 'httpResponseTime',
                            label: t('network:data_key_http_response_time'),
                        },
                    ]}
                    xAxis="timestamp"
                    xAxisFormatter={(v) => formatTimestamp(v, props.query)}
                    xAxisDomain={xAxisDomain}
                    yAxisDomain={[0, 10_000]}
                    yAxisFormatter={(v) => {
                        if (v === Infinity || v === -Infinity) return v
                        return `${v} ms`
                    }}
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiTitle size="xs">
                    <h4>{t('network:http_title_status_code')}</h4>
                </EuiTitle>
                <NetworkLineChart
                    height={250}
                    legendWidth={175}
                    data={props.data}
                    query={props.query}
                    setQuery={props.setQuery}
                    lines={[
                        {
                            key: 'httpStatusCode',
                            label: t('network:data_key_http_status_code'),
                        },
                    ]}
                    xAxis="timestamp"
                    xAxisFormatter={(v) => formatTimestamp(v, props.query)}
                    xAxisDomain={xAxisDomain}
                    yAxisDomain={[200, 500]}
                    yAxisFormatter={(v) => {
                        if (v === Infinity || v === -Infinity) return v
                        return `${mapHttpStatusCode(v)} (${v})`
                    }}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}

export default HttpChart
