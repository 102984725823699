import { EuiConfirmModal, EuiSpacer, EuiText } from '@elastic/eui'
import { getDefaultQuery, useFetchSensorsQuery } from '@services/api'

import { SelectionBox } from '@components/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type SensorConfiguratorProps = {
    onConfirm: (sensorTemplateId: number | undefined) => void
    onCancel: () => void
}

const SensorConfigurator = ({
    onConfirm,
    onCancel,
}: SensorConfiguratorProps) => {
    const { t } = useTranslation(['common', 'sensorConfigurator'])

    // Queries
    const { data: sensorTemplates, isLoading: sensorsLoading } =
        useFetchSensorsQuery({
            ...getDefaultQuery(),
            filters: ['isTemplate==true'],
        })

    // States
    const [selectedSensorId, setSelectedTemplateId] = useState<
        number | undefined
    >(undefined)

    return (
        <EuiConfirmModal
            title={t('sensorConfigurator:title')}
            onCancel={onCancel}
            onConfirm={() => onConfirm(selectedSensorId)}
            cancelButtonText={t('common:cancel')}
            confirmButtonText={t('common:confirm')}
            buttonColor="danger"
            defaultFocusedButton="confirm"
            isLoading={sensorsLoading}
        >
            <>
                <EuiText>
                    <p>{t('sensorConfigurator:label')}</p>
                </EuiText>
                <EuiSpacer />
                <SelectionBox
                    fullWidth
                    placeholder={t('sensorConfigurator:selection_placeholder')}
                    value={selectedSensorId}
                    onChange={(value) => setSelectedTemplateId(value[0]?.value)}
                    options={
                        sensorTemplates?.items.map((item) => ({
                            label: item.templateName ?? item.name,
                            value: item.id,
                        })) || []
                    }
                />
            </>
        </EuiConfirmModal>
    )
}

export default SensorConfigurator
