import {
    ALL_NOTIFICATION_OPTION_TYPES,
    Option,
    getAlarmOptionsTypeBySensorType,
    getOptionsTypeBySensorType,
} from '@services/options'
import {
    DetailsSensorRequest,
    SensorType,
    getSensorTypeTranslation,
} from '@services/sensors'
import { EuiForm, EuiSpacer } from '@elastic/eui'
import { SelectionBoxField, SwitchField, TextField } from '@components/form'
import { Tab, Tabs } from '@components/tabs'
import { array, boolean, number, object, string } from 'yup'
import {
    getDefaultQuery,
    useCreateSensorTemplateMutation,
    useFetchCategoriesQuery,
    useFetchDevicesQuery,
    useFindSensorQuery,
    useUpdateSensorTemplateMutation,
} from '@services/api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { DetailsPage } from '@components/layout'
import { OptionsEditor } from '@components/options'
import { getNumberOrDefault } from '@utils/numbers'
import { getOptionsByEnum } from '@utils/enums'
import { mergeArraysBy } from '@utils/arrays'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

const sensorSchema = object({
    name: string(),
    templateName: string().required(),
    type: string(),
    isEnabled: boolean().required(),
    deviceId: number().nullable().optional(),
    categoryId: number().nullable().optional(),
    options: array().optional(),
})

const SensorTemplateDetailsPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(['common', 'sensors'])

    // eslint-disable-next-line prefer-const
    let { id } = useParams()

    // States
    const [skip, setSkip] = useState(true)
    const [autoNameParams, setAutoNameParams] = useState({
        deviceName: '',
        sensorType: SensorType.Ping, // Initial value for the dropdown
    })

    // Queries
    const { data, isLoading } = useFindSensorQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip }
    )
    const { data: devices } = useFetchDevicesQuery({
        ...getDefaultQuery(),
        filters: ['isTemplate==true'],
    })
    const { data: categories } = useFetchCategoriesQuery({})

    // Mutations
    const [createSensor, { isLoading: isCreating }] =
        useCreateSensorTemplateMutation()
    const [updateSensor, { isLoading: isUpdating }] =
        useUpdateSensorTemplateMutation()

    const onSubmit = async (record: DetailsSensorRequest) => {
        try {
            if (data && id) await updateSensor({ ...record, id: +id } as any)
            else await createSensor(record)

            navigate('/sensorTemplates')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<DetailsSensorRequest>({
        initialValues: {
            name: undefined,
            templateName: '',
            isPaused: false,
            pausedUntil: undefined,
            type: SensorType.Ping,
            isEnabled: true,
            isTemplate: false,
            deviceId: undefined,
            options: [],
        },
        validationSchema: sensorSchema,
        onSubmit,
    })

    const handleOptionsChange = (opts: Option[]) => {
        const newOptions = mergeArraysBy<Option>(
            [...((form.values.options as Option[]) || [])],
            opts,
            'key'
        )
        form.setFieldValue('options', newOptions)
    }

    const optionsType = useMemo(
        () => getOptionsTypeBySensorType(form.values.type),
        [form.values.type]
    )

    const alarmOptionsTypes = useMemo(
        () => getAlarmOptionsTypeBySensorType(form.values.type),
        [form.values.type]
    )

    useEffect(() => {
        if (id && !isNaN(+id)) {
            setSkip(false)
        }
    }, [])

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    /**
     * Sets the name value automatically based on the device name and the sensor type.
     * This is only used when creating a new sensor before manually entering the name.
     */
    useEffect(() => {
        if (!form.touched.name && !!skip) {
            const name = autoNameParams.sensorType
                ? `${autoNameParams.deviceName} ${getSensorTypeTranslation(
                      autoNameParams.sensorType
                  )}`
                : autoNameParams.deviceName
            form.setFieldValue('name', name)
        }
    }, [autoNameParams])

    return (
        <DetailsPage
            onClose={() => navigate('..')}
            size="s"
            title={
                data
                    ? t('sensors:edit_template_title')
                    : t('sensors:create_template_title')
            }
            loading={isLoading || isCreating || isUpdating}
            submitLoading={isLoading || isCreating || isUpdating}
            onSave={form.handleSubmit}
            onCancel={() => navigate('..')}
        >
            <Tabs>
                <Tab title={t('common:details')} id="details">
                    <EuiSpacer />
                    <EuiForm component="form" onSubmit={form.handleSubmit}>
                        <TextField
                            form={form}
                            name="templateName"
                            fullWidth
                            label={t('sensors:template_name')}
                            placeholder={t('sensors:template_name')}
                        />
                        <SelectionBoxField
                            form={form}
                            name="deviceId"
                            label={t('sensors:device')}
                            fullWidth
                            isClearable={false}
                            value={form.values.deviceId}
                            onChange={(value) => {
                                form.setFieldValue('deviceId', value[0]?.value)
                                setAutoNameParams({
                                    ...autoNameParams,
                                    deviceName: value[0]?.label,
                                })
                            }}
                            options={
                                devices?.items?.map((item) => ({
                                    label: item.templateName,
                                    value: item.id,
                                })) ?? []
                            }
                        />
                        <SelectionBoxField
                            form={form}
                            name="type"
                            label={t('sensors:type')}
                            fullWidth
                            isClearable={false}
                            value={form.values.type}
                            onChange={(value) => {
                                form.setFieldValue('type', value[0]?.value)
                                setAutoNameParams({
                                    ...autoNameParams,
                                    sensorType: value[0]?.value,
                                })
                            }}
                            options={
                                getOptionsByEnum(SensorType).map(
                                    ({ value }) => ({
                                        value,
                                        label: getSensorTypeTranslation(value),
                                    })
                                ) ?? []
                            }
                        />
                        <TextField
                            form={form}
                            name="name"
                            fullWidth
                            label={t('sensors:name')}
                            placeholder={t('sensors:name')}
                        />
                        <SelectionBoxField
                            form={form}
                            name="categoryId"
                            label={t('sensors:category')}
                            fullWidth
                            isClearable={false}
                            value={form.values.categoryId}
                            onChange={(value) =>
                                form.setFieldValue(
                                    'categoryId',
                                    value[0]?.value
                                )
                            }
                            options={
                                categories?.items?.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })) ?? []
                            }
                        />
                        <SwitchField
                            form={form}
                            name="isEnabled"
                            label={t('sensors:is_enabled')}
                            value={form.values.isEnabled}
                            onChange={(value) =>
                                form.setFieldValue('isEnabled', value)
                            }
                        />
                    </EuiForm>
                </Tab>
                <Tab
                    title={t('common:settings')}
                    id="settings"
                    hide={!optionsType ? true : undefined}
                >
                    <OptionsEditor
                        source="sensor"
                        sourceId={id === 'new' ? 0 : id}
                        inheritanceSourceId={
                            id === 'new' ? form.values.deviceId : undefined
                        }
                        optionsGroups={optionsType ? optionsType : []}
                        onChange={handleOptionsChange}
                    />
                </Tab>
                <Tab title={t('common:alarms')} hide={skip ? true : undefined}>
                    <OptionsEditor
                        source="sensor"
                        sourceId={id}
                        optionsGroups={
                            alarmOptionsTypes ? alarmOptionsTypes : []
                        }
                        onChange={handleOptionsChange}
                    />
                </Tab>
                <Tab title={t('common:notifications')}>
                    <OptionsEditor
                        source="sensor"
                        sourceId={id}
                        optionsGroups={ALL_NOTIFICATION_OPTION_TYPES}
                        onChange={handleOptionsChange}
                    />
                </Tab>
            </Tabs>
        </DetailsPage>
    )
}

export default SensorTemplateDetailsPage
