import { SnmpDataPointKeys } from '@services/sensors'
import moment from 'moment'

export const DATE_FILTER_TYPES = [
    'hour',
    'today',
    'twoDay',
    'week',
    'month',
    'custom',
] as const

export type DateFilterType = (typeof DATE_FILTER_TYPES)[number]

export type DataQuery = {
    type: DateFilterType
    start?: Date
    end?: Date
    interfaces?: string[]
}

export type SensorDataRequest = {
    sensor: number
    query?: DataQuery
}

export interface DataPoint {
    id: string
    values: never
    timestamp: Date
    sensorId: number
}

export const getPollingInterval = (
    query: DataQuery | undefined
): number | undefined => {
    const baseInterval = 1000 * 60 // 1 minute

    // If the end timestamp of the query is not "now", there is no need for a polling interval
    if (
        query?.type === 'custom' &&
        moment.duration(moment().diff(moment(query.end))) >
            moment.duration(baseInterval * 2)
    ) {
        return undefined
    }

    switch (query?.type) {
        case 'custom': {
            const span = moment.duration(
                moment(query.end).diff(moment(query.start))
            )
            if (span < moment.duration(3, 'day')) return baseInterval
            else return baseInterval * 30 // 30 minutes for default
        }
        case 'hour':
        case 'today':
        case 'twoDay':
            return baseInterval
        case 'week':
        case 'month':
        default:
            return baseInterval * 30 // 30 minutes for default
    }
}

/**
 * It takes a DataQuery object and returns a string that is the URL encoded version of the DataQuery
 * object.
 * @param {DataQuery | undefined} query - DataQuery | undefined
 * @returns DataQuery as encoded url
 */
export const encodeUrlDataQuery = (query: DataQuery | undefined): string => {
    if (!query) {
        return ''
    }

    let params = `type=${query.type}&start=${query.start?.toISOString() ?? ''}&end=${
        query.end?.toISOString() ?? ''
    }`

    if (query.interfaces) {
        query.interfaces.forEach((i) => {
            params = params.concat(`&interfaces=${i}`)
        })
    }

    return params
}

/**
 * Remaps the data to be used for the charts
 * @param data the data to be remapped
 * @returns the remapped data
 */
export const mapData = (data: DataPoint[] | undefined) => {
    if (!data) {
        return []
    }

    const mapping = {
        float: [
            SnmpDataPointKeys.bandwith.outgoing,
            SnmpDataPointKeys.bandwith.incoming,
            SnmpDataPointKeys.bandwith.total,
            SnmpDataPointKeys.bandwith.max,
        ],
        integer: [
            SnmpDataPointKeys.errors.outgoing,
            SnmpDataPointKeys.errors.incoming,
            SnmpDataPointKeys.unicastPkts.outgoing,
            SnmpDataPointKeys.unicastPkts.incoming,
            SnmpDataPointKeys.nonUnicastPkts.outgoing,
            SnmpDataPointKeys.nonUnicastPkts.incoming,
        ],
    }

    return data.map((item) => {
        const results: any = {
            timestamp: moment.utc(item.timestamp).valueOf(),
        }

        if (item.values) {
            Object.keys(item.values).forEach((key) => {
                if (mapping.float.some((m) => key.startsWith(m))) {
                    results[key] = parseFloat(item.values[key])
                } else if (mapping.integer.some((m) => key.startsWith(m))) {
                    results[key] = parseInt(item.values[key], 10)
                } else {
                    results[key] = item.values[key]
                }
            })
        }

        return results
    })
}

/**
 * Gets the data domain for the given sensor type. This is used to determine the range of the chart.
 * @param query The query to use to get the data domain.
 * @returns The data domain.
 */
export const getDataDomainByQuery = (query: DataQuery): [number, number] => {
    const end = moment.utc()

    switch (query.type) {
        case 'hour': {
            const start = end.clone().subtract(1, 'hour')
            return [start.valueOf(), end.valueOf()]
        }
        case 'today': {
            const startToday = end.clone().subtract(1, 'day')
            return [startToday.valueOf(), end.valueOf()]
        }
        case 'twoDay': {
            const startTwoDay = end.clone().subtract(2, 'day')
            return [startTwoDay.valueOf(), end.valueOf()]
        }
        case 'week': {
            const startWeek = end.clone().subtract(1, 'week')
            return [startWeek.valueOf(), end.valueOf()]
        }
        case 'month': {
            const startMonth = end.clone().subtract(1, 'month')
            return [startMonth.valueOf(), end.valueOf()]
        }
        case 'custom':
            return [
                moment.utc(query.start).valueOf() ?? 0,
                moment.utc(query.end).valueOf() ?? 0,
            ]
    }
}

/**
 * Formats the timestamp (of date) based on the given/selected filter
 * @param timestamp The timestamp to format.
 * @param filterType The filter type to use.
 * @returns The formatted timestamp.
 */
export const formatTimestamp = (
    timestamp: string | Date,
    filter: DataQuery | undefined
) => {
    const t = moment(timestamp)
    switch (filter?.type) {
        case 'custom': {
            const diff = moment.duration(
                moment(filter.end).diff(moment(filter.start))
            )
            if (diff < moment.duration(1, 'day')) return t.format('HH:mm:ss')
            if (diff < moment.duration(1, 'week')) return t.format('dd HH:mm')

            return t.format('DD-MM-YY HH:mm')
        }
        case 'hour':
            return t.format('HH:mm:ss')
        case 'today':
        case 'twoDay':
            return t.format('dd HH:mm')
        case 'week':
        case 'month':
        default:
            return t.format('DD-MM-YY HH:mm')
    }
}
