import {
    DropResult,
    EuiDragDropContext,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiSwitch,
    EuiText,
    EuiTitle,
    euiDragDropReorder,
} from '@elastic/eui'
import { configAlarmsColumns, isAlarmColumnsChanged } from '@services/alarms'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useEffect, useRef, useState } from 'react'

import { TableColumn } from '@components/table'
import { setAlarmsPrefrences } from '@store/alarms'
import { useTranslation } from 'react-i18next'

const AlarmsPrefrencesColumns = () => {
    const { prefrences } = useAppSelector((state) => state.alarms)
    const [columns, setColums] = useState<TableColumn[]>([])
    const isIniialized = useRef(false)
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['common', 'alarms'])

    const handleOnDragEnd = ({ source, destination }: DropResult) => {
        if (source && destination) {
            const items = euiDragDropReorder(
                columns,
                source.index,
                destination.index
            )

            setColums(items)
        }
    }

    const handleOnColumnChange = (index: number, isActive: boolean): void => {
        const newColumns = [...columns]
        newColumns[index].hidden = !isActive
        setColums(newColumns)
    }

    useEffect(() => {
        if (columns.length > 0 || isIniialized.current) return

        const newColumns = configAlarmsColumns(prefrences)

        setColums(newColumns)

        isIniialized.current = true
    }, [prefrences])

    useEffect(() => {
        if (
            !isIniialized.current ||
            !isAlarmColumnsChanged(columns, prefrences)
        )
            return

        setTimeout(() => {
            const newConfigColumns = columns.map(({ id, hidden }) => ({
                id: id || '',
                active: !hidden,
            }))

            dispatch(
                setAlarmsPrefrences({
                    ...prefrences,
                    configColumns: newConfigColumns,
                })
            )
        })
    }, [columns])

    return (
        <EuiPanel color="subdued">
            <EuiTitle size="xxs">
                <h3>{t('alarms:columns_settings')}</h3>
            </EuiTitle>
            <EuiSpacer size="s" />
            <EuiDragDropContext onDragEnd={handleOnDragEnd}>
                <EuiDroppable droppableId="DROPPABLE_AREA">
                    {columns.map(({ id, name, hidden }, index) => (
                        <EuiDraggable
                            spacing="s"
                            key={id}
                            index={index}
                            draggableId={id || ''}
                        >
                            {(_, state) => (
                                <EuiPanel
                                    hasShadow={state.isDragging}
                                    hasBorder
                                    paddingSize="s"
                                >
                                    <EuiFlexGroup>
                                        <EuiFlexItem grow={false}>
                                            <EuiSwitch
                                                label={'Show'}
                                                showLabel={false}
                                                checked={!hidden}
                                                compressed
                                                onChange={(e) =>
                                                    handleOnColumnChange(
                                                        index,
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiText size="s">
                                                {name}
                                                {state.isDragging && ' ✨'}
                                            </EuiText>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiPanel>
                            )}
                        </EuiDraggable>
                    ))}
                </EuiDroppable>
            </EuiDragDropContext>
        </EuiPanel>
    )
}

export default AlarmsPrefrencesColumns
