type TabProps = {
    title: string
    id?: string
    children: JSX.Element | JSX.Element[]
    hide?: boolean | undefined
}

const Tab = (props: TabProps) => {
    return <span>{props.children}</span>
}
export default Tab
