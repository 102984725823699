import { EuiBadge, EuiButton, EuiCode } from '@elastic/eui'
import { Outlet, useNavigate } from 'react-router-dom'
import { Table, TableColumn } from '@components/table'
import {
    useDeleteAgentMutation,
    useDeleteAgentsMutation,
    useFetchAgentsQuery,
    useFetchTenantsQuery,
} from '@services/api'
import { useHasDependentPermissions, useHasPermission } from '@hooks/auth'

import { Agent } from '@services/agents'
import { AgentUpdate } from '@components/agents'
import { Page } from '@components/layout'
import { Permissions } from '@services/auth'
import { useQuery } from '@hooks/query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const AgentsPage = () => {
    const { t } = useTranslation(['common', 'agents'])
    const navigate = useNavigate()
    const {
        query,
        handleQueryChange,
        handleQueryFiltersChange,
        clearQueryFilters,
    } = useQuery()

    const canCreateAgent = useHasDependentPermissions('agent', [
        'canView',
        'canCreate',
    ])
    const canUpdateAgent = useHasDependentPermissions('agent', [
        'canView',
        'canUpdate',
    ])
    const canDeleteAgent = useHasDependentPermissions('agent', ['canDelete'])

    const canTriggerUpdate = useHasPermission(
        Permissions.agent.canTriggerUpdate
    )
    const canDebugAgent = useHasPermission(Permissions.agent.canViewDebugLogs)
    const canViewTenants = useHasPermission(Permissions.tenant.canView)

    const { data: tenants } = useFetchTenantsQuery(
        {},
        { skip: !canViewTenants }
    )
    const { data, isLoading } = useFetchAgentsQuery(query)

    const [updateAgent, setUpdateAgent] = useState<Agent | undefined>(undefined)

    const [deleteAgent, { isLoading: isDeleteLoading }] =
        useDeleteAgentMutation()
    const [deleteAgents, { isLoading: isDeletesLoading }] =
        useDeleteAgentsMutation()

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('agents:name'),
            sortable: true,
            filterable: true,
            filterType: 'text',
            filterPlaceholder: t('agents:filter_by_name'),
        },
        {
            field: 'isEnabled',
            name: t('agents:is_enabled'),
            renderAs: 'boolean',
            sortable: true,
            filterable: true,
            filterType: 'boolean',
            filterPlaceholder: t('agents:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        {
            field: 'MachineName',
            name: t('agents:hostname'),
            sortable: false,
            filterable: false,
            formatter: (_, row: Agent) => {
                if (!row.machines || row.machines.length == 0) {
                    return (
                        <EuiBadge color="warning">
                            {t('agents:unknown')}
                        </EuiBadge>
                    )
                }

                return <EuiCode>{row.machines[0]?.machineName}</EuiCode>
            },
        },
        {
            field: 'DomainName',
            name: t('agents:domain_name'),
            sortable: false,
            filterable: false,
            formatter: (_, row: Agent) => {
                if (!row.machines || row.machines.length == 0) {
                    return (
                        <EuiBadge color="warning">
                            {t('agents:unknown')}
                        </EuiBadge>
                    )
                }

                return <EuiCode>{row.machines[0]?.domainName}</EuiCode>
            },
        },
        {
            field: 'version',
            name: t('agents:version'),
            sortable: false,
            filterable: false,
            formatter: (_, row: Agent) => {
                if (!row.machines || row.machines.length == 0) {
                    return (
                        <EuiBadge color="warning">
                            {t('agents:unknown')}
                        </EuiBadge>
                    )
                }

                return (
                    <EuiBadge color="hollow">
                        v{row.machines[0]?.version}
                    </EuiBadge>
                )
            },
        },
        ...(canViewTenants
            ? [
                  {
                      field: 'tenant.name',
                      name: t('agents:tenant'),
                      sortable: true,
                      filterable: true,
                      filterField: 'tenantId',
                      filterType: 'options',
                      filterPlaceholder: t('agents:filter_by_tenant'),
                      filterOptions:
                          tenants?.items?.map((item) => ({
                              label: item.name,
                              value: item.id,
                          })) ?? [],
                  } as TableColumn,
              ]
            : []),
        {
            field: 'action',
            renderAs: 'action',
            customActions: [
                {
                    label: t('agents:install'),
                    icon: 'download',
                    description: t('agents:install_agent'),
                    onClick: (record: Agent) =>
                        navigate(`/agents/${record.id}/install`),
                },
                ...(canTriggerUpdate
                    ? [
                          {
                              label: t('agents:update'),
                              icon: 'refresh',
                              description: t('agents:update_agent'),
                              onClick: setUpdateAgent,
                          },
                      ]
                    : []),
                ...(canDebugAgent
                    ? [
                          {
                              label: t('agents:debug'),
                              icon: 'bug',
                              description: t('agents:debug_agent_description'),
                              onClick: (record: Agent) =>
                                  navigate(`/agents/${record.id}/debug`),
                          },
                      ]
                    : []),
            ],
            [canUpdateAgent ? 'onEdit' : 'onView']: (agent: Agent) =>
                navigate(`/agents/${agent.id}`),
            onDelete: canDeleteAgent
                ? (agent: Agent) => deleteAgent(agent.id)
                : undefined,
        },
    ]

    return (
        <>
            <Outlet />

            {updateAgent && (
                <AgentUpdate
                    agent={updateAgent}
                    onClose={() => setUpdateAgent(undefined)}
                />
            )}

            <Page
                title={t('agents:title')}
                iconType="managementApp"
                items={
                    canCreateAgent
                        ? [
                              <EuiButton
                                  key="btn_new_agent"
                                  color="primary"
                                  iconType="plus"
                                  onClick={() => navigate('/agents/new')}
                              >
                                  {t('agents:new_agent')}
                              </EuiButton>,
                          ]
                        : []
                }
            >
                <Table<Agent>
                    loading={isLoading || isDeleteLoading || isDeletesLoading}
                    items={data?.items ?? []}
                    totalCount={data?.totalCount ?? 0}
                    columns={columns}
                    onFilter={handleQueryFiltersChange}
                    onFilterClear={clearQueryFilters}
                    onChange={handleQueryChange}
                    selectable={canDeleteAgent}
                    itemSelectable={() => true}
                    deleteSelectedRows={
                        canDeleteAgent
                            ? (items) =>
                                  deleteAgents(items.map((item) => item.id))
                            : undefined
                    }
                />
            </Page>
        </>
    )
}

export default AgentsPage
