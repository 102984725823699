import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingChart,
    EuiPanel,
    EuiPanelProps,
} from '@elastic/eui'
import {
    FC,
    PropsWithChildren,
    Suspense,
    lazy,
    useEffect,
    useRef,
    useState,
} from 'react'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { useInView } from 'react-intersection-observer'

type IfInViewProps = PropsWithChildren & {
    height?: number
    viewId?: string
    isLoading?: boolean
    onChange?: (viewId: IfInViewProps['viewId'], isInView: boolean) => void
}

const ViewLoading = ({ color }: { color?: EuiPanelProps['color'] }) => {
    return (
        <EuiPanel
            hasShadow={false}
            paddingSize="none"
            color={color ?? 'subdued'}
            style={{ height: '100%' }}
        >
            <EuiFlexGroup
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
            >
                <EuiFlexItem grow={false}>
                    <EuiLoadingChart size="l" />
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPanel>
    )
}

const ViewContent = ({ children }: PropsWithChildren) => <>{children}</>

const IfInView: FC<IfInViewProps> = ({
    children,
    height,
    viewId,
    onChange,
    isLoading,
}) => {
    const ref = useRef(null)
    const [inView, setInView] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting)
                onChange?.(viewId, entry.isIntersecting)
            },
            {
                root: null,
                rootMargin: `${height}px 0px ${height}px 0px`,
                threshold: 0.1,
            }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [])

    return (
        <div style={{ height }} ref={ref}>
            {!isLoading && inView ? (
                <ViewContent>{children}</ViewContent>
            ) : (
                <ViewLoading />
            )}
        </div>
    )
}

export default IfInView
