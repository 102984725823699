import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Query, getDefaultQuery } from '@services/api'

import { AlarmsFiltersValues } from '@services/alarms'

export interface AlarmsState {
    realtime: boolean
    stats?: boolean
    interval?: number
    filters?: AlarmsFiltersValues
    prefrences: {
        compressedLayout: boolean
        iconsOnly: boolean
        humanReadableDates: boolean
        displayValues: boolean
        displayTechDetails: boolean
        displayFullTechDetails: boolean
        displayGroupPath: boolean
        displayAgentPath: boolean
        displayResolved?: boolean
        configColumns?: {
            id: string
            active: boolean
        }[]
    }
    query: Query
}

const initialState: AlarmsState = {
    realtime: true,
    interval: 10,
    query: getDefaultQuery('created', 'desc'),
    prefrences: {
        compressedLayout: false,
        iconsOnly: false,
        humanReadableDates: true,
        displayValues: true,
        displayTechDetails: false,
        displayFullTechDetails: false,
        displayGroupPath: true,
        displayAgentPath: false,
    },
}

export const alarmsSlice = createSlice({
    name: 'alarms',
    initialState,
    reducers: {
        setAlarmsRealtime: (state, { payload: realtime }) => {
            state.realtime = realtime
        },
        setAlarmsInterval: (state, { payload: interval }) => {
            state.interval = interval
        },
        setAlarmsStats: (state, action: PayloadAction<boolean>) => {
            state.stats = action.payload
        },
        setAlarmsPrefrences: (
            state,
            action: PayloadAction<AlarmsState['prefrences']>
        ) => {
            state.prefrences = action.payload
        },
        setAlarmsFilters: (
            state,
            action: PayloadAction<AlarmsFiltersValues | undefined>
        ) => {
            state.filters = action.payload
        },
        setAlarmsRefreshSettings: (
            state,
            action: PayloadAction<{ realtime: boolean; interval?: number }>
        ) => {
            const { realtime, interval } = action.payload
            state.realtime = realtime
            state.interval = interval
        },
        setAlarmsQuery: (state, { payload: query }) => {
            // We ignore filters here because they are handled separately
            state.query = { ...query, filters: undefined }
        },
    },
})

export const {
    setAlarmsRealtime,
    setAlarmsInterval,
    setAlarmsStats,
    setAlarmsQuery,
    setAlarmsRefreshSettings,
    setAlarmsPrefrences,
    setAlarmsFilters,
} = alarmsSlice.actions
export default alarmsSlice.reducer
