import { EuiErrorBoundary, EuiPage } from '@elastic/eui'

import Header from '../Header/Header'
import { Outlet } from 'react-router-dom'
import OverlayNotification from '../OverlayNotification/OverlayNotification'

const Layout = () => {
    return (
        <EuiPage paddingSize="none" className="main-page">
            <OverlayNotification />
            <Header />
            <EuiErrorBoundary>
                <Outlet />
            </EuiErrorBoundary>
        </EuiPage>
    )
}

export default Layout
