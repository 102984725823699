import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
} from '@elastic/eui'
import { useMemo, useState } from 'react'

import { Agent } from '@services/agents'
import { useAgentUpdateActionMutation } from '@services/api'
import { useTranslation } from 'react-i18next'

type AgentUpdateProps = {
    agent: Agent
    onClose: () => void
}

type UpdateAgentAction = {
    id: number
    reinstall: boolean
}

const AgentUpdate = ({ agent, onClose }: AgentUpdateProps) => {
    const { t } = useTranslation(['common', 'agents'])

    const [updateResult, setUpdateResult] = useState<boolean | undefined>()

    const [updateAgent, { isLoading }] = useAgentUpdateActionMutation()

    const handleOnUpdate = async (reinstall: boolean) => {
        try {
            await updateAgent({ id: agent.id, reinstall })
            setUpdateResult(true)
        } catch (error) {
            console.error(error)
            setUpdateResult(false)
        }
    }

    const icon = useMemo(() => {
        if (updateResult === undefined) {
            return { type: 'refresh', color: undefined }
        }

        return updateResult
            ? { type: 'check', color: 'success' }
            : { type: 'cross', color: 'danger' }
    }, [updateResult])

    return (
        <EuiModal onClose={onClose}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <EuiFlexGroup alignItems="center" gutterSize="m">
                        <EuiFlexItem grow={false}>
                            <EuiIcon {...icon} size="xl" />
                        </EuiFlexItem>
                        <EuiFlexItem>{t('agents:m_update_agent')}</EuiFlexItem>
                    </EuiFlexGroup>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                {updateResult === undefined && (
                    <p>{t('agents:m_update_agent_description')}</p>
                )}

                {updateResult && <p>{t('agents:m_update_agent_success')}</p>}

                {updateResult === false && (
                    <p>
                        {t(
                            'agents:m_update_agent_m_update_agent_errordescription'
                        )}
                    </p>
                )}
            </EuiModalBody>

            {updateResult === undefined && (
                <EuiModalFooter>
                    <EuiFlexGroup
                        justifyContent="flexEnd"
                        alignItems="center"
                        gutterSize="m"
                    >
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                size="s"
                                iconType="refresh"
                                fill
                                onClick={() => handleOnUpdate(false)}
                                isLoading={isLoading}
                            >
                                {t('agents:m_update_agent_confirm')}
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                size="s"
                                iconType="download"
                                isLoading={isLoading}
                                onClick={() => handleOnUpdate(true)}
                            >
                                {t('agents:m_update_agent_reinstall')}
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                size="s"
                                iconType="cross"
                                onClick={onClose}
                            >
                                {t('common:cancel')}
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiModalFooter>
            )}
        </EuiModal>
    )
}

export default AgentUpdate
