export const DEBOUNCE_DELAY = 350 // in ms
export const COLORS = ['primary', 'accent', 'success', 'warning', 'danger']
export const LIVE_POOLING_INTERVAL = 10000 // ms
export const ALARMS_DEFAULT_REFRESH_INTERVAL = 60_000 // ms
export const ALARMS_MIN_REFRESH_INTERVAL = 15_000 // ms
export const LOG_LEVELS = [
    'Trace',
    'Debug',
    'Information',
    'Warning',
    'Error',
    'Critical',
] as const
export type LogLevel = (typeof LOG_LEVELS)[number]
