import { EuiFieldPassword, EuiFormRow } from '@elastic/eui'

import { ChangeEvent } from 'react'

type PasswordFieldProps = {
    form: any // eslint-disable-line @typescript-eslint/no-explicit-any
    placeholder?: string
    name: string
    type?: 'password' | 'text' | 'dual'
    label?: string
    fullWidth?: boolean
    autocomplete?: string
    disabled?: boolean
    onChange?: (value: string) => void
}

const PasswordField = (props: PasswordFieldProps) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e.target.value)
        }
        props.form.handleChange(e)
    }

    return (
        <EuiFormRow
            isInvalid={
                props.form.touched[props.name] &&
                !!props.form.errors[props.name]
            }
            error={props.form.errors[props.name]}
            label={props.label}
            fullWidth={props.fullWidth}
        >
            <EuiFieldPassword
                name={props.name}
                placeholder={props.placeholder}
                onChange={handleOnChange}
                onBlur={props.form.handleBlur}
                value={props.form.values[props.name]}
                autoComplete={props.autocomplete}
                fullWidth={props.fullWidth}
                disabled={props.disabled}
                isInvalid={
                    props.form.touched[props.name] &&
                    !!props.form.errors[props.name]
                }
            />
        </EuiFormRow>
    )
}

export default PasswordField
