import { EuiCheckbox, EuiFormRow, useGeneratedHtmlId } from '@elastic/eui'

import { FC } from 'react'

type CheckboxFieldProps = {
    form: any // eslint-disable-line @typescript-eslint/no-explicit-any
    name: string
    label?: string
    value?: boolean
    compressed?: boolean
    indeterminate?: boolean
    onChange: (value: boolean) => void
}

const CheckboxField: FC<CheckboxFieldProps> = (props) => {
    const id = useGeneratedHtmlId({
        prefix: `checkbox-${props.name}`,
    })

    return (
        <EuiCheckbox
            id={id}
            label={props.label}
            indeterminate={props.indeterminate}
            checked={props.value ?? false}
            onChange={(e) => props.onChange(e.target.checked)}
            compressed={props.compressed}
        />
    )
}

export default CheckboxField
