import { CollectionResult, Query } from '../interfaces'
import {
    CreateRoleRequest,
    Role,
    RoleDetails,
    UpdateRoleRequest,
} from '@services/roles'
import {
    buildQueryParams,
    invalidateCacheTag,
    provideCacheTag,
} from '../helpers'

import { appApi } from '../app.api'

const agentApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchRoles: builder.query<CollectionResult<Role>, Query>({
            providesTags: (result) => provideCacheTag(result, 'Role'),
            query: (q) => ({
                url: 'roles',
                method: 'GET',
                params: buildQueryParams(q),
            }),
        }),
        findRole: builder.query<RoleDetails, { id: string }>({
            providesTags: (result) => provideCacheTag(result, 'Role'),
            query: (q) => ({
                url: `roles/${q.id}`,
                method: 'GET',
            }),
        }),
        createRole: builder.mutation<RoleDetails, CreateRoleRequest>({
            query: (req) => ({
                url: 'roles',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: (result) => invalidateCacheTag('Role', result?.id),
        }),
        updateRole: builder.mutation<RoleDetails, UpdateRoleRequest>({
            query: (req) => ({
                url: `roles/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) => invalidateCacheTag('Role', result?.id),
        }),
        deleteRole: builder.mutation<void, string>({
            query: (id) => ({
                url: `roles/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Role', req),
        }),
    }),
})

export const {
    useFetchRolesQuery,
    useFindRoleQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
} = agentApi
