import {
    EuiFlexGrid,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiStat,
    EuiTitle,
} from '@elastic/eui'
import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import {
    SshServiceStates,
    SshServiceStatuses,
} from '@services/sensors/sensors.service'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'
import { useEffect, useMemo, useState } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'

type SshServiceChartProps = ChartProps

type ServiceInfo = {
    sshServiceName: string
    sshServiceState: string
    sshServiceStatus: string
}

enum LineChartValues {
    Exited = 1,
    Failed = 0,
    Running = 2,
}

const SshServiceChart = (props: SshServiceChartProps) => {
    const { t } = useTranslation(['network'])
    const [lines, setLines] = useState<ChartLine[]>([])
    const [data, setData] = useState<any[]>([])
    const [snapshotService, setSnapshotService] = useState<
        ServiceInfo | undefined
    >(undefined)

    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    const getStateColor = (state: string) =>
        state === SshServiceStates.Failed ? 'danger' : 'success'

    const getStatusColor = (status: string) =>
        status == SshServiceStatuses.Active ? 'success' : 'danger'

    const getLineChartValue = (serviceData: ServiceInfo) => {
        if (serviceData.sshServiceState === SshServiceStates.Running) {
            return LineChartValues.Running
        } else if (serviceData.sshServiceState === SshServiceStates.Exited) {
            return LineChartValues.Exited
        } else {
            return LineChartValues.Failed
        }
    }

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const snapshot = _.findLast(props.data as any[], (s) => s)

            if (!snapshot) return

            const newLine: ChartLine = {
                key: 'lineChartValue',
                label: `${snapshot['sshServiceName']}`,
            }

            setSnapshotService(snapshot)
            setLines([newLine])
            setData(
                props.data.map((x: ServiceInfo) => ({
                    ...x,
                    lineChartValue: getLineChartValue(x),
                }))
            )
        }
    }, [props.data])

    return (
        <>
            <NetworkLineChart
                data={data}
                query={props.query}
                setQuery={props.setQuery}
                lines={lines}
                xAxis="timestamp"
                xAxisFormatter={(v) => formatTimestamp(v, props.query)}
                xAxisDomain={xAxisDomain}
                yAxisTickCount={3}
                yAxisDomain={[0, 2]}
                yAxisFormatter={(v) => {
                    switch (v) {
                        case LineChartValues.Exited:
                            return t('network:service_exited')
                        case LineChartValues.Failed:
                            return t('network:service_failed')
                        case LineChartValues.Running:
                            return t('network:service_running')
                        default:
                            return t('network:service_unknown')
                    }
                }}
            />
            <EuiSpacer size="xl" />
            <EuiTitle size="s">
                <h4>{t('network:service_information')}</h4>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGrid>
                <EuiFlexItem>
                    <EuiPanel
                        hasShadow={false}
                        color="subdued"
                        hasBorder={false}
                    >
                        <EuiTitle>
                            <h3>
                                {snapshotService?.sshServiceName ??
                                    t('network:service_unknown')}
                            </h3>
                        </EuiTitle>
                        <EuiSpacer size="s" />
                        <EuiStat
                            description={t(
                                'network:data_key_ssh_service_state'
                            )}
                            title={
                                snapshotService?.sshServiceState
                                    ? t(
                                          `network:service_${snapshotService.sshServiceState}`
                                      )
                                    : t('network:service_unknown')
                            }
                            titleSize="xxs"
                            titleColor={getStateColor(
                                snapshotService?.sshServiceState || 'Unknown'
                            )}
                        />
                        <EuiSpacer size="s" />
                        <EuiStat
                            description={t(
                                'network:data_key_ssh_service_status'
                            )}
                            title={
                                snapshotService?.sshServiceStatus
                                    ? t(
                                          `network:service_${snapshotService.sshServiceStatus}`
                                      )
                                    : t('network:service_unknown')
                            }
                            titleSize="xxs"
                            titleColor={getStatusColor(
                                snapshotService?.sshServiceStatus || 'Unknown'
                            )}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGrid>
        </>
    )
}

export default SshServiceChart
