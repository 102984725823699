import { EuiFlexGroup, EuiFlexItem, EuiLoadingChart } from '@elastic/eui'
import React, { FC } from 'react'

type LoadingSpinnerProps = {
    children?: React.ReactNode | React.ReactNode[]
    style?: React.CSSProperties
    loading?: boolean
}

const LoadingContainer = () => {
    return (
        <EuiFlexGroup justifyContent="center" alignItems="center">
            <EuiFlexItem grow={false}>
                <EuiLoadingChart size="l" />
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    loading,
    children,
    style,
}) => (
    <div style={style}>
        {loading && <LoadingContainer />}
        {children}
    </div>
)
export default LoadingSpinner
