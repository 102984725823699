/**
 * Merges two arrays of an object by a given key
 * @param array1 The first array to merge
 * @param array2 The second array to merge
 * @param key The key to merge by
 * @param additionalKeyMapper A function that if it returns another key of T,
 * it will use that key in combination with the "key" param when merging items
 * @returns The merged array
 */
export const mergeArraysBy = <T>(
    array1: T[],
    array2: T[],
    key: keyof T,
    additionalKeyMapper?: (i: T) => keyof T | undefined
) => {
    const map = new Map<T[keyof T], T>()
    const combinedKeysMap = new Map<string, T>()

    array1.forEach((item) => {
        const additionalKey = additionalKeyMapper?.(item)
        if (additionalKey) {
            combinedKeysMap.set(`${item[key]}_${item[additionalKey]}`, item)
        } else {
            map.set(item[key], item)
        }
    })

    array2.forEach((item) => {
        const additionalKey = additionalKeyMapper?.(item)
        if (additionalKey) {
            if (combinedKeysMap.has(`${item[key]}_${item[additionalKey]}`)) {
                combinedKeysMap.set(`${item[key]}_${item[additionalKey]}`, {
                    ...combinedKeysMap.get(
                        `${item[key]}_${item[additionalKey]}`
                    ),
                    ...item,
                })
            } else {
                combinedKeysMap.set(`${item[key]}_${item[additionalKey]}`, item)
            }
        } else {
            if (map.has(item[key])) {
                map.set(item[key], {
                    ...map.get(item[key]),
                    ...item,
                })
            } else {
                map.set(item[key], item)
            }
        }
    })

    return [
        ...Array.from(map.values()),
        ...Array.from(combinedKeysMap.values()),
    ]
}
