import { AlarmsState } from '@store/alarms'
import { CollectionResult } from '@services/api'
import { Moment } from 'moment'
import { SensorType } from '@services/sensors'
import { TableColumn } from '@components/table'
import { getAlarmsTableColumns } from '@components/alarms/AlarmsTable/AlarmsTableColumns'

export const MonitoringItemTypes = [
    'Device',
    'Sensor',
    'Agent',
    'Group',
] as const
export type MonitoringItemType = (typeof MonitoringItemTypes)[number]

export const AlarmTypes = [
    'DeviceUnavailable',
    'SensorUnavailable',
    'SensorInvalidOptions',
    'SensorWmiProcessorError',
    'SensorWmiProcessorWarning',
    'SensorWmiMemoryError',
    'SensorWmiMemoryWarning',
    'SensorWmiDiskError',
    'SensorWmiDiskWarning',
    'SensorPingTimeError',
    'SensorPingTimeWarning',
    'SensorPingItlError',
    'SensorPingItlWarning',
    'SensorPingBytesError',
    'SensorPingBytesWarning',
    'SensorWmiServiceError',
    'SensorWmiServiceWarning',
    'SensorSshServiceError',
    'SensorSshServiceWarning',
]

export const AlarmAllStatus = ['Info', 'Warning', 'Error']
export type AlarmType = (typeof AlarmTypes)[number]
export type AlarmStatus = 'Info' | 'Warning' | 'Error'

export const WmiServiceAlarmValues = [
    'StateRunning',
    'StateStopped',
    'StatusOk',
    'StatusUnknown',
]

export interface Alarm {
    id: string
    type: AlarmType
    status: AlarmStatus
    created: Date
    acknowledgedTimestamp?: Date
    recoveredTimestamp?: Date
    isAcknowledged: boolean
    isOk: boolean
    agentId?: number
    deviceId?: number
    sensorId?: number
    additionalData: { [key: string]: string }
    breadcrumbs?: {
        groups?: AlarmBreadcrumb[]
        agent?: AlarmBreadcrumb[]
    }
}

export const alarmStatusColors = {
    Info: 'default',
    Warning: 'warning',
    Error: 'danger',
}

export interface AlarmsFiltersValues {
    sensor?: string
    device?: string
    agent?: string
    statuses?: { label: string; value: string | number }[]
    isOk?: boolean
    isAcknowledged?: boolean
    types?: { label: string; value: string | number }[]
    startDate?: Moment
    endDate?: Moment
    categories?: { label: string; value: string | number }[]
}

export interface AlarmAcknowledgement {
    alarmIds: string[]
    isAcknowledged: boolean
    isPaused: boolean
    pauseTimeAmount?: number
    note?: string
}

export interface AlarmsSensorsStats {
    errors: number
    warnings: number
    online: number
    paused: number
    offline: number
}

export interface AlarmsCounterStats {
    errors: number
    acknowledgedErrors: number
    warnings: number
}

export interface AlarmBreadcrumb {
    id: number
    name: string
    type: MonitoringItemType
    sensorType?: SensorType
}

export interface AlarmsCollectionResult extends CollectionResult<Alarm> {
    stats?: {
        sensors: AlarmsSensorsStats
        activeAlarms: AlarmsCounterStats
        offlineAgents: number
        outdatedAgents: number
        disabledDevices: number
    }
}

export const TimeUnits = ['minutes', 'hours', 'days']

export const getPauseTimeAmount = (
    unit: (typeof TimeUnits)[number],
    amount: number
) => {
    switch (unit) {
        case 'minutes':
            return amount
        case 'hours':
            return amount * 60
        case 'days':
            return amount * 60 * 24
        default:
            return amount
    }
}

export const handleOnBreadcrumbClick = (item: AlarmBreadcrumb) =>
    handleOnItemClick(item.id, item.type)

export const handleOnItemClick = (id: number, type: MonitoringItemType) => {
    switch (type) {
        case 'Sensor':
            window.open(`${window.origin}/#/network?sensor=${id}`)
            break
        case 'Device':
            window.open(`${window.origin}/#/network?device=${id}`)
            break
        case 'Agent':
            window.open(`${window.origin}/#/network?agent=${id}`)
            break
        case 'Group':
            window.open(`${window.origin}/#/network?group=${id}`)
            break
    }
}

export const configAlarmsColumns = (prefrences: AlarmsState['prefrences']) => {
    const orgColumns = getAlarmsTableColumns(prefrences)

    if (!prefrences.configColumns) {
        return orgColumns
    }

    // Match order of columns from config
    const orderedColumns = prefrences.configColumns.map(({ id, active }) => {
        const column = orgColumns.find((c) => c.id === id)
        return {
            ...column,
            hidden: !active,
        }
    })

    return orderedColumns
}

export const isAlarmColumnsChanged = (
    columns: TableColumn[],
    prefrences: AlarmsState['prefrences']
) => {
    if (!prefrences.configColumns) {
        return false
    }

    for (let i = 0; i < columns.length; i++) {
        const isIdChanged =
            (columns[i].id || '') !== prefrences.configColumns[i].id
        if (
            isIdChanged ||
            columns[i].hidden !== !prefrences.configColumns[i].active
        ) {
            return true
        }
    }

    return false
}
