import {
    EuiBadge,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiTextColor,
    EuiToolTip,
    transparentize,
} from '@elastic/eui'
import { Fragment, useMemo } from 'react'
import { Sensor, getSensorTagName } from '@services/sensors'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import { ALL_ACTIVATABLE_TREE_NODES } from '@services/network'
import { Device } from '@services/devices'
import NetworkNodeContextMenu from '../NetworkNodeContextMenu/NetworkNodeContextMenu'
import { formatFullDate } from '@utils/dates'
import { setActiveNode } from '@store/network'
import { useTranslation } from 'react-i18next'

type NetworkTreeNodeProps = {
    type:
        | 'group'
        | 'agent'
        | 'device'
        | 'wmi_sensors'
        | 'sensor'
        | 'ssh_sensors'
        | 'snmp_sensors'
    data: any
    path?: string
    isActive?: boolean
}

const colorsMap = {
    Offline: 'default',
    Online: 'success',
    Error: 'danger',
    Warning: 'warning',
    Paused: 'primary',
    Acknowledged: transparentize('#ff7e62', 0.5),
}

const NetworkTreeNode = (props: NetworkTreeNodeProps) => {
    const { type, data } = props
    const dispatch = useAppDispatch()
    const networkState = useAppSelector((state) => state.network)
    const { t } = useTranslation(['sensors'])

    const handleOnClick = (
        nodeData: Sensor | Device,
        type:
            | 'sensor'
            | 'device'
            | 'group'
            | 'agent'
            | 'wmi_sensors'
            | 'ssh_sensors'
            | 'snmp_sensors'
    ) => {
        if (ALL_ACTIVATABLE_TREE_NODES.includes(type)) {
            dispatch(
                setActiveNode({
                    data: { ...nodeData },
                    type: type,
                    path: props.path,
                })
            )
        }
    }

    const getSensorNodeColor = (sensor: Sensor) => {
        if (sensor.isPaused) {
            return colorsMap['Paused']
        }

        return colorsMap[sensor.monitoringStatus ?? 'Online']
    }

    const getSensorNode = (sensor: Sensor) => (
        <EuiToolTip
            content={
                sensor.isPaused && sensor.pausedUntil
                    ? t('sensors:paused_until', {
                          date: formatFullDate(sensor.pausedUntil),
                      })
                    : undefined
            }
        >
            <EuiBadge
                color={getSensorNodeColor(sensor)}
                onClickAriaLabel="Open sensor"
                iconOnClickAriaLabel="Open sensor"
                isDisabled={!props.isActive || !sensor.isEnabled}
                style={{ marginRight: 5 }}
                iconType={sensor.isPaused ? 'pause' : undefined}
                onClick={() => handleOnClick(sensor, 'sensor')}
                iconOnClick={() => handleOnClick(sensor, 'sensor')}
            >
                {getSensorTagName(sensor)}
            </EuiBadge>
        </EuiToolTip>
    )

    // WMI sensors
    if (
        type === 'wmi_sensors' ||
        type === 'ssh_sensors' ||
        type === 'snmp_sensors'
    ) {
        return (
            <div>
                {data.map((sensor: Sensor) => (
                    <Fragment key={sensor.id}>
                        <NetworkNodeContextMenu type={'sensor'} item={sensor}>
                            {getSensorNode(sensor)}
                        </NetworkNodeContextMenu>
                    </Fragment>
                ))}
            </div>
        )
    }

    // Sensor node
    if (type === 'sensor') {
        return (
            <NetworkNodeContextMenu type={'sensor'} item={data}>
                {getSensorNode(data)}
            </NetworkNodeContextMenu>
        )
    }

    const nodeColor = useMemo(() => {
        if (!props.isActive && type !== 'group') return 'lightGrey'

        switch (data.monitoringStatus) {
            case 'Offline':
            case 'Error':
                return 'danger'
            case 'Warning':
                return 'warning'
            default:
                return 'default'
        }
    }, [data.monitoringStatus, props.isActive, type])

    const fontWeight = useMemo(() => {
        return nodeColor === 'default' || !props.isActive ? 'normal' : 'bold'
    }, [nodeColor, props.isActive])

    const subGroupsCounter = useMemo(
        () =>
            `(${(data.subGroups?.length ?? 0) + (data.devices?.length ?? 0) + (data.sensors?.length ?? 0)})`,
        [data.subGroups, data.devices, data.sensors]
    )

    return (
        <NetworkNodeContextMenu type={type} item={data}>
            <EuiTextColor
                color={nodeColor}
                style={{
                    fontWeight,
                }}
                onClick={() => handleOnClick(data, type)}
            >
                <EuiFlexGroup
                    direction="row"
                    gutterSize="xs"
                    alignItems="center"
                >
                    <EuiFlexItem grow={false}>{props.data.name}</EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        {subGroupsCounter && (
                            <EuiText size="xs" color="subdued">
                                <small>{subGroupsCounter}</small>
                            </EuiText>
                        )}
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiTextColor>
        </NetworkNodeContextMenu>
    )
}
export default NetworkTreeNode
