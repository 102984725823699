import { AlarmStatus, alarmStatusColors } from '@services/alarms'
import { EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { formatFullDate, formatReadableDate } from '@utils/dates'

import { AlarmCellProps } from '.'
import { FC } from 'react'
import { useAppSelector } from '@hooks/store'
import { useTranslation } from 'react-i18next'

const AlarmStatusCell: FC<AlarmCellProps> = ({ value, record }) => {
    const { t } = useTranslation(['common', 'alarms'])
    const { prefrences } = useAppSelector((state) => state.alarms)

    const formatDate = (date: string | Date | undefined): string => {
        if (!date) return formatDate(new Date())

        return prefrences?.humanReadableDates
            ? formatReadableDate(date, true)
            : formatFullDate(date, true)
    }

    return (
        <EuiFlexGroup gutterSize="xs" wrap>
            <EuiFlexItem grow={false}>
                <EuiBadge color={alarmStatusColors[value as AlarmStatus]}>
                    {t(`alarms:status_${value.toLowerCase()}`)}
                </EuiBadge>
            </EuiFlexItem>
            {record.isAcknowledged && (
                <EuiFlexItem grow={false}>
                    <EuiBadge
                        iconType="eye"
                        title={formatDate(record.acknowledgedTimestamp)}
                    >
                        {t('alarms:acknowledged')}
                    </EuiBadge>
                </EuiFlexItem>
            )}
            {record.isOk && (
                <EuiFlexItem grow={false}>
                    <EuiBadge
                        color="success"
                        iconType="check"
                        title={formatDate(record.recoveredTimestamp)}
                    >
                        {t('alarms:solved')}
                    </EuiBadge>
                </EuiFlexItem>
            )}
        </EuiFlexGroup>
    )
}

export default AlarmStatusCell
