import {
    Category,
    CreateCategoryCommand,
    UpdateCategoryCommand,
} from '@services/categories'
import { CollectionResult, Query } from '../interfaces'

import { appApi } from '../app.api'

const categoriesApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchCategories: builder.query<CollectionResult<Category>, Query>({
            providesTags: ['Category'],
            query: (q) => ({
                url: 'categories',
                method: 'GET',
                params: q,
            }),
        }),
        findCategory: builder.query<Category, { id: number }>({
            providesTags: ['Category'],
            query: ({ id }) => `categories/${id}`,
        }),
        createCategory: builder.mutation<Category, CreateCategoryCommand>({
            query: (category) => ({
                url: 'categories',
                method: 'POST',
                body: category,
            }),
            invalidatesTags: ['Category'],
        }),
        updateCategory: builder.mutation<Category, UpdateCategoryCommand>({
            query: (category) => ({
                url: `categories/${category.id}`,
                method: 'PUT',
                body: category,
            }),
            invalidatesTags: ['Category'],
        }),
        deleteCategory: builder.mutation<void, number>({
            query: (id) => ({
                url: `categories/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Category'],
        }),
        deleteCategories: builder.mutation<void, number[]>({
            query: (ids) => ({
                url: 'categories',
                method: 'DELETE',
                body: {
                    categoryIds: ids,
                },
            }),
            invalidatesTags: ['Category'],
        }),
    }),
})

export const {
    useFetchCategoriesQuery,
    useFindCategoryQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useDeleteCategoriesMutation,
} = categoriesApi
