import { EuiComboBoxOptionOption, EuiFormLabel, EuiSpacer } from '@elastic/eui'
import {
    getSshServiceComboboxOptions,
    getSshServiceComboboxOptionsFromString,
} from '@services/sensors/sensors.service'
import { useEffect, useMemo, useRef, useState } from 'react'

import AlarmOptionSelectField from './AlarmOptionSelectField'
import { Option } from '@services/options'
import { serializeAlarmSelectOptionValue } from '@services/options/options.service'
import { useTranslation } from 'react-i18next'

type AlarmOptionSshServiceGroupProps = {
    option: Option
    helpText?: JSX.Element | undefined
    onChange: (option: Option) => void
    isDisabled?: boolean
}

const AlarmOptionSshServiceGroup = ({
    option,
    helpText,
    onChange,
    isDisabled,
}: AlarmOptionSshServiceGroupProps) => {
    const { t } = useTranslation(['options'])
    const label = t(`options:${option.key.toLowerCase()}`)
    const [warnings, setWarnings] = useState<EuiComboBoxOptionOption[]>([])
    const [errors, setErrors] = useState<EuiComboBoxOptionOption[]>([])

    const isInitialized = useRef<boolean>(false)
    const comboboxOptions = useMemo(() => getSshServiceComboboxOptions(), [])

    useEffect(() => {
        if (isInitialized.current) return

        const warningString = (option.value as any)?.warning as string
        const errorString = (option.value as any)?.error as string

        if (warningString && errorString) isInitialized.current = true

        if (warningString)
            setWarnings(getSshServiceComboboxOptionsFromString(warningString))

        if (errorString)
            setErrors(getSshServiceComboboxOptionsFromString(errorString))
    }, [option.value])

    useEffect(() => {
        const warningString = serializeAlarmSelectOptionValue(warnings)
        const errorsString = serializeAlarmSelectOptionValue(errors)

        onChange({
            ...option,
            value: `${warningString};${errorsString}`,
        })
    }, [warnings, errors])

    return (
        <>
            <EuiSpacer size="s" />
            <EuiFormLabel>{label}</EuiFormLabel>
            <EuiSpacer size="s" />
            <AlarmOptionSelectField
                label={t('options:alarm_ssh_service_warnings')}
                helpText={helpText}
                onChange={setWarnings}
                options={comboboxOptions}
                selectedOptions={warnings}
                isDisabled={isDisabled}
            />
            <EuiSpacer size="s" />
            <AlarmOptionSelectField
                label={t('options:alarm_ssh_service_errors')}
                helpText={helpText}
                onChange={setErrors}
                options={comboboxOptions}
                selectedOptions={errors}
                isDisabled={isDisabled}
            />
        </>
    )
}

export default AlarmOptionSshServiceGroup
