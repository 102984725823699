import {
    EuiButton,
    EuiDatePicker,
    EuiDatePickerRange,
    EuiFlexGroup,
    EuiFlexItem,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
} from '@elastic/eui'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useEffect, useState } from 'react'

import moment from 'moment'
import { setCustomDateFilter } from '@store/network/network.slice'
import { useTranslation } from 'react-i18next'

type NetworkCustomDateFilterProps = {
    onClose: () => void
}

const NetworkCustomDateFilter = ({ onClose }: NetworkCustomDateFilterProps) => {
    const dispatch = useAppDispatch()
    const { customDateFilter } = useAppSelector((state) => state.network)
    const [startDate, setStartDate] = useState<moment.Moment | null>(null)
    const [endDate, setEndDate] = useState<moment.Moment | null>(null)

    const { t } = useTranslation(['common', 'network'])

    const handleApplyFilters = () => {
        dispatch(setCustomDateFilter([startDate, endDate]))
        onClose()
    }

    const getFilterAsMoment = (
        date: string | moment.Moment | undefined
    ): moment.Moment | null => {
        if (moment.isMoment(date)) return date
        if (date === undefined) return null
        return moment(date)
    }

    useEffect(() => {
        if (!customDateFilter) return

        const [start, end] = customDateFilter.map(getFilterAsMoment)
        setStartDate(start)
        setEndDate(end)
    }, [customDateFilter])

    return (
        <EuiModal onClose={onClose} style={{ minWidth: 770 }}>
            <EuiModalHeader>
                <h4>{t('network:select_date_filter_range')}</h4>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiFlexGroup>
                    <EuiFlexItem align="center">
                        <EuiDatePickerRange
                            inline
                            shadow={false}
                            startDateControl={
                                <EuiDatePicker
                                    selected={startDate}
                                    showTimeSelect
                                    onChange={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={endDate || undefined}
                                />
                            }
                            endDateControl={
                                <EuiDatePicker
                                    selected={endDate}
                                    showTimeSelect
                                    onChange={setEndDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate || undefined}
                                />
                            }
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton
                    iconType={'check'}
                    size="s"
                    onClick={handleApplyFilters}
                    disabled={!startDate || !endDate}
                >
                    {t('common:apply')}
                </EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}

export default NetworkCustomDateFilter
