import { createSlice } from '@reduxjs/toolkit'

export interface MainState {
    navigateTo?: string
    loading?: boolean
    activeContextMenu?: string
}

const initialState: MainState = {
    navigateTo: undefined,
    loading: false,
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setMainNavigate: (state, { payload: navigate }) =>
            (state.navigateTo = navigate),
        setMainLoading: (state, { payload: loading }) =>
            (state.loading = loading),
        setActiveContextMenu: (state, { payload: contextMenu }) => {
            state.activeContextMenu = contextMenu
        },
    },
})

export const { setMainNavigate, setMainLoading, setActiveContextMenu } =
    mainSlice.actions
export default mainSlice.reducer
