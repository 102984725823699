import { EuiBadge } from '@elastic/eui'
import { useTranslation } from 'react-i18next'

type TableBooleanColumnProps = {
    value: boolean
}

export const TableBooleanColumn = (props: TableBooleanColumnProps) => {
    const { t } = useTranslation(['common'])

    return (
        <EuiBadge color={props.value ? 'success' : 'danger'}>
            {props.value ? t('common:yes') : t('common:no')}
        </EuiBadge>
    )
}

type TableActionColumnProps = {
    editName?: string
    editDescription?: string
    deleteName?: string
    deleteDescription?: string
    viewName?: string
    viewDescription?: string
    customActions?: {
        label: string
        description: string
        icon: string
        onClick: (record: any) => void
    }[]
    onView?: (record: any) => void
    onEdit?: (record: any) => void
    onDelete?: (record: any) => void
    isDeleteEnabled?: (record: any) => boolean
}

export const getTableActions = (props: TableActionColumnProps) => {
    const actions = []

    if (props.customActions) {
        props.customActions.forEach((action) => {
            actions.push({
                name: action.label,
                description: action.description,
                icon: action.icon,
                type: 'icon',
                onClick: action.onClick,
            })
        })
    }

    if (props.onView) {
        actions.push({
            name: props.viewName,
            description: props.viewDescription,
            icon: 'eye',
            type: 'icon',
            onClick: props.onView,
        })
    }

    if (props.onEdit) {
        actions.push({
            name: props.editName,
            description: props.editDescription,
            icon: 'pencil',
            type: 'icon',
            onClick: props.onEdit,
        })
    }

    if (props.onDelete) {
        actions.push({
            name: props.deleteName,
            description: props.deleteDescription,
            icon: 'trash',
            type: 'icon',
            onClick: props.onDelete,
            color: 'danger',
            enabled: (record: any) =>
                !props.isDeleteEnabled || props.isDeleteEnabled(record),
        })
    }

    return actions
}
