import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiSpacer,
} from '@elastic/eui'
import { PasswordField, TextField } from '@components/form'
import { object, string } from 'yup'

import { AuthPage } from '@components/layout'
import BgImg3 from '@assets/bg-3.jpg'
import { LoginRequest } from '@services/auth'
import Logo from '@assets/logo_large.png'
import { storeToken } from '@store/auth'
import { useAppDispatch } from '@hooks/store'
import { useFormik } from 'formik'
import { useLoginMutation } from '@services/api'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const loginSchema = object({
    username: string().required(),
    password: string().required(),
})

const LoginPage = () => {
    const { t } = useTranslation(['auth'])
    const [login, { isLoading, isError }] = useLoginMutation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const onLogin = async (data: LoginRequest) => {
        const token = await login(data).unwrap()
        dispatch(storeToken(token))
        navigate('/', { replace: true })
    }

    const form = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: loginSchema,
        onSubmit: onLogin,
    })

    return (
        <AuthPage
            img={Logo}
            bgImg={BgImg3}
            error={form.touched && isError}
            errorMessage={t('auth:invalid_credentials')}
        >
            <EuiForm component="form" onSubmit={form.handleSubmit}>
                <TextField
                    form={form}
                    name="username"
                    placeholder={t('auth:username')}
                />
                <PasswordField
                    form={form}
                    name="password"
                    type="dual"
                    placeholder={t('auth:password')}
                />
                <EuiSpacer size="l" />
                <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                    <EuiFlexItem grow={2}>
                        <EuiButton
                            fill
                            type="submit"
                            fullWidth
                            isLoading={isLoading}
                        >
                            {t('auth:login')}
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={1}>
                        <EuiButtonEmpty size="xs" flush="right">
                            {t('auth:forgot_password')}
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiForm>
        </AuthPage>
    )
}

export default LoginPage
