import {
    DataQuery,
    SensorDataRequest,
    getPollingInterval,
} from '@services/data'
import { Table, TableColumn } from '@components/table'
import { useEffect, useState } from 'react'

import { useFetchSensorUptimeDataQuery } from '@services/api'
import { useTranslation } from 'react-i18next'

type PingUptimesProps = {
    sensorId: number
    chartQuery: DataQuery
}

const PingUptimes = ({ sensorId, chartQuery }: PingUptimesProps) => {
    const { t } = useTranslation(['network'])

    // Data fetching
    const [query, setQuery] = useState<SensorDataRequest>({
        sensor: sensorId,
        query: chartQuery,
    })
    const { data, isLoading, isFetching, refetch, isUninitialized } =
        useFetchSensorUptimeDataQuery(query, {
            skip: query.sensor === 0,
            pollingInterval: getPollingInterval(query.query),
        })

    const columns: TableColumn[] = [
        {
            field: 'uptimePercentage',
            name: t('ping_uptime_uptimePercentage'),
            sortable: false,
            filterable: false,
        },
        {
            field: 'uptimeTotalTime',
            name: t('ping_uptime_uptimeTotalTime'),
            sortable: false,
            filterable: false,
        },
        {
            field: 'goodRequestsPercentage',
            name: t('ping_uptime_goodRequestsPercentage'),
            sortable: false,
            filterable: false,
        },
        {
            field: 'downtimePercentage',
            name: t('ping_uptime_downtimePercentage'),
            sortable: false,
            filterable: false,
        },
        {
            field: 'downtimeTotalTime',
            name: t('ping_uptime_downtimeTotalTime'),
            sortable: false,
            filterable: false,
        },
        {
            field: 'longestConcurrentDowntime',
            name: t('ping_uptime_longestConcurrentDowntime'),
            sortable: false,
            filterable: false,
        },
        {
            field: 'failedRequestsPercentage',
            name: t('ping_uptime_failedRequestsPercentage'),
            sortable: false,
            filterable: false,
        },
    ]

    useEffect(() => {
        setQuery(() => ({
            sensor: sensorId,
            query: chartQuery,
        }))
    }, [chartQuery])

    return (
        <Table
            items={[data || {}]}
            totalCount={0}
            columns={columns}
            loading={isLoading || isFetching}
        />
    )
}

export default PingUptimes
