import {
    EuiComboBox,
    EuiFormRow,
    EuiIcon,
    EuiInputPopover,
    EuiTreeView,
} from '@elastic/eui'
import { useMemo, useRef, useState } from 'react'

type GroupSelectionBoxTreeProps = {
    form: any
    label?: string
    fullWidth?: boolean
    placeholder?: string
    name: string
    help?: string
    value?: any
    onChange?: (value: any) => void
    onSearch?: ((searchValue: string) => void) | undefined
    options: any[]
    isClearable?: boolean
    isDisabled?: boolean
}

const GroupSelectionBoxTree = ({
    form,
    label,
    fullWidth,
    placeholder,
    name,
    help,
    value,
    onChange,
    onSearch,
    options,
    isClearable,
    isDisabled,
}: GroupSelectionBoxTreeProps) => {
    // Refs
    const boxRef = useRef<EuiComboBox<any> | null>(null)

    // States
    const [popoverIsOpen, setPopoverIsOpen] = useState(false)

    const mapFunc = (data: any[]): any[] => {
        return data.map((x) => ({
            id: x.id,
            label: x.name,
            icon: value === x.id ? <EuiIcon type="check" /> : undefined,
            useEmptyIcon: value !== undefined && value !== x.id,
            callback: () => {
                if (onChange) onChange(x.id)
                if (boxRef.current) boxRef.current.clearSearchValue()
                setPopoverIsOpen(false)
                return x.id
            },
            children:
                x.subGroups && x.subGroups.length > 0
                    ? mapFunc(x.subGroups)
                    : [],
        }))
    }

    const reduceFunc = (data: any[], val: any): any[] => {
        return data.reduce((result: any[], x) => {
            if (x.id === val) result.push(x)
            else if (x.children && x.children.length)
                result.push(...reduceFunc(x.children, val))

            return result
        }, [])
    }

    // Memos
    const mappedOptions = useMemo(() => mapFunc(options), [value, options])
    const selectedValue = useMemo(() => {
        // Gets the selected values from the options
        if (value) {
            const res = reduceFunc(mappedOptions, value).map((item: any) => ({
                label: item.label,
                value: item.id,
            }))
            return res
        }

        return undefined
    }, [value, options])

    return (
        <EuiFormRow
            isInvalid={form.touched[name] && !!form.errors[name]}
            error={form.errors[name]}
            label={label}
            helpText={help}
            fullWidth={fullWidth}
            isDisabled={isDisabled}
        >
            <EuiInputPopover
                isOpen={popoverIsOpen}
                closePopover={() => setPopoverIsOpen(false)}
                fullWidth={fullWidth}
                disableFocusTrap
                input={
                    <EuiComboBox
                        ref={(ref) => (boxRef.current = ref)}
                        onFocus={() => {
                            setPopoverIsOpen(true)
                        }}
                        fullWidth={fullWidth}
                        placeholder={placeholder}
                        selectedOptions={selectedValue}
                        isClearable={isClearable}
                        singleSelection={{ asPlainText: true }}
                        onSearchChange={onSearch}
                        noSuggestions
                    />
                }
            >
                <EuiTreeView
                    className="eui-scrollBar"
                    style={{ maxHeight: 200, overflowY: 'auto' }}
                    items={mapFunc(options)}
                    aria-label="Tree"
                    expandByDefault
                    display="compressed"
                />
            </EuiInputPopover>
        </EuiFormRow>
    )
}

export default GroupSelectionBoxTree
