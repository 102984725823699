import { EuiFormRow, EuiSelect, EuiSelectOption } from '@elastic/eui'

import { ChangeEvent } from 'react'
import { Option } from '@services/options'
import { useTranslation } from 'react-i18next'

type OptionSelectFieldProps = {
    option: Option
    values: EuiSelectOption[]
    helpText?: JSX.Element | undefined
    append?: JSX.Element
    placeholder?: string
    onChange: (option: Option) => void
    isDisabled?: boolean
}

const OptionSelectField = (props: OptionSelectFieldProps) => {
    const { t } = useTranslation(['alarms', 'options'])

    const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
        props.onChange({ ...props.option, value: e.target.value })
    }

    return (
        <EuiFormRow
            label={t(`options:${props.option.key.toLowerCase()}`)}
            isDisabled={
                props.isDisabled ||
                props.option.isInherited ||
                props.option.isEnabled === false
            }
            helpText={props.helpText}
            fullWidth
        >
            <EuiSelect
                options={props.values}
                value={props.option.value?.toString() ?? ''}
                onChange={handleOnChange}
                disabled={
                    props.isDisabled ||
                    props.option.isInherited ||
                    props.option.isEnabled === false
                }
                compressed
                fullWidth
                append={props.append}
            />
        </EuiFormRow>
    )
}

export default OptionSelectField
