export interface Role {
    id: string
    name: string
    cannotBeDeleted: boolean
}

export interface RoleDetails extends Role {
    permissions: { [key: string]: boolean }
}

export interface CreateRoleRequest {
    name: string
    permissions: string[]
}

export interface UpdateRoleRequest extends CreateRoleRequest {
    id: string
}

export const getRolePermissions = (permissions?: { [key: string]: boolean }) =>
    Object.keys(permissions ?? {}).reduce(
        (acc, permission) => {
            const [parent, child] = permission.split('.')
            if (!acc[parent]) {
                acc[parent] = []
            }
            if (child) {
                acc[parent].push(permission)
            }
            return acc
        },
        {} as Record<string, string[]>
    )

export const getRolesOptions = (
    ids: string[] | undefined,
    roles: Role[] | undefined
) => {
    if (!ids || !roles) return []

    return ids.map((id) => {
        const role = roles.find((r) => r.id === id)
        return {
            label: role?.name ?? '',
            value: id,
        }
    })
}
