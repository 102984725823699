import AlarmOptionField, { AlarmOptionFieldProps } from './AlarmOptionField'

import { Option } from '@services/options'
import OptionField from './OptionField'
import { useMemo } from 'react'

type AlarmOptionChannelGroupFieldProps = AlarmOptionFieldProps & {
    onChannelChange: (option: Option) => void
}

const AlarmOptionChannelGroupField = (
    props: AlarmOptionChannelGroupFieldProps
) => {
    const handleParentChange = (o: Option) => {
        props.onChange(o)
    }

    const getChannelChangeHandler = (option: Option) => (o: Option) =>
        props.onChannelChange({
            ...o,
            value: `${o.value};${option.channel}`,
            isInherited: o.value === undefined || o.isInherited,
        })

    const ChannelFields = useMemo(() => {
        if (!props.option.channelOptions?.length) return []

        return props.option.channelOptions.map((o, i) => (
            <OptionField
                key={`alarm-channel-option-${i}`}
                option={o}
                onChange={getChannelChangeHandler(o)}
                fieldLabel={o.channel}
            />
        ))
    }, [props.option, getChannelChangeHandler])

    return (
        <>
            <AlarmOptionField {...props} onChange={handleParentChange} />
            {ChannelFields}
        </>
    )
}

export default AlarmOptionChannelGroupField
