import { EuiPage } from '@elastic/eui'
import Header from '@components/layout/Header/Header'
import { Outlet } from 'react-router-dom'

const DashboardPage = () => {
    return (
        <EuiPage paddingSize="none" className="main-page">
            <Header />
            <Outlet />
        </EuiPage>
    )
}
export default DashboardPage
