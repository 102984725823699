import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiStat,
    EuiTitle,
} from '@elastic/eui'
import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import { formatAsPercentage, formatSize } from '@utils/numbers'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'
import { useCallback, useEffect, useMemo, useState } from 'react'

import NetworkChannelOptionPopover from '../NetworkChannelOptionPopover/NetworkChannelOptionPopover'
import { Option } from '@services/options'
import { Sensor } from '@services/sensors'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

type WmiDiskChartProps = ChartProps & {
    sensorId: Sensor['id']
    options: Option[] | undefined
}

type DiskInfo = {
    name: string
    size: string
    load: number
    free: string
}

const WmiDiskChart = (props: WmiDiskChartProps) => {
    const [lines, setLines] = useState<ChartLine[]>([])
    const [snapshotDisks, setSnapshotDisks] = useState<DiskInfo[]>([])
    const { t } = useTranslation(['network'])
    const xAxisDomain = useMemo(
        () => getDataDomainByQuery(props.query),
        [props.query]
    )

    const getTitleColor = (load: number) => {
        if (load > 90) return 'danger'
        if (load > 75) return 'warning'
        return 'success'
    }

    const getChannelPopover = useCallback(
        (disk: DiskInfo) => {
            const diskOption = props.options?.find(
                (o) => o.channel === disk.name
            )
            return diskOption ? (
                <NetworkChannelOptionPopover
                    sensorId={props.sensorId}
                    option={diskOption}
                />
            ) : (
                <></>
            )
        },
        [props]
    )

    const channelPopovers: Record<string, JSX.Element> = useMemo(
        () =>
            snapshotDisks.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.name]: getChannelPopover(curr),
                }),
                {}
            ),
        [snapshotDisks, getChannelPopover]
    )

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const snapshot = _.findLast(
                props.data as any[],
                (d) => d.wmiDiskNumberOfDisks
            )

            if (!snapshot) return

            const disks: DiskInfo[] = []
            const numOfLines = +snapshot.wmiDiskNumberOfDisks
            const newLines: ChartLine[] = Array.from(
                { length: numOfLines },
                (_, i) => {
                    disks.push({
                        free: formatSize(
                            snapshot[`wmiDiskFreeSpace_${i}`],
                            'MB',
                            'GB'
                        ),
                        size: formatSize(
                            snapshot[`wmiDiskSize_${i}`],
                            'MB',
                            'GB'
                        ),
                        load: +snapshot[`wmiDiskSizeLoad_${i}`],
                        name: snapshot[`wmiDiskName_${i}`],
                    })

                    return {
                        key: `wmiDiskSizeLoad_${i}`,
                        label: `${snapshot[`wmiDiskName_${i}`]} ${t(
                            'network:data_key_wmi_disk_load'
                        )}`,
                    }
                }
            )
            setSnapshotDisks(disks)
            setLines(newLines)
        }
    }, [props.data])

    return (
        <div>
            <NetworkLineChart
                data={props.data}
                query={props.query}
                setQuery={props.setQuery}
                lines={lines}
                xAxis="timestamp"
                xAxisFormatter={(v) => formatTimestamp(v, props.query)}
                xAxisDomain={xAxisDomain}
                yAxisFormatter={(v) => formatAsPercentage(+v)}
                yAxisDomain={[0, 100]} // Percentage
            />
            <EuiSpacer size="xl" />
            <EuiTitle size="s">
                <h4>{t('network:disk_information')}</h4>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup gutterSize="s">
                {snapshotDisks.map((disk, i) => (
                    <EuiFlexItem key={i} grow={false}>
                        <EuiPanel
                            hasShadow={false}
                            color="subdued"
                            hasBorder={false}
                        >
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiTitle>
                                        <h3>{disk.name}</h3>
                                    </EuiTitle>
                                    <EuiSpacer size="s" />
                                    <EuiStat
                                        description={t(
                                            'network:data_key_wmi_disk_total'
                                        )}
                                        title={disk.size}
                                        titleSize="xxs"
                                    />
                                    <EuiSpacer size="s" />
                                    <EuiStat
                                        description={t(
                                            'network:data_key_wmi_disk_free'
                                        )}
                                        title={disk.free}
                                        titleSize="xxs"
                                        titleColor="success"
                                    />
                                    <EuiSpacer size="s" />
                                    <EuiStat
                                        description={t(
                                            'network:data_key_wmi_disk_load'
                                        )}
                                        title={formatAsPercentage(disk.load)}
                                        titleSize="xxs"
                                        titleColor={getTitleColor(disk.load)}
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem
                                    grow={false}
                                    style={{ paddingTop: 4 }}
                                >
                                    {channelPopovers[disk.name]}
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiPanel>
                    </EuiFlexItem>
                ))}
            </EuiFlexGroup>
        </div>
    )
}
export default WmiDiskChart
