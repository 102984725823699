import { CollectionResult, Query } from '../interfaces'
import { DetailsGroupRequest, Group } from '../../groups'
import {
    buildQueryParams,
    invalidateCacheTag,
    provideCacheTag,
} from '../helpers'

import { appApi } from '../app.api'

const groupApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchGroups: builder.query<CollectionResult<Group>, Query>({
            providesTags: (result) => provideCacheTag(result, 'Group'),
            query: (q) => ({
                url: 'groups',
                method: 'GET',
                params: buildQueryParams(q),
            }),
        }),
        findGroup: builder.query<Group, { id: number }>({
            providesTags: (result) => provideCacheTag(result, 'Group'),
            query: (q) => ({
                url: `groups/${q.id}`,
                method: 'GET',
            }),
        }),
        createGroup: builder.mutation<Group, DetailsGroupRequest>({
            query: (req) => ({
                url: 'groups',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Group', result?.id).concat(['Option']),
        }),
        updateGroup: builder.mutation<Group, Group>({
            query: (req) => ({
                url: `groups/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) =>
                invalidateCacheTag('Group', result?.id).concat(['Option']),
        }),
        deleteGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `groups/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, req) =>
                invalidateCacheTag('Group', req).concat(['Option']),
        }),
    }),
})

export const {
    useFetchGroupsQuery,
    useFindGroupQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
} = groupApi
