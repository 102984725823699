import moment, { Moment } from 'moment'

export const DEFAULT_DATE_CULTURE = 'nl'
export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
export const DEFAULT_TIME_FORMAT = 'HH:mm'
export const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`

export const formatFullDate = (
    date: Date | string | Moment | undefined,
    utc: boolean = false
): string => {
    if (!date) return ''

    const newData = utc ? moment.utc(date) : moment(date)

    if (newData.isUTC()) {
        return newData.local().format('llll')
    }
    return newData.format('llll')
}

export const formatReadableDate = (
    date: Date | string | Moment,
    utc: boolean = false
): string => {
    const newData = utc ? moment.utc(date) : moment(date)
    return newData.fromNow()
}

export const isTimeFormat = (value: string): boolean => {
    return moment(value, 'HH:mm:ss', true).isValid()
}

export const convertMsToSeconds = (ms: number): number => {
    return ms / 1000
}

export const convertSecondsToMs = (seconds: number): number => {
    return seconds * 1000
}
